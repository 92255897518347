import { registerTechnicalDictionary } from '../../webmodule-common/other/language/technical-dictionary';
import { registerLangFile, tlang } from '../../webmodule-common/other/language/lang';
import { registerComponents } from '../../webmodule-common/components/src/components/register-components';
import { registerComponentsLocal } from '../../webmodule-common/other/ui/templatecontrols/component-registry';
import { getCurrentUser, setAfterApplyUserEvent } from '../../webmodule-common/other/api/current-user';
import {
  DealerApiCommunications,
  setDealerTokenProvider
} from '../../webmodule-common/other/api/dealer-api-communications';
import { getUserLock } from './common/optimistic-user-lock';
import {
  ServiceResponseInvalid,
  ServiceResponseType
} from '../../webmodule-common/interop/interfaces/service_response';
import { EventSnippet } from '../../webmodule-common/interop/types/misc';
import { displayAllError } from '../../webmodule-common/other/ui/modal-errorhandler';
import { setErrorDialogEventHandler, showError } from '../../webmodule-common/other/ui/show-error';
import { userDataStore } from './common/current-user-data-store';
import { getApi, setApiFactory, setApiInjector } from '../api/api-injector';
import { SupplierApiImplementation } from '../api/supplier-api-implementation';
import { FranchiseeNetworkApiImplementation } from '../api/franchisee-network-api-implementation';
import { BlobApiImpl } from '../api/blob-api-impl';
import { DataAggregationApiImplementation } from '../api/supplier-api-dataaggregation-implementation';
import { responseHandler } from '../api/api-response-handler';
import { setCacheRegistry } from './cache-impl/cache-registry';
import { UserProfileCache } from './cache-impl/user-profile-cache';
import { runGlobalInit } from '../../webmodule-common/other/app-global-init';
import { dealerVerifyUser, performPageLoadLoginVerifications } from '../api/dealer-pageload-verifications';

import {
  clearCurrentUserFromSession,
  saveCurrentUserIntoSession,
  verifySession
} from '../../webmodule-common/other/user-session-verifier';
import { ConnectedState } from '../api/misc-codegen';
import { showDevelopmentError } from '../../webmodule-common/other/development-error';
import { appConfig } from './app-config';
import { setRoutes } from '../../webmodule-common/other/router';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { bindApplicationFeatures } from '../../webmodule-common/other/bind-application-features';

let _appStartupEvents: Promise<void> | undefined;

export function getApplicationStartupEvents() {
  if (_appStartupEvents === undefined) {
    _appStartupEvents = applicationStartupEvents();
  }
  return _appStartupEvents;
}

globalThis.getApplicationStartupEvents = getApplicationStartupEvents;

async function applicationStartupEvents() {
  const log = (msg: string) => console.log('STARTUP: ' + msg);
  dealerBuildApplicationBindings();
  if (!(await dealerVerifyUser(log))) {
    window.location.reload();
  }
  await dealerPrepUserData(log);
}

async function dealerPrepUserData(_updateNote: (msg: string) => void) {
  try {
    //we want to enforce that the system the user connects to is fully up to date. this is a
    //simple call to the v6 systems to check their version, and will ensure the system resets info
    //as needed. This is also required for the quotes page, so we know if we are a multi supplier view or not
  } catch {
    console.log('could not load suppliers');
  }
}

function reload() {
  // Take us to the home page. this will clear any validations
  // or other issues
  // the home page has code to trigger login if required and
  // page reloading
  window.location.href = '/login';
}

async function connectUserAfterLogin() {
  const reset = async () => {
    userDataStore.clear();
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    reload();
  };
  const user = getCurrentUser();
  if (user !== null) {
    if (!(await verifySession())) {
      await reset();
      return;
    }

    //DO NOT MAKE ANY CALLS THAT NEED AUTHORIZATION BEFORE CONNECTING THE USER
    //Make any api calls or anything else here that are necessary to be used for the current user
    try {
      const state = await getApi().post<ConnectedState>('api/Supplier/ConnectUser', {});
      if (!state?.connected) {
        await information(
          tlang`Your login details are valid, but the server refused to accept your login at this time. please try again later.`,
          tlang`Login Rejected`
        );
        await reset();
        return;
      }
      //this will update the user claims security before we rebind the router.
      await userDataStore.loadCoreDetailsAfterLogin();
      saveCurrentUserIntoSession();
    } catch (e) {
      await showDevelopmentError(e as Error);
      await reset();
    }
  } else {
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    userDataStore.clear();
    reload();
    //we always want to login again
  }
}

export function rebindRouter() {
  const config = appConfig();
  setRoutes(config.routes);
  /*
    addURLResolvers(
        config.routes
            .filter((x) => x.resolveUrl !== undefined)
            .map((x) => {
                return x.resolveUrl as ResolveURLEntry;
            })
    );
    */
}

function dealerBuildApplicationBindings() {
  registerTechnicalDictionary(globalThis.dealerConfiguration.dictItems);
  registerLangFile(globalThis.dealerConfiguration.langFile);

  registerComponents();
  registerComponentsLocal();

  bindApplicationFeatures();

  setAfterApplyUserEvent(connectUserAfterLogin);

  setDealerTokenProvider(() => getUserLock());

  const errorDialogEventHandler = async (item: ServiceResponseInvalid | Error, title: EventSnippet) => {
    try {
      await displayAllError(title, item);
    } catch (e) {
      console.log(e);
    }
  };
  setErrorDialogEventHandler(errorDialogEventHandler);

  setApiFactory({
    supplier: () => new SupplierApiImplementation(getApi()),
    franchisee: () => new FranchiseeNetworkApiImplementation(getApi()),
    blob: () => new BlobApiImpl(getApi()),
    dataAggregation: () => new DataAggregationApiImplementation(getApi())
  });

  let _commSingleton: DealerApiCommunications | undefined;
  const apiInjecterEvent = () => {
    if (!_commSingleton)
      _commSingleton = new DealerApiCommunications('', responseHandler, () => {
        //Redirect to home page, next query will force a login to occur
        window.location.href = '/login';
      });
    return _commSingleton;
  };
  //Dependency inject an api for the entire application
  setApiInjector(apiInjecterEvent);
  setCacheRegistry(() => {
    const api = getApi();
    return {
      userProfile: new UserProfileCache(api)
    };
  });

  window.addEventListener('unhandledrejection', function (event) {
    if (event.reason.message.includes('Vaadin')) {
      return;
    }
    console.error(event.reason.stack);
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    showError(
      {
        responseType: ServiceResponseType.Error,
        responseTypeCaption: tlang`unhandled error`,
        responseError: {
          message: event.reason.message,
          stackTrace: event.reason.stackTrace
        }
      },
      () => tlang`Unhandled Error inside a promise occurred`
    );
  });

  runGlobalInit();
}

if (!performPageLoadLoginVerifications) console.log('missing page loader');
