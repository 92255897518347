import { css, html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { until } from 'lit/directives/until.js';
import type { TemplateResult } from 'lit';

export function awaitableTemplate(
  template:
    | TemplateResult
    | string
    | Promise<TemplateResult>
    | Promise<string>
    | HTMLElement
    | Promise<HTMLElement>
    | undefined
    | null
    | (() => string | TemplateResult)
    | (() => TemplateResult[])
    | (() => Promise<string | TemplateResult>)
    | (() => Promise<TemplateResult[]>)
) {
  if (!template) return html``;
  const templateValue = typeof template === 'function' ? template() : template;

  return (templateValue as any).then ? until(templateValue) : templateValue;
}

/**
 * Returns True if handled. if false, the event will go to the cellclick
 */
export type WebModuleLitTableCellClickEvent = (
  e: Event,
  table: WebModuleLitTable,
  item: unknown,
  fieldName: string
) => boolean;

// Id is a unique value to query on if set.
export interface WebModuleLitTableColumnDef {
  id?: string;
  title: string | TemplateResult | (() => string | TemplateResult);
  fieldName: string;
  classes: string;
  sortable?: boolean;
  isDefaultSortDirectionAscending?: boolean;
  displayValue?: (table: WebModuleLitTable, item: any, index: number, fieldName: string) => TemplateResult | string;
  click?: WebModuleLitTableCellClickEvent;
  visible?: boolean;
  columnAggregate?: () => TemplateResult | string;
}

export type WebModuleLitTableRowExtensionTemplateEvent =
  | ((item: unknown) => TemplateResult)
  | ((item: unknown) => Promise<TemplateResult>);

export type WebModuleLitTableRowExtensionTemplate = TemplateResult | Promise<TemplateResult>;

export type WebModuleLitTableExtensionTemplateProvider =
  | WebModuleLitTableRowExtensionTemplate
  | WebModuleLitTableRowExtensionTemplateEvent;

interface WebModuleLitTableExtension {
  itemKey: unknown;
  extensionProvider: WebModuleLitTableExtensionTemplateProvider;
}

export interface WebModuleTableItemMove {
  sourceIndex: number;
  targetIndex: number;
}

/**
 * @event {{ index : Number }} page-change - Emitted when the tree item expands.
 *
 * @tag webmodule-lit-table-paginator
 */
export class WebModuleLitTablePaginator extends LitElement {
  @property({ type: Number })
  index = 1;
  @property({ type: Number })
  count = 1;

  attributeTrue(name: string) {
    if (!this.hasAttribute(name)) return false;
    else {
      const attr = this.getAttribute(name);
      return attr === undefined || attr !== 'false';
    }
  }

  render() {
    let currentPage = this.index;
    if (typeof currentPage === 'string') currentPage = parseInt(currentPage);
    const pageCount = this.count;

    const visiblePages = 11;
    const pageDeductor = 5;
    let firstDisplayPage = currentPage - pageDeductor;
    if (firstDisplayPage < 0) firstDisplayPage = 0;
    let lastDisplayPage = firstDisplayPage + visiblePages;
    if (lastDisplayPage > pageCount) lastDisplayPage = pageCount;

    if (lastDisplayPage === pageCount && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      firstDisplayPage = Math.max(0, lastDisplayPage - visiblePages + 1);
    if (firstDisplayPage === 0 && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      lastDisplayPage = Math.min(pageCount, firstDisplayPage + visiblePages - 1);

    const includeSkipToFirst = firstDisplayPage > 0;
    const includeSkipToLast = lastDisplayPage < pageCount;

    const clickEvent = (e: Event) => {
      e.preventDefault();
      const strPage = (e.target as HTMLUListElement).textContent?.toLowerCase();
      if (strPage === 'prev') currentPage--;
      else if (strPage === 'next') currentPage++;
      else if (strPage === 'last') currentPage = pageCount - 1;
      else if (strPage === 'first') currentPage = 0;
      else currentPage = parseInt(strPage ?? '1') - 1;

      //handle accidental overflows
      currentPage = Math.min(pageCount, Math.max(0, currentPage));
      const event = new CustomEvent('page-change', {
        detail: {
          index: currentPage
        }
      });
      this.dispatchEvent(event);
    };

    const pages = (): TemplateResult[] => {
      const pageResult: TemplateResult[] = [];
      for (let i = firstDisplayPage; i < lastDisplayPage; i++) {
        if (i === currentPage) {
          pageResult.push(html` <li class="page-item active"><span class="page-link">${i + 1}</span></li>`);
        } else {
          pageResult.push(
            html` <li class="page-item">
              <a class="page-link" href="#" @click=${clickEvent}>${i + 1}</a>
            </li>`
          );
        }
      }
      return pageResult;
    };

    const skipFirstClass = `page-item ${!includeSkipToFirst ? 'disabled' : ''}`;
    const currentPageClass = `page-item ${currentPage === 0 ? 'disabled' : ''}`;
    const lastPageClass = `page-item ${currentPage === pageCount - 1 ? 'disabled' : ''}`;
    const skipToLastClass = `page-item ${!includeSkipToLast ? 'disabled' : ''}`;
    return html` <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class=${skipFirstClass}>
          <a class="page-link" href="#" @click=${clickEvent}>First</a>
        </li>
        <li class=${currentPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Prev</a>
        </li>
        ${pages()}
        <li class=${lastPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Next</a>
        </li>
        <li class=${skipToLastClass}>
          <a class="page-link" href="#" @click=${clickEvent}>Last</a>
        </li>
      </ul>
    </nav>`;
  }

  //This class is designed to not have a shadow root, and allow styling to come from the application consuming it
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}

/**
 * @event fetchtemplate - Emitted requesting row template.
 * @event fetch - Emitted requesting data.
 * @event {{ table: WebModuleLitTable, item: Object }} rowclick - Emitted when row is clicked.
 * @event {{ table: WebModuleLitTable, selectedkeys: Object }} rowselect - Emitted when row is selected.
 * @event {{ table: WebModuleLitTable, item: Object, colDef: WebModuleLitTableColumnDef, originalEvent: Event }} cellclick - Emitted when cell is clicked.
 * @event {{ Object : WebModuleTableItemMove }} webmodule-table-item-move - Emitted when items are moved (drag/drop).
 *
 * @tag webmodule-lit-table
 */
export class WebModuleLitTable extends LitElement {
  private dragSrcEl: HTMLDivElement | null = null;
  private lastDropTarget: HTMLElement | null = null;
  private draggedIndex: number | null = null;
  private noDropClass = 'no-drop-allowed';

  @property() rowClass = 'row';
  @property() colClass = 'col';

  @property({ type: String })
  enableDrag?: string;

  //This class is designed to not have a shadow root, and allow styling to come from the application consuming it
  static styles = css`
    .simple-table {
      background-color: whitesmoke;

      .simple-table-body {
        overflow-y: scroll;
        overflow-x: hidden;
        min-height: 25px;
      }

      &.standardtable {
        .simple-table-body {
          min-height: 40vh;
          max-height: 70vh;
        }
      }

      &.nestedtable {
        .simple-table-body {
          min-height: 25px !important;
        }
      }

      &.halftable {
        .simple-table-body {
          min-height: 20vh;
          max-height: 30vh;
        }
      }

      .simple-table-header {
        background-color: darkcyan;
        color: white;
        margin-right: 1rem !important;

        .col {
          border-right: solid 1px white;
        }

        .first {
          border-right: solid 1px darkcyan;
        }

        .last {
          border-right: solid 1px darkcyan !important;
        }
      }

      .simple-table-row {
        border-bottom: solid 1px darkcyan;
        background-color: whitesmoke;
        color: black;

        .col {
          border-left: solid 1px darkcyan;
        }

        .last {
          border-right: solid 1px darkcyan;
        }
      }

      .simple-table-row-extension {
        border-bottom: solid 1px darkcyan;
        background-color: white;
        padding-left: 1rem;
        border-left: solid 3px gray;
        color: black;
      }

      .column {
        display: inline-block;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      .cw-1 {
        width: 1%;
      }

      .cw-2 {
        width: 2%;
      }

      .cw-3 {
        width: 3%;
      }

      .cw-4 {
        width: 4%;
      }

      .cw-5 {
        width: 5%;
      }

      .cw-6 {
        width: 6%;
      }

      .cw-7 {
        width: 7%;
      }

      .cw-8 {
        width: 8%;
      }

      .cw-9 {
        width: 9%;
      }

      .cw-10 {
        width: 10%;
      }

      .cw-11 {
        width: 11%;
      }

      .cw-12 {
        width: 12%;
      }

      .cw-13 {
        width: 13%;
      }

      .cw-14 {
        width: 14%;
      }

      .cw-15 {
        width: 15%;
      }

      .cw-16 {
        width: 16%;
      }

      .cw-17 {
        width: 17%;
      }

      .cw-18 {
        width: 18%;
      }

      .cw-19 {
        width: 19%;
      }

      .cw-20 {
        width: 20%;
      }

      .cw-21 {
        width: 21%;
      }

      .cw-22 {
        width: 22%;
      }

      .cw-23 {
        width: 23%;
      }

      .cw-24 {
        width: 24%;
      }

      .cw-25 {
        width: 25%;
      }

      .cw-26 {
        width: 26%;
      }

      .cw-27 {
        width: 27%;
      }

      .cw-28 {
        width: 28%;
      }

      .cw-29 {
        width: 29%;
      }

      .cw-30 {
        width: 30%;
      }

      .cw-31 {
        width: 31%;
      }

      .cw-32 {
        width: 32%;
      }

      .cw-33 {
        width: 33%;
      }

      .cw-34 {
        width: 34%;
      }

      .cw-35 {
        width: 35%;
      }

      .cw-36 {
        width: 36%;
      }

      .cw-37 {
        width: 37%;
      }

      .cw-38 {
        width: 38%;
      }

      .cw-39 {
        width: 39%;
      }

      .cw-40 {
        width: 40%;
      }

      .cw-41 {
        width: 41%;
      }

      .cw-42 {
        width: 42%;
      }

      .cw-43 {
        width: 43%;
      }

      .cw-44 {
        width: 44%;
      }

      .cw-45 {
        width: 45%;
      }

      .cw-46 {
        width: 46%;
      }

      .cw-47 {
        width: 47%;
      }

      .cw-48 {
        width: 48%;
      }

      .cw-49 {
        width: 49%;
      }

      .cw-50 {
        width: 50%;
      }

      .cw-51 {
        width: 51%;
      }

      .cw-52 {
        width: 52%;
      }

      .cw-53 {
        width: 53%;
      }

      .cw-54 {
        width: 54%;
      }

      .cw-55 {
        width: 55%;
      }

      .cw-56 {
        width: 56%;
      }

      .cw-57 {
        width: 57%;
      }

      .cw-58 {
        width: 58%;
      }

      .cw-59 {
        width: 59%;
      }

      .cw-60 {
        width: 60%;
      }

      .cw-61 {
        width: 61%;
      }

      .cw-62 {
        width: 62%;
      }

      .cw-63 {
        width: 63%;
      }

      .cw-64 {
        width: 64%;
      }

      .cw-65 {
        width: 65%;
      }

      .cw-66 {
        width: 66%;
      }

      .cw-67 {
        width: 67%;
      }

      .cw-68 {
        width: 68%;
      }

      .cw-69 {
        width: 69%;
      }

      .cw-70 {
        width: 70%;
      }

      .cw-71 {
        width: 71%;
      }

      .cw-72 {
        width: 72%;
      }

      .cw-73 {
        width: 73%;
      }

      .cw-74 {
        width: 74%;
      }

      .cw-75 {
        width: 75%;
      }

      .cw-76 {
        width: 76%;
      }

      .cw-77 {
        width: 77%;
      }

      .cw-78 {
        width: 78%;
      }

      .cw-79 {
        width: 79%;
      }

      .cw-80 {
        width: 80%;
      }

      .cw-81 {
        width: 81%;
      }

      .cw-82 {
        width: 82%;
      }

      .cw-83 {
        width: 83%;
      }

      .cw-84 {
        width: 84%;
      }

      .cw-85 {
        width: 85%;
      }

      .cw-86 {
        width: 86%;
      }

      .cw-87 {
        width: 87%;
      }

      .cw-88 {
        width: 88%;
      }

      .cw-89 {
        width: 89%;
      }

      .cw-90 {
        width: 90%;
      }

      .cw-91 {
        width: 91%;
      }

      .cw-92 {
        width: 92%;
      }

      .cw-93 {
        width: 93%;
      }

      .cw-94 {
        width: 94%;
      }

      .cw-95 {
        width: 95%;
      }

      .cw-96 {
        width: 96%;
      }

      .cw-97 {
        width: 97%;
      }

      .cw-98 {
        width: 98%;
      }

      .cw-99 {
        width: 99%;
      }

      .cw-100 {
        width: 100%;
      }

      .table {
        width: 100%;
        background-color: white;
      }

      .px-1 {
        width: 1px;
      }

      .px-6 {
        width: 6px;
      }

      .px-11 {
        width: 11px;
      }

      .px-16 {
        width: 16px;
      }

      .px-21 {
        width: 21px;
      }

      .px-26 {
        width: 26px;
      }

      .px-31 {
        width: 31px;
      }

      .px-36 {
        width: 36px;
      }

      .px-41 {
        width: 41px;
      }

      .px-46 {
        width: 46px;
      }

      .px-51 {
        width: 51px;
      }

      .px-56 {
        width: 56px;
      }

      .px-61 {
        width: 61px;
      }

      .px-66 {
        width: 66px;
      }

      .px-71 {
        width: 71px;
      }

      .px-76 {
        width: 76px;
      }

      .px-81 {
        width: 81px;
      }

      .px-86 {
        width: 86px;
      }

      .px-91 {
        width: 91px;
      }

      .px-96 {
        width: 96px;
      }

      .px-101 {
        width: 101px;
      }

      .px-106 {
        width: 106px;
      }

      .px-111 {
        width: 111px;
      }

      .px-116 {
        width: 116px;
      }

      .px-121 {
        width: 121px;
      }

      .px-126 {
        width: 126px;
      }

      .px-131 {
        width: 131px;
      }

      .px-136 {
        width: 136px;
      }

      .px-141 {
        width: 141px;
      }

      .px-146 {
        width: 146px;
      }

      .px-151 {
        width: 151px;
      }

      .px-156 {
        width: 156px;
      }

      .px-161 {
        width: 161px;
      }

      .px-166 {
        width: 166px;
      }

      .px-171 {
        width: 171px;
      }

      .px-176 {
        width: 176px;
      }

      .px-181 {
        width: 181px;
      }

      .px-186 {
        width: 186px;
      }

      .px-191 {
        width: 191px;
      }

      .px-196 {
        width: 196px;
      }

      .px-201 {
        width: 201px;
      }

      .px-206 {
        width: 206px;
      }

      .px-211 {
        width: 211px;
      }

      .px-216 {
        width: 216px;
      }

      .px-221 {
        width: 221px;
      }

      .px-226 {
        width: 226px;
      }

      .px-231 {
        width: 231px;
      }

      .px-236 {
        width: 236px;
      }

      .px-241 {
        width: 241px;
      }

      .px-246 {
        width: 246px;
      }

      .px-251 {
        width: 251px;
      }

      .px-256 {
        width: 256px;
      }

      .px-261 {
        width: 261px;
      }

      .px-266 {
        width: 266px;
      }

      .px-271 {
        width: 271px;
      }

      .px-276 {
        width: 276px;
      }

      .px-281 {
        width: 281px;
      }

      .px-286 {
        width: 286px;
      }

      .px-291 {
        width: 291px;
      }

      .px-296 {
        width: 296px;
      }

      .px-301 {
        width: 301px;
      }

      .px-306 {
        width: 306px;
      }

      .px-311 {
        width: 311px;
      }

      .px-316 {
        width: 316px;
      }

      .px-321 {
        width: 321px;
      }

      .px-326 {
        width: 326px;
      }

      .px-331 {
        width: 331px;
      }

      .px-336 {
        width: 336px;
      }

      .px-341 {
        width: 341px;
      }

      .px-346 {
        width: 346px;
      }

      .px-351 {
        width: 351px;
      }

      .px-356 {
        width: 356px;
      }

      .px-361 {
        width: 361px;
      }

      .px-366 {
        width: 366px;
      }

      .px-371 {
        width: 371px;
      }

      .px-376 {
        width: 376px;
      }

      .px-381 {
        width: 381px;
      }

      .px-386 {
        width: 386px;
      }

      .px-391 {
        width: 391px;
      }

      .px-396 {
        width: 396px;
      }

      .px-401 {
        width: 401px;
      }

      .px-406 {
        width: 406px;
      }

      .px-411 {
        width: 411px;
      }

      .px-416 {
        width: 416px;
      }

      .px-421 {
        width: 421px;
      }

      .px-426 {
        width: 426px;
      }

      .px-431 {
        width: 431px;
      }

      .px-436 {
        width: 436px;
      }

      .px-441 {
        width: 441px;
      }

      .px-446 {
        width: 446px;
      }

      .px-451 {
        width: 451px;
      }

      .px-456 {
        width: 456px;
      }

      .px-461 {
        width: 461px;
      }

      .px-466 {
        width: 466px;
      }

      .px-471 {
        width: 471px;
      }

      .px-476 {
        width: 476px;
      }

      .px-481 {
        width: 481px;
      }

      .px-486 {
        width: 486px;
      }

      .px-491 {
        width: 491px;
      }

      .px-496 {
        width: 496px;
      }

      .px-501 {
        width: 501px;
      }

      .px-506 {
        width: 506px;
      }

      .px-511 {
        width: 511px;
      }

      .px-516 {
        width: 516px;
      }

      .px-521 {
        width: 521px;
      }

      .px-526 {
        width: 526px;
      }

      .px-531 {
        width: 531px;
      }

      .px-536 {
        width: 536px;
      }

      .px-541 {
        width: 541px;
      }

      .px-546 {
        width: 546px;
      }

      .px-551 {
        width: 551px;
      }

      .px-556 {
        width: 556px;
      }

      .px-561 {
        width: 561px;
      }

      .px-566 {
        width: 566px;
      }

      .px-571 {
        width: 571px;
      }

      .px-576 {
        width: 576px;
      }

      .px-581 {
        width: 581px;
      }

      .px-586 {
        width: 586px;
      }

      .px-591 {
        width: 591px;
      }

      .px-596 {
        width: 596px;
      }

      .px-601 {
        width: 601px;
      }

      .px-606 {
        width: 606px;
      }

      .px-611 {
        width: 611px;
      }

      .px-616 {
        width: 616px;
      }

      .px-621 {
        width: 621px;
      }

      .px-626 {
        width: 626px;
      }

      .px-631 {
        width: 631px;
      }

      .px-636 {
        width: 636px;
      }

      .px-641 {
        width: 641px;
      }

      .px-646 {
        width: 646px;
      }

      .px-651 {
        width: 651px;
      }

      .px-656 {
        width: 656px;
      }

      .px-661 {
        width: 661px;
      }

      .px-666 {
        width: 666px;
      }

      .px-671 {
        width: 671px;
      }

      .px-676 {
        width: 676px;
      }

      .px-681 {
        width: 681px;
      }

      .px-686 {
        width: 686px;
      }

      .px-691 {
        width: 691px;
      }

      .px-696 {
        width: 696px;
      }

      .tr {
        width: 100%;
      }
    }
  `;
  @property({ type: Boolean })
  clickrows = false;
  @property({ type: String })
  selectmode = 'none'; // none | single | multi
  @property({ type: String })
  tablestyle = 'standardtable'; //standard, nested, "", small
  @property({ type: Number })
  rowCount = 0;
  @property({ type: Function })
  keyevent?: (item: object) => unknown;

  @property({ type: Function })
  rowidevent?: (item: object) => string | null;

  @property({ type: Function })
  itemRowClassEvent?: (item: object) => string;
  @property({ type: Function })
  checkEnabledDragEvent?: (item: object) => boolean;

  private _columns: WebModuleLitTableColumnDef[] = [
    { title: 'Column1', fieldName: '', classes: 'col-6', displayValue: () => 'One' },
    { title: 'Column2', fieldName: '', classes: 'col-6', displayValue: () => 'Two' }
  ];

  @property({ type: Object })
  set columns(value: WebModuleLitTableColumnDef[]) {
    this._columns = value;
  }

  get columns() {
    return this.enableDrag === 'true'
      ? [
          {
            title: '',
            classes: 'colpxmax-30 drag-grab-handle',
            fieldName: 'xx',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            displayValue: (_table: WebModuleLitTable, _item: unknown, _index: number) => {
              return html`<span
                @touchstart="${this.dragtouchstart}"
                @touchmove="${this.dragtouchmove}"
                @touchend="${this.dragtouchend}"
                @touchcancel="${this.handleTouchCancel}"
                class="draggable-item"
                ><i class="fa-solid fa-grip-vertical"></i
              ></span>`;
            }
          },
          ...this._columns
        ]
      : this._columns;
  }

  @state()
  data: object[] = [];
  @property({ type: Number })
  pageLength = 3000;
  @state()
  private _extensions: WebModuleLitTableExtension[] = [];
  private _firstConnected = false;

  @property()
  sortField?: string;
  @property()
  sortAscending?: boolean;
  @property()
  selectedKeys: unknown[] = [];

  @property({ type: Number })
  private _pageIndex = 0;

  public get pageIndex(): number {
    return this._pageIndex;
  }

  public set pageIndex(value: number) {
    this._pageIndex = this.pageNumberCheck(value);
    this.fetchEvent();
  }

  protected get standardtable(): boolean {
    return this.tablestyle === 'standardtable' || this.tablestyle === '';
  }

  protected get nestedtable(): boolean {
    return this.tablestyle === 'nestedtable';
  }

  protected get halftable(): boolean {
    return this.tablestyle === 'halftable';
  }

  protected get isSortingAscending(): boolean {
    return this.sortAscending === undefined || this.sortAscending;
  }

  protected get pageCount(): number {
    return Math.ceil(this.rowCount / this.pageLength);
  }

  protected get isSelectable(): boolean {
    return this.selectmode !== 'none';
  }

  connectedCallback(): void {
    if (!this._firstConnected) this.configure();
    this._firstConnected = true;
    super.connectedCallback();
  }

  configure() {
    //override;
  }

  isExpanded(item: object) {
    const itemKey = this.getPrimaryKey(item);
    return this._extensions.find(x => x.itemKey === itemKey);
  }

  addExtension(item: object, template: WebModuleLitTableExtensionTemplateProvider) {
    const itemKey = this.getPrimaryKey(item);
    this._extensions = [
      ...this._extensions.filter(x => x.itemKey !== itemKey),
      { itemKey: itemKey, extensionProvider: template }
    ];
  }

  remExtension(item: object) {
    const itemKey = this.getPrimaryKey(item);
    this._extensions = this._extensions.filter(x => x.itemKey !== itemKey);
  }

  fetchTemplateEvent(item: object) {
    this.dispatchCustom('fetchtemplate', { table: this, item: item });
  }

  afterRowTemplate(item: object, index: number) {
    const event = {
      table: this,
      item: item,
      index: index,
      template: undefined
    };
    this.dispatchCustom('after-row-render', event);
    return event.template;
  }

  beforeRowTemplate(item: object, index: number) {
    const event = {
      table: this,
      item: item,
      index: index,
      template: undefined
    };
    this.dispatchCustom('before-row-render', event);
    return event.template;
  }

  fetchEvent() {
    this.dispatchCustom('fetch', {
      table: this,
      pageIndex: this.pageIndex,
      pageLength: this.pageLength,
      sortField: this.sortField,
      sortAscending: this.sortAscending
    });
  }

  rowClickEvent(item: object) {
    this.dispatchCustom('rowclick', { table: this, item: item });
  }

  rowSelectEvent() {
    this.dispatchCustom('rowselect', { table: this, selectedkeys: this.selectedKeys });
  }

  cellClickEvent(originalEvent: Event, item: object, colDef: WebModuleLitTableColumnDef) {
    this.dispatchCustom('cellclick', { table: this, item: item, colDef: colDef, originalEvent: originalEvent });
  }

  getPrimaryKey(item: object): unknown {
    if (this.keyevent) return this.keyevent(item);
    return item;
  }

  getItemRowClass(item: object): string {
    return this.itemRowClassEvent?.(item) ?? '';
  }

  getRowId(item: object): string | null {
    if (this.rowidevent) return this.rowidevent(item);

    return null;
  }

  enableDragOnRow(item: object) {
    if (this.enableDrag !== 'true') return false;

    if (this.checkEnabledDragEvent) return this.checkEnabledDragEvent(item);

    //Default is to return true
    return true;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  /**
   * override this and validate the page is allowed against a max page etc
   * @param value
   * @returns
   */
  protected pageNumberCheck(value: number): number {
    return value;
  }

  protected async firstUpdated() {
    const data = await this.prepareData();
    if (data) this.data = data;
    else this.fetchEvent();
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async prepareData(): Promise<object[] | null> {
    return null;
  }

  protected render(): TemplateResult {
    const paginator =
      this.pageCount > 1
        ? html` <webmodule-lit-table-paginator
            @page-change=${(e: CustomEvent) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
              (this.pageIndex = e.detail.index)}
            index=${this.pageIndex}
            count=${this.pageCount}
          ></webmodule-lit-table-paginator>`
        : html``;
    return html`
      <div class="simple-table ${this.tablestyle} ">
        <div class="simple-table-header">
          <div class="${this.rowClass} simple-table-header-row">${this.headerTemplate()}</div>
        </div>
        <div class="simple-table-body">${until(this.bodyTemplate(), html``)}</div>
        <div class="simple-table-footer">${this.footerTemplate()}</div>

        ${paginator}
      </div>
    `;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async bodyTemplate(): Promise<TemplateResult | TemplateResult[]> {
    const data = this.getPageData();
    return html`${repeat(
      data,
      item => this.getPrimaryKey(item),
      (item, index) => this.rowTemplate(item, index)
    )}`;
  }

  protected columnTemplate(
    item: any,
    itemIndex: number,
    colDef: WebModuleLitTableColumnDef,
    colindex: number
  ): TemplateResult {
    const clickEvent = (colDefClick: WebModuleLitTableColumnDef) =>
      colDefClick.click
        ? (e: Event) => {
            if (!colDefClick.click?.(e, this, item, colDefClick.fieldName)) this.cellClickEvent(e, item, colDefClick);
            else e.stopImmediatePropagation();
          }
        : (e: Event) => {
            //dont stop propagation. row click may want this
            this.cellClickEvent(e, item, colDefClick);
          };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    const displayVal = colDef.displayValue?.(this, item, itemIndex, colDef.fieldName) ?? item[colDef.fieldName] ?? '';

    const posClass = colindex === 0 ? 'first' : colindex === this.columns.length - 1 ? 'last' : '';
    const colClasses = `${colDef.classes} ${posClass}`;

    return colDef.click
      ? html` <div role="button" @click=${clickEvent(colDef)} class="${this.colClass} ${colDef.classes} ">
          ${displayVal}
        </div>`
      : html` <div @click=${clickEvent(colDef)} class="${this.colClass} ${colClasses} ">${displayVal}</div>`;
  }

  private isVisible(column: WebModuleLitTableColumnDef): boolean {
    if (column.visible !== undefined) {
      return column.visible;
    }

    // Default to true if visible is not set
    return true;
  }

  public rowTemplate(item: object, index: number): unknown {
    const cols = this.columns
      .filter(x => this.isVisible(x))
      .map((colDef, colindex) => this.columnTemplate(item, index, colDef, colindex));

    const itemRowClasses = this.getItemRowClass(item);
    const extensionPromise = this.createExtensionTemplate(item);
    const extensionTemplateWrapper = extensionPromise
      ? html` <div class="${this.rowClass} simple-table-row-extension">
          ${until(extensionPromise, html`<h1>Loading....</h1>`)}
        </div>`
      : undefined;

    const rowClick = (e: Event) => {
      e.stopImmediatePropagation();
      this.rowClicked(item);
    };
    // check if current item key is in array, if so, add custom class to highlight row
    const itemKey = this.getPrimaryKey(item);
    const isSelected = this.selectedKeys.indexOf(itemKey) > -1;
    const enableDragDrop = this.enableDragOnRow(item);
    const noDrop: string = this.enableDrag === 'true' && !enableDragDrop ? this.noDropClass : '';

    const clickable = this.clickrows || this.isSelectable;
    const beforeRowTemplate = this.beforeRowTemplate(item, index);
    const afterRowTemplate = this.afterRowTemplate(item, index);

    return html`${beforeRowTemplate}
      <div
        draggable=${enableDragDrop ? 'true' : nothing}
        @click=${clickable ? rowClick : undefined}
        role=${clickable ? 'button' : ''}
        class="${this.rowClass} ${itemRowClasses} simple-table-row ${isSelected ? 'selected' : ''} ${noDrop}"
        @dragstart="${this.handleDragStart}"
        @dragover="${this.handleDragOver}"
        @dragleave="${this.handleDragLeave}"
        @drop="${this.handleDrop}"
        @dragend="${this.handleDragEnd}"
        data-index="${index}"
        data-rowid=${this.getRowId(item) ?? nothing}
      >
        ${cols}
      </div>
      ${extensionTemplateWrapper ?? ''}${afterRowTemplate}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleTouchCancel(_e: TouchEvent) {
    this.cleanupDragEnd();
  }

  private dragtouchstart(event: TouchEvent) {
    console.log('touchStart');
    const row: HTMLDivElement = (event.target as HTMLElement).closest(`.${this.rowClass}`)!;

    if (!row.draggable) return;

    row.classList.add('ghost-row');
    this.dragSrcEl = row;
    this.draggedIndex = Number(row.dataset.index);
  }

  private dragtouchmove(event: TouchEvent) {
    event.preventDefault();
    console.log('touchMove');
    const touch = event.touches[0];

    const targetRow: HTMLDivElement | null | undefined = document
      .elementFromPoint(touch.clientX, touch.clientY)
      ?.closest(`.${this.rowClass}[draggable="true"]`);
    if (!targetRow) {
      this.lastDropTarget?.classList.remove('drop-target-at');
      this.lastDropTarget = null;
      return;
    }

    if (targetRow !== this.dragSrcEl && targetRow !== this.lastDropTarget) {
      this.lastDropTarget?.classList.remove('drop-target-at');

      targetRow.classList.add('drop-target-at');
      this.lastDropTarget = targetRow;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private dragtouchend(_event: TouchEvent) {
    if (this.lastDropTarget) this.dropHandle(this.lastDropTarget);

    this.cleanupDragEnd();
  }

  private handleDragStart(e: DragEvent): void {
    const target = e.currentTarget as HTMLDivElement;

    if (this.enableDrag !== 'true') {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    this.dragSrcEl = target;
    this.draggedIndex = Number(target.dataset.index);
    target.classList.add('ghost-row');
    e.dataTransfer!.effectAllowed = 'move';
    e.dataTransfer!.setDragImage(target, 0, 0);
  }

  private handleDragOver(e: DragEvent): void {
    e.preventDefault();
    e.dataTransfer!.dropEffect = 'move';
    const target = e.currentTarget as HTMLDivElement;

    if (this.enableDrag !== 'true') {
      e.stopPropagation();
      return;
    }

    if (target !== this.dragSrcEl) {
      target.classList.add('drop-target-at');
    }
  }

  private handleDragLeave(e: DragEvent): void {
    const target = e.currentTarget as HTMLElement;
    target.classList.remove('drop-target-at');
  }

  private handleDrop(e: DragEvent) {
    e.preventDefault();
    const target = e.currentTarget as HTMLElement;
    this.dropHandle(target);
  }

  private dropHandle(target: HTMLElement) {
    if (this.enableDrag !== 'true') {
      return;
    }

    if (target !== this.dragSrcEl && this.draggedIndex !== null && !target.classList.contains(this.noDropClass)) {
      const insertAt = Number(target.dataset.index);
      const insertObject: WebModuleTableItemMove = { sourceIndex: this.draggedIndex, targetIndex: insertAt };
      this.dispatchCustom('webmodule-table-item-move', insertObject);
    }
  }

  private handleDragEnd(e: DragEvent): void {
    e.preventDefault();
    this.cleanupDragEnd();
  }

  private cleanupDragEnd() {
    const rows = this.querySelectorAll(`.${this.rowClass}`);
    rows.forEach(row => {
      row.classList.remove('ghost-row');
      row.classList.remove('drop-target-at');
    });
    // Ensure cleanup on drag end
    this.dragSrcEl = null;
    this.draggedIndex = null;
  }

  protected createExtensionTemplate(item: object): Promise<TemplateResult> | null {
    const itemKey = this.getPrimaryKey(item);
    const extensionProvider = this._extensions.find(x => x.itemKey === itemKey)?.extensionProvider;
    if (!extensionProvider) return null;
    if (typeof extensionProvider === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const func = extensionProvider as WebModuleLitTableRowExtensionTemplateEvent;
      return Promise.resolve(func(item));
    } else return Promise.resolve(extensionProvider);
  }

  protected rowClicked(item: object) {
    if (this.isSelectable) {
      const itemKey = this.getPrimaryKey(item);
      if (this.selectmode === 'single') {
        this.selectedKeys = [itemKey];
      } else if (this.selectmode === 'multi') {
        const keyIndex = this.selectedKeys.indexOf(itemKey);
        if (keyIndex === -1) {
          this.selectedKeys = [...this.selectedKeys.filter(x => x !== itemKey), itemKey];
        } else {
          this.selectedKeys = this.selectedKeys.filter(x => x !== itemKey);
        }
      }
      this.rowSelectEvent();
    }
    if (this.clickrows) {
      this.rowClickEvent(item);
    }
  }

  protected getPageData(): object[] {
    //can be converted to return a range for the page;
    return this.data;
  }

  protected calculateFirstIndex(_data: unknown[], pageIndex: number, pageLength: number) {
    return pageIndex * pageLength;
  }

  protected getColumnHeaderTemplate(defs: WebModuleLitTableColumnDef) {
    const columnSortEvent = (e: Event) => {
      e.stopImmediatePropagation();
      if (this.sortField === defs.fieldName) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.sortField = defs.fieldName;
        this.sortAscending = defs.isDefaultSortDirectionAscending ?? true;
      }
      this.fetchEvent();
    };

    if (defs.sortable) {
      const isAscendingSort = this.isSortingAscending ?? defs.isDefaultSortDirectionAscending;
      const isColumnBeingSorted = this.sortField === defs.fieldName;
      const sortDirectionClass = isColumnBeingSorted ? (isAscendingSort ? 'sorting_asc' : 'sorting_desc') : '';
      return html` <div
        role="button"
        @click=${columnSortEvent}
        class="${this.colClass} ${defs.classes}
        sorting ${sortDirectionClass}"
      >
        ${awaitableTemplate(defs.title)}
      </div>`;
    }
    return html` <div class="${this.colClass} ${defs.classes} sorting-disabled">${awaitableTemplate(defs.title)}</div>`;
  }

  protected headerTemplate(): unknown {
    const headers = this.columns.filter(x => this.isVisible(x)).map(defs => this.getColumnHeaderTemplate(defs));
    return html`${headers}`;
  }

  protected footerTemplate(): unknown {
    if (!this.columns.some(x => typeof x.columnAggregate === 'function' && this.isVisible(x))) return html``;

    const getTotalColTemplate = (column: WebModuleLitTableColumnDef) => {
      const value = typeof column.columnAggregate === 'function' ? column.columnAggregate() : html``;

      return html` <div class="${this.colClass} ${column.classes}">${value}</div>`;
    };

    const cols = this.columns.filter(x => this.isVisible(x)).map(colDef => getTotalColTemplate(colDef));

    return html` <div class="${this.rowClass} simple-table-row">${cols}</div>`;
  }

  private dispatchCustom(name: string, detail: object) {
    const newEvent = new CustomEvent(name, {
      detail: detail,
      bubbles: false,
      composed: true
    });
    this.dispatchEvent(newEvent);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-lit-table-paginator': WebModuleLitTablePaginator;

    'webmodule-lit-table': WebModuleLitTable;
  }
}
