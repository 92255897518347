import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { currentUserClaims } from '../../../webmodule-common/other/currentuser-claims';
import { getCredentials } from '../../api/licenseserver-credentials';
import { html } from 'lit';
import {
  ILicenseServerUIStaffPageProvider,
  IStaffPage,
  licenseServerUIBootLoader,
  ViewBaseLogin
} from '../data/licenseserver-integration';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import { tlang } from '../../../webmodule-common/other/language/lang';

@autoElement()
export class SettingsStaffView extends PageControlTabWithIndependantSaving {
  private staffPageProvider: ILicenseServerUIStaffPageProvider;
  private userConsole?: IStaffPage;

  constructor() {
    super();
    this.pageFragment = 'staff';

    this.staffPageProvider = this.staffPageProviderFactory();

    console.log(JSON.stringify(this.staffPageProvider.getColumnProperties?.()));
  }

  public async afterConstruction(): Promise<void> {
    const licUI = await licenseServerUIBootLoader();

    this.userConsole = licUI?.getStaffPage(this.staffPageProvider);
  }

  public allowDeletePage(): boolean {
    return false;
  }

  async prepareForSave(): Promise<void> {
    //
  }

  public internalDataChanged(): boolean {
    return false;
  }

  async onEnter(): Promise<void> {
    this.requestUpdate();
  }

  public buttonMenu(): Snippet {
    const isAdmin = currentUserClaims().isAdmin;
    const buttons = this.userConsole?.actions().map(x => {
      return html` <button class="btn btn-primary" ?disabled=${!x.enabled || !isAdmin} @click=${x.event}>
        ${x.label}
      </button>`;
    });
    return html`${buttons}`;
  }

  protected getCaption(): Snippet {
    return tlang`Staff`;
  }

  protected async internalSaveData(): Promise<boolean> {
    //nothing to do
    return true;
  }

  protected async bodyTemplate(): PromiseTemplate {
    return this.userConsole?.template() ?? html``;
  }

  protected staffPageProviderFactory(): ILicenseServerUIStaffPageProvider {
    return {
      credentials: getCredentials(),
      onAfterSave: async (login: ViewBaseLogin) => {
        console.log(`login saved ${login.baseLogin.userName}`);
      },
      onBeforeSave: async (login: ViewBaseLogin) => {
        console.log(`login saving ${login.baseLogin.userName}`);
        return true;
      },
      canEditUser: (_viewLogin: ViewBaseLogin): boolean => {
        //full admin can edit anyone
        const claims = currentUserClaims();
        if (claims.isAdmin) return true;
        //if admin, and only admin, we can see everything.
        return false;
      },

      uiChanged: () => this.requestUpdate()
    };
  }
}
