import { customElement, query, state } from 'lit/decorators.js';
import { html } from 'lit';
import { ICommandAction } from '../../../webmodule-common/other/ui/command-action';
import { LitElementBase } from '../../../webmodule-common/other/litelement-base';
import { Territory } from '../../api/supplier-api-interface-supplier';
import { TerritoryContainerManager } from '../data/territory-manager';
import { TerritoryEvent } from '../view/settings-region-view';
import { tlang } from '../../../webmodule-common/other/language/lang';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

@customElement('softtech-supplier-ui-regions')
export default class TerritoryRegionsTableElement extends LitElementBase {
  @query('#settings-regions-table')
  table?: WebModuleLitTable;

  editEvent: TerritoryEvent | undefined;
  deleteEvent: TerritoryEvent | undefined;
  eventTerritoryClick: ((linkData: Territory) => void) | undefined;

  @state()
  private _territoryManager: TerritoryContainerManager | undefined;

  public get territoryManager(): TerritoryContainerManager | undefined {
    return this._territoryManager;
  }

  public set territoryManager(value: TerritoryContainerManager | undefined) {
    this._territoryManager = value;
    this.table?.fetchEvent();
  }

  @state()
  private _data: Territory[] | undefined;

  private get data(): Territory[] | undefined {
    return this._data;
  }

  private set data(value: Territory[] | undefined) {
    this._data = value;
  }

  public refreshData() {
    if (this.table) {
      this.table.fetchEvent();
    }
  }

  render() {
    const keyEvent = (item: Territory) => {
      return item.id;
    };

    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: tlang`Name`,
      classes: 'colpx-300',
      fieldName: 'name',
      sortable: true,
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as Territory;
        return html`${rowItem.name}`;
      }
    });
    columns.push({
      title: '',
      classes: 'colpxmax-90 ',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as Territory;
        const deleteEvent = (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          this.doDeleteTerritory(rowItem);
        };
        const updateEvent = (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          this.doUpdateTerritory(rowItem);
        };
        const iconTemplate = (icon: string, event: (e: Event) => void) => html`
          <webmodule-icon-button library="fa" name=${icon} @click=${event}></webmodule-icon-button>
        `;
        return html` <div class="">
          ${iconTemplate('trash-can', deleteEvent)} ${iconTemplate('pen-to-square', updateEvent)}
        </div>`;
      }
    });

    return html` <div class="settings-regions">
      <webmodule-lit-table
        id="settings-regions-table"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .clickrows=${true}
        @rowclick=${this.rowClick}
        .columns=${columns}
        pageLength="100"
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
        selectmode="single"
      >
      </webmodule-lit-table>
    </div>`;
  }

  getActions(): ICommandAction[] {
    return [];
  }

  protected async doUpdateTerritory(territory: Territory) {
    await this.editEvent?.(territory);
  }

  protected async doDeleteTerritory(territory: Territory) {
    await this.deleteEvent?.(territory);
  }

  protected rowClick = (e: CustomEvent<{ item: Territory }>) => {
    const territory = e.detail.item;
    this.eventTerritoryClick?.(territory);
  };

  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    const table = e.detail.table as WebModuleLitTable;
    const index = (e.detail?.pageIndex as number) ?? 0;
    const length = (e.detail?.pageLength as number) ?? 1000;
    const sortField = (e.detail?.sortField as string) ?? '';
    const sortAscending = (e.detail?.sortAscending as boolean) ?? true;

    if (this.territoryManager) {
      await this.territoryManager.needsTerritories();
      this.data = [...this.territoryManager.territories];
    }
    if (!this.data) {
      table.data = [];
    } else if (this.data) {
      const sortedData = this.data.sort((a, b) => {
        if (sortField === 'name') {
          const result = a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base'
          });
          return sortAscending ? result : result * -1;
        }
        return 0;
      });
      table.data = sortedData.slice(index * length, index * length + length);
      table.rowCount = sortedData.length;
    }
  }

  private _loadExtension(_e: CustomEvent) {
    //do nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'softtech-supplier-ui-regions': TerritoryRegionsTableElement;
  }
}
