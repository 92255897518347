//this represents the object layer of the data layer
import * as db from './indexed-db';
import { emptyGuid } from '../other/api/guid';
import { IndexedDBAccess as IndexedDbAccess } from './indexed-db-wrapper';
import { INoSqlDb, JsonData } from './no-sql-db-intf';

export type guid = string;

export const stores = {
  all: 'all'
};

function createVersion1(idb: IDBDatabase) {
  db.newDbItemObjectStore(idb, {
    name: stores.all
  });
}

export interface DatabaseLocalCache {
  all: JsonData[] | null;
}

export const nextDayExpiry = -100;

export class NoSqlDb extends IndexedDbAccess implements INoSqlDb {
  public cache: DatabaseLocalCache = { all: null };
  public lastFetch: JsonData | null = null;
  private _dbName = 'wm-no-sql';

  public dbName(): string {
    return this._dbName;
  }

  public async clear() {
    const conn = await this.connect();
    db.deleteAll(conn, stores.all);
    this.close();
  }

  async getJsonData(key: string, userId?: string): Promise<JsonData | null> {
    const idxKey = this.getId(key, userId ?? emptyGuid);
    const jsonData = await this.getFirst<JsonData>(stores.all, { query: idxKey });
    if (!jsonData) return null;
    if (this.expired(jsonData.created, jsonData.expires)) {
      await this.del(idxKey, stores.all);
      return null;
    }
    return jsonData;
  }

  async getItem<T>(key: string, userId?: string): Promise<T | null> {
    const jsonData = await this.getJsonData(key, userId);

    return (jsonData?.data as T) ?? null;
  }

  async delItem(key: string, userId?: string) {
    const idxKey = this.getId(key, userId ?? emptyGuid);
    await this.del(idxKey, stores.all);
  }

  async setItem<T>(key, value: T, expires?: number) {
    return this.internalSetItem(key, value, expires ?? 604800);
  }

  async setUserItem<T>(key, userId: string, value: T, expires?: number) {
    return this.internalSetItem(key, value, expires ?? 604800, userId);
  }

  async fetch<T>(url: string, expiresSeconds?: number, userId?: string, force?: boolean): Promise<T | null> {
    const lastFetch = !force ? await this.getJsonData(url, userId) : null;
    try {
      if (!lastFetch) {
        const result = await this.getJsonFile(url);
        if (!result) return null;
        await this.setUserItem(url, userId ?? emptyGuid, result, expiresSeconds ?? 604800);

        return result;
      }

      return lastFetch.data as T;
    } finally {
      this.lastFetch = lastFetch;
    }
  }

  //return a list of upgrades that get this database to the correct version
  protected getDatabaseUpgrades(): db.DatabaseUpgrade[] {
    return [{ version: 1, execute: createVersion1 }];
  }

  private getId(key: string, userId: string = emptyGuid) {
    return `${userId}:${key}`;
  }

  private sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }

  private expired(createDate: Date, expiresSeconds: number): boolean {
    if (expiresSeconds === nextDayExpiry) {
      const today = new Date();
      return !this.sameDay(today, createDate);
    }

    const now = Date.now();
    return createDate.getTime() + expiresSeconds * 1000 < now;
  }

  private async internalSetItem<T>(key: string, value: T, expires: number = 604800, userId: string = emptyGuid) {
    const item: JsonData = {
      id: this.getId(key, userId),
      data: value,
      created: new Date(),
      expires: expires
    };
    await super.put(item, stores.all);
  }

  private async getJsonFile(path) {
    try {
      const response = await fetch(path, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
      if (response.status === 200) return await response.json();
      return null;
    } catch {
      return null;
    }
  }
}

export const noSqlDb = new NoSqlDb();

(function () {
  globalThis.noSqlDb = noSqlDb;
})();
