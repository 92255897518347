import { claimIdentity, setUserSecurity } from './user-security.js';
import type { IUserSecurity } from './user-security.js';
import { StringDictionary } from '../../interop/types/misc';
import { globalState } from '../ui/global-state';

export type EventNotify = () => void | Promise<void>;

export interface UserPublicInfo {
  id: string;
  tenantId: number;
  userName: string;
  friendlyName: string;
  emailAddress: string;
  tokenDateExpires: string; // of date
  Is2FAEnabled?: boolean;
}

export interface TenantLoginPasswordChangeRequired {
  token: string;
}

export interface ResultTenantLogin {
  authenticationToken: string;
  publicInfo: UserPublicInfo;
  passwordChangeRequired?: TenantLoginPasswordChangeRequired;
  requires2FA: boolean;
  token2FA?: string;
}

interface IUserInformation {
  profile: UserPublicInfo | null;
  claims: StringDictionary | null;
  eventApplyUser: EventNotify | null;
}

globalState().userInformation = {
  profile: null,
  claims: null,
  eventApplyUser: null
};

function userInformation(): IUserInformation {
  return globalState().userInformation;
}

export function setAfterApplyUserEvent(event: EventNotify | null) {
  userInformation().eventApplyUser = event;
}

export function tenantId(): number {
  return getCurrentUser()?.tenantId ?? 0;
}

export function getCurrentUser(force?: boolean): UserPublicInfo | null {
  if (!userInformation().profile || force) {
    const s = localStorage.getItem(apiUserName);
    if (!s) return null;
    userInformation().profile = JSON.parse(s) as UserPublicInfo;
  }
  return userInformation().profile;
}

export type UserStateNotifierEvent = (user: UserPublicInfo | null) => void | Promise<void>;

export interface CurrentUserStateNotifier {
  userStateChanged: (user: UserPublicInfo | null) => void | Promise<void>;
}

let callbackEvents: CurrentUserStateNotifier[] = [];

export function addCurrentUserEventListener(notifer: CurrentUserStateNotifier) {
  if (!callbackEvents.find(x => x === notifer)) {
    callbackEvents.push(notifer);
    //moving this to make the call in a timeout so that it is not happening in a constructor
    setTimeout(() => notifer.userStateChanged(getCurrentUser()), 5);
  }
}

export function removeCurrentUserEventListener(notifer: CurrentUserStateNotifier) {
  if (callbackEvents.find(x => x === notifer)) {
    callbackEvents = callbackEvents.filter(x => x !== notifer);
  }
}

export function setUserClaims(userClaims?: { [key: string]: string } | null) {
  userInformation().claims = userClaims ?? null;
  // Make a default implementation
  setUserSecurity(new DefaultUserSecuritySingleton());
}

export async function setCurrentUser(user: UserPublicInfo | null, apiToken?: string): Promise<void> {
  const userInfo = userInformation();
  userInfo.profile = user;

  if (userInfo.profile) {
    localStorage.setItem(apiUserName, JSON.stringify(userInfo.profile));
    if (apiToken) setApiToken(apiToken);
  } else {
    localStorage.removeItem(apiUserName);
    setApiToken(null);
  }
  await userInfo.eventApplyUser?.();
  callbackEvents.forEach(x => x.userStateChanged(user));
}

const apiTokenName = 'webmodule:v1:authentication-token';
const apiUserName = 'webmodule:v1:user-profile';

//Authentication is handled via an embedded inline authentication system which will apply any token recoved via authentication into
//this local location.
export function setApiToken(token: string | null) {
  if (token) localStorage.setItem(apiTokenName, token);
  else localStorage.removeItem(apiTokenName);
}

export function getApiToken(): string {
  //always load token to ensure responsiveness to cache deletions
  const token = localStorage.getItem(apiTokenName);
  return token ?? '';
}

class DefaultUserSecuritySingleton implements IUserSecurity {
  private get userClaims(): { [key: string]: string } {
    return userInformation().claims ?? {};
  }

  public claimExists(claimName: string): boolean {
    return this.userClaims[claimName] !== undefined;
  }

  public claim(claimName: string): string {
    return this.userClaims[claimName] ?? '';
  }

  public claimIsTrue(claimName: string): boolean {
    return this.userClaims[claimName] === 'true';
  }

  public hasRole(roleName: string): boolean {
    return this.claimIsTrue(`role-${roleName}`);
  }

  public isSupplier() {
    return this.claimIsTrue(claimIdentity.supplier);
  }

  public isPowerUser(): boolean {
    return this.claimIsTrue(claimIdentity.frameConfigPowerUser);
  }

  public isAdmin(): boolean {
    return this.claimIsTrue(claimIdentity.admin);
  }

  supplierId() {
    return this.getUserSupplierId() ?? 0;
  }

  supplierGuid() {
    return this.getUserSupplierGuid() ?? '00000000-0000-0000-0000-000000000000';
  }

  public getUserSupplierId(): number | undefined {
    if (this.isSupplier()) {
      const val = parseInt(this.userClaims[claimIdentity.supplierId] ?? '0');

      return isNaN(val) ? undefined : val;
    }
    return undefined;
  }

  public getUserSupplierGuid(): string | undefined {
    if (this.isSupplier()) {
      return this.userClaims[claimIdentity.supplierId];
    }
    return undefined;
  }
}
