import { TemplateResult } from 'lit-html';

export interface FormInputSelectValue {
  text: string | TemplateResult;
  value: string;
  disabled: boolean;
}

export function dlSelectValues(input: any[], convert: (x: any) => FormInputSelectValue): string {
  return JSON.stringify(input.map(x => convert(x)));
}

export function dlSelectValuesArray(input: any[], convert: (x: any) => FormInputSelectValue): FormInputSelectValue[] {
  return input.map(x => convert(x));
}

export function getEnumKeys(e: any, hideValues?: number[]): number[] {
  return Object.keys(e)
    .filter(o => {
      const value = parseInt(o);
      if (isNaN(value)) return false;
      return !(hideValues && hideValues.includes(value));
    })
    .map(x => parseInt(x));
}

export function getEnum<E>(e: E, value) {
  return getEnumKeys(e)[value];
}

export function dlEnumSelectValues<E>(e: E, hideValues?: number[], map?: EnumValueToString): string {
  return dlSelectValues(getEnumKeys(e, hideValues), (key: number) => {
    const text = map?.(key) ?? e[key];
    return { text: text, value: key.toString(), disabled: false };
  });
}

export function dlEnumSelectValuesArray<E>(
  e: E,
  hideValues?: number[],
  map?: EnumValueToString
): FormInputSelectValue[] {
  return dlSelectValuesArray(getEnumKeys(e, hideValues), key => {
    const text = map?.(key) ?? e[key];
    return { text: text, value: key.toString(), disabled: false };
  });
}

/**
 *
 * @param input an array of objects
 * @param convert a converter to turn an object from input into a FormInputSelect
 * @returns a stringified json object to pass as a parameter
 *
 * * @example
 *
 * ```ts
 *
 * let test = [ {name:"bob" , id:1, age:34},{name:"tom" ,age:27, id:2} ]
 *
 * const options = MapArrayToFormInputSelectValue(test,(obj:any)=> {text:obj.name, value:obj.id} )
 * let template = html`<bs-form-select options=${options} />`
 *
 * ```
 *
 */
export type EnumValueToString = (value: number) => string | TemplateResult;

export function MapArrayToFormInputSelectValue<ObjectType>(
  input: ObjectType[],
  convert: (x: ObjectType) => FormInputSelectValue
): FormInputSelectValue[] {
  return input.map(x => convert(x));
}

/**
 *
 * @param typeValue the enumerated type to convert to a picker selection
 *
 * @param hideValues
 * if false, we exlude the 0
 * @param map
 * @returns a string containing jsonarray of data to pass to the options attribute for <bs-form-select>
 *
 * @example
 *
 * ```ts
 * enum Test
 * {
 *    all = 0,
 *    one = 1,
 *    two = 2
 * }
 * const options = ConvertEnumToFormSelectOptions(Test, true)
 * let template = html`<bs-form-select options=${options} />`
 *
 * ```
 *
 */
export function ConvertEnumToFormSelectOptions<EnumeratedType>(
  typeValue: EnumeratedType,
  hideValues?: number[],
  map?: EnumValueToString
) {
  return dlEnumSelectValues(typeValue, hideValues, map);
}

export function ConvertEnumToFormSelectOptionsArray<EnumeratedType>(
  typeValue: EnumeratedType,
  hideValues?: number[],
  map?: EnumValueToString
): FormInputSelectValue[] {
  return dlEnumSelectValuesArray(typeValue, hideValues, map);
}
