import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventSnippet } from '../../../webmodule-common/interop/webmodule-interop';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html, TemplateResult } from 'lit-html';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { showValidationsWithDiscard } from '../../../webmodule-common/other/ui/modal-validationhandler';
import { Territory } from '../../api/supplier-api-interface-supplier';
import { TerritoryContainerManager } from '../data/territory-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { autoElement } from '../../../webmodule-common/components/src/common/lit-decorator-extended';

export interface RegionEditModalOptions {
  territory: Territory;
  modalTitle: EventSnippet;
  saveButtonTitle: EventSnippet;
  cancelButtonTitle: EventSnippet;
  forceReadonly: boolean;
  territoryManager: TerritoryContainerManager;
}

export enum RegionEditResult {
  None,
  Edit
}

@autoElement()
export class ManageTerritoryModal extends ModalViewBase {
  public ok = RegionEditResult.None;
  protected readonly options: RegionEditModalOptions;
  protected readonly dataBinding: DataBinding;
  protected readonly dataTracker: DataTracker;

  constructor(options: RegionEditModalOptions) {
    super();

    this.options = options;
    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `region-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.options.territory),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('name', FieldType.string, true);
  }

  public get readonly(): boolean {
    return this.options.forceReadonly;
  }

  public get pricingRule(): Territory {
    return this.options.territory;
  }

  /**
   * inherited
   * @returns
   */
  protected get modalSize() {
    return 'modal-lg';
  }

  public async afterConstruction(): Promise<void> {
    this.loadOrRefresh();
  }

  /**
   * inherited
   * @returns
   */
  getTitle() {
    return this.options.modalTitle() ?? '';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    if (!this.prepareForSave()) {
      return true;
    }
    const errors = await this.getValidationErrors();
    if (errors.length != 0) {
      const discardChanges = await showValidationsWithDiscard(errors);
      if (discardChanges) {
        this.dataTracker.resetEditorValue();
        return true;
      }
      return false;
    } else if (isAutoSaving()) {
      this.ok = await this.save();
      await this.hideModal();
    }
    return true;
  }

  protected loadOrRefresh() {
    this.requestUpdate();
  }

  protected bodyTemplate() {
    const forms = new FormInputAssistant(this.dataTracker);

    return html` <form class="form-one-col">
      <div class="row">
        <div class="form-column">${forms.textRequired('name', tlang`%%territory%% Name`, 100)}</div>
      </div>
    </form>`;
  }

  protected footerTemplate(): TemplateResult | null {
    const okEvent = async () => {
      const needsSave = this.dataTracker.modified;
      if (!needsSave) {
        await this.hideModal();
        return;
      }
      this.prepareForSave();
      const errors = await this.getValidationErrors();
      if (errors.length != 0) {
        const discardChanges = await showValidationsWithDiscard(errors);
        if (discardChanges) {
          this.dataTracker.resetEditorValue();
        }
      } else {
        this.ok = await this.save();
        await this.hideModal();
      }
    };
    const cancelEvent = async () => {
      await this.hideModal();
    };
    return this.createConfirmCancelButtons(
      this.options.saveButtonTitle(),
      okEvent,
      this.options.cancelButtonTitle(),
      cancelEvent
    );
  }

  protected prepareForSave(): boolean {
    if (this.readonly) return false;

    this.dataTracker.applyChangeToValue();
    return true;
  }

  protected async save(): Promise<RegionEditResult> {
    const result = await this.options.territoryManager.updateAndSaveTerritory(this.options.territory);
    this.ok = result ? RegionEditResult.Edit : RegionEditResult.None;
    return this.ok;
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];

    const territoryName = this.dataBinding.getValue('name');
    if (isEmptyOrSpace(territoryName)) {
      errors.push(tlang`No %%territory%% name specified`);
    }

    return errors;
  }
}
