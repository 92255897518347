import type {
  CynclyLoginTenantAccess,
  InputAddUser,
  InputChangeTenantLoginProperties,
  InputTenantListFilterUpdate,
  InputUpdateMyUserDetails,
  LoginPublicInfoPrivate,
  RequestPAT,
  ResultCheckUserName,
  ResultCynclySupportTenantList,
  ResultCynclySupportTenantListFilter,
  ResultFullSupplierNetwork,
  ResultPAT,
  ResultSecurityRoles,
  ResultTenantLicenseInformation,
  ResultTenantLoginsView,
  ResultUserClaims,
  SystemViewDeployment,
  UpdateCynclyLoginTenantAccess,
  ViewBaseLogin
} from './api-interface-licenseserver';
import { ApiCommunications } from '../../webmodule-common/interop/interfaces/api-communications';
import { NullPromise } from '../../webmodule-common/interop/webmodule-interop';

export class FetchError extends Error {
  statusCode = 400;

  constructor(statuscode: number, message: string) {
    super(message);
    this.statusCode = statuscode;

    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, FetchError.prototype);
  }

  get errorMessage(): string {
    return `Fetch Error: ${this.statusCode} - ${this.message}`;
  }
}

export class LicenseServerAPI {
  private api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  get<ResultType>(path: string): Promise<ResultType | null> {
    return this.api.get(path, false, true);
  }

  async fetch<ResultType>(path: string, input: object): Promise<ResultType | null> {
    return await this.api.post<ResultType>(path, input, false, true);
  }

  async checkUserName(userName: string): NullPromise<ResultCheckUserName> {
    return await this.get<ResultCheckUserName>(`api/Login/CheckUserName?userName=${encodeURI(userName)}`);
  }

  async getSecurityRoles(): NullPromise<ResultSecurityRoles> {
    return await this.fetch<ResultSecurityRoles>('api/Access/GetSecurityRoles', {});
  }

  async getTenantLogins(): NullPromise<ResultTenantLoginsView> {
    return await this.fetch<ResultTenantLoginsView>('api/Access/GetTenantLogins', {});
  }

  async getSupportTenantList(): NullPromise<ResultCynclySupportTenantList> {
    return await this.fetch<ResultCynclySupportTenantList>('api/Access/SupportTenantList', {});
  }

  async getSupportTenantListFilter(): NullPromise<ResultCynclySupportTenantListFilter> {
    return await this.fetch<ResultCynclySupportTenantListFilter>('api/Access/SupportTenantListFilter', {});
  }

  async supportTenantListFilterUpdate(
    input: InputTenantListFilterUpdate
  ): NullPromise<ResultCynclySupportTenantListFilter> {
    return await this.fetch<ResultCynclySupportTenantListFilter>('api/Access/SupportTenantListFilterUpdate', input);
  }

  async getTenantLicenseInformation(): NullPromise<ResultTenantLicenseInformation> {
    return await this.fetch<ResultTenantLicenseInformation>('api/Access/GetTenantLicenseInformation', {});
  }

  async updateMyDetails(input: InputUpdateMyUserDetails): NullPromise<LoginPublicInfoPrivate> {
    return await this.fetch<LoginPublicInfoPrivate>('api/Access/UpdateMyUserDetails', input);
  }

  async updateUserDetails(input: InputChangeTenantLoginProperties): NullPromise<ViewBaseLogin> {
    return await this.fetch<ViewBaseLogin>('api/Access/ChangeTenantLoginProperties', input);
  }

  async addUser(input: InputAddUser): NullPromise<ViewBaseLogin> {
    return await this.fetch<ViewBaseLogin>('api/Access/AddUser', input);
  }

  async getMySecurity(): NullPromise<ResultUserClaims> {
    const x = await this.get<ResultUserClaims>(`api/Login/GetMySecurity`);
    return x;
  }

  async pat(input: RequestPAT): NullPromise<ResultPAT> {
    const x = await this.fetch<ResultPAT>(`api/Access/PATV2`, input);
    return x;
  }

  async getFullSupplierNetwork(): NullPromise<ResultFullSupplierNetwork> {
    return await this.fetch<ResultFullSupplierNetwork>('api/AdminV2/GetFullSupplierNetwork', {});
  }

  async getDeploymentView(id: number): NullPromise<SystemViewDeployment> {
    return await this.fetch<SystemViewDeployment>('api/AdminV2/SystemViewDeployment', { id: id });
  }

  async saveCynclyLoginTenantAccesses(input: UpdateCynclyLoginTenantAccess): NullPromise<CynclyLoginTenantAccess> {
    return await this.fetch<CynclyLoginTenantAccess>('api/AdminV2/UpdateCynclyTenantAccess', input);
  }
}
