//Copyright - Kyley Harris (2021) - All rights to copy and reuse allowed
// a version based approach to upgrading a database

export interface GetOptions {
  indexName?: string;
  query?: IDBValidKey | IDBKeyRange | null;
  direction?: IDBCursorDirection;
}

const debug = false;
export const log = (s: string) => {
  if (debug) console.log(s);
};

export interface DatabaseUpgrade {
  version: number;
  execute: (db: IDBDatabase, tran: IDBTransaction) => void;
}

//db connection plan including any and all upgrades
export interface DatabaseOptions {
  dbName: string;
  version: number;
  upgrades?: DatabaseUpgrade[];
  onBlocked: () => void;
}

export interface CreateIndexOptions {
  name: string;
  keyPath: string | string[];
  options: IDBIndexParameters;
  delete?: boolean;
}

export interface CreateObjectStoreOptions {
  name: string;
  indexes?: CreateIndexOptions[];
}

//may only be called from inside a migration path
export function newDbItemObjectStore(db: IDBDatabase, options: CreateObjectStoreOptions): IDBObjectStore {
  const store = db.createObjectStore(options.name, { keyPath: 'id', autoIncrement: false });
  options.indexes?.forEach(option => {
    if (!option.delete)
      // should never be included.
      store.createIndex(option.name, option.keyPath, option.options);
  });
  return store;
}

//may only be called as part of a migration path
export function updateDbItemObjectStore(tran: IDBTransaction, options: CreateObjectStoreOptions): IDBObjectStore {
  const store = tran.objectStore(options.name);
  options.indexes?.forEach(option => {
    if (!option.delete)
      // should never be included.
      store.createIndex(option.name, option.keyPath, option.options);
    else store.deleteIndex(option.name);
  });
  return store;
}

//promise based db open that manages the upgrade path
export function openDb(options: DatabaseOptions): Promise<IDBDatabase> {
  const promise = new Promise<IDBDatabase>((resolve, reject) => {
    const request = window.indexedDB.open(options.dbName, options.version);
    request.onerror = () => {
      log(`Database Connection Error ${options.dbName}`);
      reject(request.error);
    };
    request.onsuccess = () => {
      log(`Database Connection Open ${options.dbName}`);
      request.result.onclose = () => console.log(`Database Connection Closed ${options.dbName}`);
      resolve(request.result);
    };

    request.onblocked = () => {
      log(`Database Pending until unblocked ${options.dbName}`);
      options.onBlocked && options.onBlocked();
    };

    //scan and update all data
    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      // Save the IDBDatabase interface
      const db = (event.target as IDBOpenDBRequest).result;
      const tran = (event.target as IDBOpenDBRequest).transaction!;
      (options.upgrades || [])
        .filter(x => x.version > event.oldVersion)
        .sort((a, b) => a.version - b.version)
        .map(x => x.execute(db, tran));
    };
  });

  return promise;
}

export function closeDb(conn: IDBDatabase): Promise<void> {
  return new Promise<void>(resolve => {
    conn.close();
    resolve();
  });
}

export function fetchCursor<T>(
  objectStore: IDBObjectStore | IDBIndex,
  options?: { query?: IDBValidKey | IDBKeyRange | null; direction?: IDBCursorDirection }
): Promise<T[]> {
  return new Promise<T[]>((resolve, reject) => {
    const results: T[] = [];
    const cursor = objectStore.openCursor(options?.query, options?.direction) as IDBRequest<IDBCursorWithValue>;
    cursor.onsuccess = (event: any) => {
      const cursor = event.target.result;
      if (cursor) {
        results.push(cursor.value);
        cursor.continue();
      } else resolve(results);
    };
    cursor.onerror = (e: Event) => reject(e);
  });
}

export function deleteAll(conn: IDBDatabase, storeName: string) {
  const tran = conn.transaction(storeName, 'readwrite');
  const store = tran.objectStore(storeName);
  store.clear();
}

export async function get<T>(conn: IDBDatabase, storeName: string, options?: GetOptions): Promise<T | null> {
  const tran = conn.transaction(storeName, 'readonly');
  const store = tran.objectStore(storeName);
  let cursorParent: any = store;
  if (options?.indexName) cursorParent = store.index(options?.indexName);
  const result = await fetchCursor<T>(cursorParent, { query: options?.query, direction: options?.direction });
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<T | null>(async (resolve, reject) => {
    tran.oncomplete = () => {
      log(`transaction complete on ${storeName}`);
      if (result.length > 0) resolve(result[0]);
      else resolve(null);
    };
    tran.onerror = () => {
      log(`transaction error on ${storeName}`);
      reject(tran.error);
    };
  });
}

export function put<T>(conn: IDBDatabase, storeName: string, item: T): Promise<IDBValidKey> {
  const tran = conn.transaction(storeName, 'readwrite');
  const store = tran.objectStore(storeName);
  const request = store.put(item);
  return new Promise<IDBValidKey>((resolve, reject) => {
    tran.oncomplete = () => {
      log(`transaction complete on ${storeName}`);
      resolve(request.result);
    };
    tran.onerror = () => {
      log(`transaction error on ${storeName}`);
      reject(tran.error);
    };
  });
}

export function putAll<T>(conn: IDBDatabase, storeName: string, item: T[]): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const tran = conn.transaction(storeName, 'readwrite');
    tran.oncomplete = () => resolve();
    // eslint-disable-next-line prefer-promise-reject-errors
    tran.onerror = () => reject();
    const store = tran.objectStore(storeName);
    let i = 0;
    putNext();

    function putNext() {
      if (i < item.length) {
        store.put(item[i++]).onsuccess = putNext;
      }
    }
  });
}

export async function getAll<T>(conn: IDBDatabase, storeName: string, options?: GetOptions): Promise<T[]> {
  const tran = conn.transaction(storeName, 'readonly');
  const store = tran.objectStore(storeName);
  let cursorParent: any = store;
  if (options?.indexName) cursorParent = store.index(options?.indexName);
  const result = await fetchCursor<T>(cursorParent, { query: options?.query, direction: options?.direction });

  // eslint-disable-next-line no-async-promise-executor
  return new Promise<T[]>(async (resolve, reject) => {
    tran.oncomplete = () => {
      log(`transaction complete on ${storeName}`);
      resolve(result);
    };
    tran.onerror = () => {
      log(`transaction error on ${storeName}`);
      reject(tran.error);
    };
  });
}

export function del(conn: IDBDatabase, storeName: string, id: IDBValidKey): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const tran = conn.transaction(storeName, 'readwrite');
    const store = tran.objectStore(storeName);
    const request = store.delete(id);
    request.onsuccess = () => {
      log(`transaction complete on ${storeName}`);
      resolve();
    };
    request.onerror = () => {
      log(`transaction error  on ${storeName}`);
      reject(request.error);
    };
  });
}
