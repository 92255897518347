import { autoElement } from '../../../webmodule-common/components/src/common/lit-decorator-extended';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { DataEntryPageControlView } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import {
  MenuIconOption,
  PageButtonLocation,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control';
import { SettingsGeneralView } from './settings-general-view';
import { SettingsLocationsView } from './settings-locations-view';
import { SettingsLoginImageBrandingView } from './settings-login-image-view';
import { SettingsLogoBrandingView } from './settings-branding-view';
import { SettingsPricingRulesView } from './settings-pricing-rules-view';
import { SettingsPurchaseOrdersRecipientsView } from './settings-purchase-orders-view';
import { SettingsRegionView } from './settings-region-view';
import { SettingsStaffView } from './settings-staff-view';
import { SettingsTermsOfUseView } from './settings-terms-of-use-view';
import { userDataStore } from '../../supplier/common/current-user-data-store';

export interface SettingsViewChildFactory {
  getGeneralSettingsview(): Promise<SettingsGeneralView>;

  getLogoBrandingView(): Promise<SettingsLogoBrandingView>;

  getLoginImageBrandingView(): Promise<SettingsLoginImageBrandingView>;

  getTermsOfUsView(): Promise<SettingsTermsOfUseView>;

  getRegionsView(): Promise<SettingsRegionView>;

  getPurchaseOrderRecipientsView(): Promise<SettingsPurchaseOrdersRecipientsView>;

  getPricingRulesView(): Promise<SettingsPricingRulesView>;

  getStaffView(): Promise<SettingsStaffView>;

  getLocationsView(): Promise<SettingsLocationsView>;
}

export class SettingsViewChildFactoryImpl implements SettingsViewChildFactory {
  async getGeneralSettingsview(): Promise<SettingsGeneralView> {
    return await constructAsync(new SettingsGeneralView());
  }

  async getLogoBrandingView(): Promise<SettingsLogoBrandingView> {
    return await constructAsync(new SettingsLogoBrandingView());
  }

  async getLoginImageBrandingView(): Promise<SettingsLoginImageBrandingView> {
    return await constructAsync(new SettingsLoginImageBrandingView());
  }

  async getTermsOfUsView(): Promise<SettingsTermsOfUseView> {
    return await constructAsync(new SettingsTermsOfUseView());
  }

  async getRegionsView(): Promise<SettingsRegionView> {
    return await constructAsync(new SettingsRegionView());
  }

  async getPurchaseOrderRecipientsView(): Promise<SettingsPurchaseOrdersRecipientsView> {
    return await constructAsync(new SettingsPurchaseOrdersRecipientsView());
  }

  async getPricingRulesView(): Promise<SettingsPricingRulesView> {
    return await constructAsync(new SettingsPricingRulesView());
  }

  async getStaffView(): Promise<SettingsStaffView> {
    return await constructAsync(new SettingsStaffView());
  }

  async getLocationsView(): Promise<SettingsLocationsView> {
    return await constructAsync(new SettingsLocationsView());
  }
}

@autoElement()
export class SettingsView extends DataEntryPageControlView {
  childViewFactory: SettingsViewChildFactory;

  generalSettingsView: SettingsGeneralView | null = null;
  logoBrandingView: SettingsLogoBrandingView | null = null;
  loginImageBrandingView: SettingsLoginImageBrandingView | null = null;
  termsOfUseView: SettingsTermsOfUseView | null = null;
  regionsView: SettingsRegionView | null = null;
  orderRecipientsView: SettingsPurchaseOrdersRecipientsView | null = null;
  pricingRulesView: SettingsPricingRulesView | null = null;
  staffView: SettingsStaffView | null = null;

  locationsView: SettingsLocationsView | null = null;

  constructor(childViewFactory: SettingsViewChildFactory) {
    super();

    this.childViewFactory = childViewFactory;
  }

  public async afterConstruction(): Promise<void> {
    await userDataStore.reLoadGlobalSupplier();

    this.generalSettingsView = await this.childViewFactory.getGeneralSettingsview();
    this.logoBrandingView = await this.childViewFactory.getLogoBrandingView();
    this.loginImageBrandingView = await this.childViewFactory.getLoginImageBrandingView();
    this.termsOfUseView = await this.childViewFactory.getTermsOfUsView();
    this.regionsView = await this.childViewFactory.getRegionsView();
    this.orderRecipientsView = await this.childViewFactory.getPurchaseOrderRecipientsView();
    this.pricingRulesView = await this.childViewFactory.getPricingRulesView();
    this.staffView = await this.childViewFactory.getStaffView();
    this.locationsView = await this.childViewFactory.getLocationsView();

    await super.afterConstruction();
    this.pageControl.pageButtonLocation = PageButtonLocation.right;
  }

  public getTitle() {
    return '%%supplier%% Settings';
  }

  public async setActiveTabByHash() {
    await this.pageControl.applyWindowHash();
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [
        this.generalSettingsView!.createPageManager(),
        this.logoBrandingView!.createPageManager(),
        this.loginImageBrandingView!.createPageManager(),
        this.termsOfUseView!.createPageManager(),
        this.regionsView!.createPageManager(),
        this.orderRecipientsView!.createPageManager(),
        this.pricingRulesView!.createPageManager(),
        this.staffView!.createPageManager(),
        this.locationsView!.createPageManager()
      ];

      return pages;
    };
    const menu: MenuIconOption[] = [];
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: menu,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  async prepareForSave(): Promise<void> {
    //DOES NOTHING HANDLED BY CHILDREN
  }

  public internalDataChanged(): boolean {
    return false;
  }
}
