import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

export enum TriCheckState {
  None,
  Mixed,
  All
}

export class InputTriCheck extends LitElement {
  @property() checked = TriCheckState.None;
  @property() readonly = false;
  @property() disabled = false;
  @property() label = '';

  render() {
    const clickEvent = (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      if (this.readonly || this.disabled) return;
      this.checked = this.checked !== TriCheckState.All ? TriCheckState.All : TriCheckState.None;
      this.click();
    };
    const textColor = this.disabled || !this.checked ? 'text-secondary' : 'text-primary';
    const checkIcon = () => {
      switch (this.checked) {
        case TriCheckState.None:
          return html`<i class="fa-regular fa-square ${textColor}"></i>`;
        case TriCheckState.Mixed:
          return html`<i class="fa-regular fa-square-check ${textColor}"></i>`;
        case TriCheckState.All:
          return html`<i class="fa-solid fa-square-check ${textColor}"></i>`;
      }
    };
    return html`<span @click=${clickEvent}> ${checkIcon()} ${this.label} </span>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
