// eslint-disable-next-line import/named
import { html } from 'lit';

import {
  allowPageControlTabChange,
  canClose,
  ErrorAbandon,
  isAutoSaving,
  SavePromptOptions,
  saveWithIndicator
} from '../save-workflow';
import { AskConfirmation, confirmationButtons, ConfirmationButtonType } from './modal-confirmation';
import { checkValidations, throwValidations } from './data-entry-screen-helpers';
import { customElement } from 'lit/decorators.js';
import { DataEntryOwner } from './DataEntryOwner';
import { DevelopmentError, showDevelopmentError } from '../development-error';
import { EventTemplate, Snippet } from '../../interop/webmodule-interop';
import { getInternalId } from './databinding/databinding';
import { IDispose } from '../dispose';
import { ModalDialog } from './modal-base';
import { PageControl, PageManager } from './page-control';
import { ViewBase } from './view-base';
import { tlang } from '../language/lang';
import { MultiPromise } from '../general/multicast-promise';

/**
 * Base class for dataentry screens that will edit database objects that need to be saved, or autosaved
 */
export class ModalViewBase extends ModalDialog implements DataEntryOwner {
  get viewController(): DataEntryViewController | undefined {
    return (this as any).view;
  }

  async tryClose(): Promise<boolean> {
    return await this.closeIfAllowed();
  }

  async forceClose(): Promise<boolean> {
    await this.hideModal();
    return true;
  }

  protected renderCloseButtonTemplate(): boolean {
    return false;
  }
}

/**
 * base class that is designed to make it easy to support the Save/Autosave workflow of data in a consistent
 * way
 *
 * when creating any class of this type, use "await constructAsync(new DataEntryView())" to return an
 * instance.. this will ensure the afterconstructor is called asynchronously
 */

@customElement('wm-dataentryview')
export class DataEntryView extends ViewBase {
  owner: DataEntryOwner | null;
  abandoned = false;

  constructor(owner?: DataEntryOwner) {
    super();
    this.owner = owner ?? null;
  }

  protected _elementId = getInternalId();

  /**
   * used for adding unique id's to a template.
   */
  protected get elementId() {
    return `${this.name()}-${this._elementId}`;
  }

  /***
   * performs the autosave workflow/ or save workflow when autosave is off.
   * do not need to override this, but rather override its individal called parts.
   */
  async performAutoSave(doThrowValidations = false): Promise<boolean> {
    //if we dont need to save, then just exit.
    //but if we are not in autosave mode, we are going to force a save.
    let needsSave = false;
    try {
      needsSave = await this.dataNeedsSaving();
    } catch (e) {
      await showDevelopmentError(e as Error);
      return false;
    }
    let successfulSave = false;
    try {
      if (doThrowValidations) throwValidations(this.getValidationErrors());
      else if (!(await this.checkValidations())) return false;
      //this is to let us abandon new items that have had no editing
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (!needsSave && this.isNew()) throw new ErrorAbandon('Cancel', tlang`Cancel New Edit`);
      if (isAutoSaving() && !needsSave) return true;
      if (await this.canProceedWithSave()) {
        if (this.displaySaveModal()) {
          //this is an advance modal display that is encapsulating a much larger process.
          successfulSave = await this.internalSaveData();
        } else {
          //this is our basic save indicator
          successfulSave = await saveWithIndicator(async () => await this.internalSaveData());
        }
      } else return false;
    } finally {
      if (successfulSave) await this.afterSaveEvent();
    }
    return successfulSave;
  }

  async afterSaveEvent(): Promise<void> {}

  /**
   * internally this will perform any autosaves etc needed to allow this view to be exited
   * @returns true if this view can be either moved away from or destroyed.
   */
  async canClose(): Promise<boolean> {
    if (this.abandoned) return true;
    if (!(await this.readyToClose())) return false;
    return await canClose(this.getAutoSavePromptOptions());
  }

  /**
   * provide a string such as '%%quote%%' used as part of any save workflow
   * @returns a dictionary entry for the data type we are saving
   */
  getDataDictionaryName(): string {
    return '';
  }

  /**
   * should not typically need to override this. override the parts.
   * @returns the parameters used for performing autosaves and form leave/close workflow
   */
  getAutoSavePromptOptions(): SavePromptOptions {
    return {
      isReadonly: this.isDataReadonly(),
      autoSaveEvent: async () => await this.performAutoSave(true),
      dictionaryName: this.getDataDictionaryName(),
      needsSaveEvent: async () => await this.dataNeedsSaving(),
      abandonSaveEvent: async () => await this.revertChanges(),
      displaySaveModal: this.displaySaveModal(),
      informationDispatcher: this.informationDispatcher
    };
  }

  displaySaveModal(): boolean {
    return false;
  }

  /**
   * override this to perform any post construction work that must be done async.
   */
  public async afterConstruction(): Promise<void> {
    // do nothing
  }

  /**
   * override this and do any work necessary to put the dataobject that is going to be saved into the state
   * it needs to be. this would be such as applying datatracker changes etc to copy UI data into the object.
   * this will be done as part of the conditions for dataNeedsSaving()
   */
  async prepareForSave(): Promise<void> {
    await showDevelopmentError('prepareForSave must be overridden');
    //
  }

  /***
   * override and return true if the data, and the ui should be treated as readonly.
   * it is up to the template writer of the body to enforce this. this is also used
   * as part of the save workflow
   */
  public isDataReadonly(): boolean {
    return false;
  }

  /**
   * override this and do any actual saving of data. at this point all data should be assumed to be in the
   * correct state ready to post to the server.
   * @returns true if a save was successful
   */
  public internalDataChanged(): boolean {
    throw new DevelopmentError('internalDataChanged must be overridden');
  }

  /**
   * do not need to override this. override internalDataChanged. it is up to each subclass
   * to manage and track its own data and differences and reflect it with internalDataChanged
   * @returns true if the data has changed.
   */
  async dataNeedsSaving(): Promise<boolean> {
    if (this.abandoned) return false;
    if (this.isDataReadonly()) return this.internalDataChanged();
    //base value will always force a save
    //override to be accurate on this

    await this.prepareForSave();

    return this.internalDataChanged();
  }

  protected async tryClose(): Promise<boolean> {
    if (this.owner) return await this.owner.tryClose();
    return false;
  }

  protected async forceClose(): Promise<boolean> {
    if (this.owner) return await this.owner.forceClose();
    return false;
  }

  /**
   *
   * @returns the name part for creating unique UI Elements
   */
  protected name() {
    return 'modal-dialog';
  }

  protected getValidationErrors(): string[] {
    return [];
  }

  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    return await checkValidations(errors);
  }

  /**
   * override this and peform the actual data save and any updates that might need to be done.
   * @returns true if the save worked correctly and data is committed
   */
  protected async internalSaveData(): Promise<boolean> {
    await showDevelopmentError('internalSaveData must be overridden');
    return false;
  }

  /**
   * override this to return true if we can actually save the data. this
   * is called at a time when the dataobject should already reflect the UI
   * and can be tested for validations and other things that might block a save.
   * @returns true if we can call the internalSaveData
   */
  protected async canProceedWithSave(): Promise<boolean> {
    return true;
  }

  /**
   * override this to alert with modals, and return false if the view is "busy"
   * @returns false if the view is doing work, and needs to wait for it to finish before any closing or saving can be done
   */
  protected async readyToClose(): Promise<boolean> {
    return true;
  }

  protected async revertChanges(): Promise<boolean> {
    return true;
  }

  protected isNew(): boolean {
    return false;
  }

  /**
   *
   * @returns the template used to fill the ui element
   */
  protected bodyTemplate(): EventTemplate {
    return html``;
  }

  /**
   * redraw the template into our UI element. call onrender if assigned.
   * owning parents should call render as needed as part of any rendering workflow,
   * and embed the ui element into themselves.
   */

  protected template(): EventTemplate {
    return this.bodyTemplate();
  }
}

export interface DataEntryViewController extends IDispose {
  ui: HTMLElement;
  owner: DataEntryOwner | null;
  //onRender: EventNotify | null;
  requestUpdate: () => void;
  abandoned: boolean;
  getTitle: () => Snippet;

  abandonAndClose: (force?: boolean) => Promise<boolean>;
  setActiveTabByHash: () => Promise<void>;
}

/**
 * use this class when building a view that is dominated by a single page control
 */
@customElement('wm-dataentrypagecontrolview')
export class DataEntryPageControlView extends DataEntryView implements DataEntryViewController {
  private allowPageSwitchPromise = new MultiPromise<boolean>(
    async () => await allowPageControlTabChange(this.getAutoSavePromptOptions())
  );

  constructor(owner?: DataEntryOwner) {
    super(owner);
  }

  get canForceReload(): boolean {
    return false;
  }

  protected _pageControl: PageControl | null = null;

  protected get pageControl(): PageControl {
    if (!this._pageControl) throw new DevelopmentError('PageControl was not created');
    return this._pageControl;
  }

  getTitle(): Snippet {
    return '';
  }

  async dispose(): Promise<void> {
    await super.dispose();
    if (this._pageControl) {
      await this._pageControl.dispose();
      this._pageControl = null;
    }
  }

  public async afterConstruction(): Promise<void> {
    if (!this._pageControl) this._pageControl = this.createPageControl();
  }

  public async rebuildPageControl() {
    this._pageControl = this.createPageControl();
    this.requestUpdate();
  }

  public async abandonAndClose(force?: boolean): Promise<boolean> {
    if (
      force ||
      (await AskConfirmation(
        tlang`Are you sure you want to discard your changes?` +
          '<br/>' +
          tlang`This will revert all changes you have made on this tab`,
        confirmationButtons[ConfirmationButtonType.yesNo],
        undefined,
        'Discard Changes?'
      ))
    ) {
      this.abandoned = true;
      if (await this.forceClose()) {
        await this.doAbandoned();
        return true;
      } else this.abandoned = false;
    }
    return false;
  }

  async canClose(): Promise<boolean> {
    if (this.abandoned) return true;
    if (!(await this.readyToClose())) return false;
    return await this.pageControl.canCloseAllTabs();
  }

  public refreshParent() {
    this.pageControl.requestUpdate();
    this.pageControl.updateComplete.then(() => this.dispatchUiChanged());
  }

  public async allowPageSwitch(): Promise<boolean> {
    return await this.allowPageSwitchPromise.run();
  }

  async setActiveTabByHash(): Promise<void> {
    await this.pageControl.applyWindowHash();
  }

  /**
   * inherited
   * @returns
   */

  protected bodyTemplate(): EventTemplate {
    return html` <div id=${this.elementId} class="page-content">${this._pageControl?.ui ?? ''}</div>`;
  }

  protected async doAbandoned() {
    //
  }

  protected createPageControl(): PageControl {
    throw new Error('Method not implemented.');
  }
}

/**
 * use this class for creating pagecontrol pages that must manage their own save workflow outside of the
 * parent view.
 *
 */
@customElement('wm-pagecontroltabwithindependantsaving')
export class PageControlTabWithIndependantSaving extends DataEntryView {
  protected pageFragment = '';
  private _updatePageControl: (() => void) | null = null;
  private _pageManager: PageManager | null = null;

  public async allowPageSwitch(): Promise<boolean> {
    return await this.canClose();
  }

  public allowDeletePage(): boolean {
    return true;
  }

  public refreshParent() {
    this._updatePageControl?.();
    this.dispatchUiChanged();
  }

  public isTab(): boolean {
    return true;
  }

  protected getCaption(): Snippet {
    return html``;
  }

  public createPageManager(title?: Snippet): PageManager {
    if (!this.isTab()) throw new DevelopmentError('Does not have a pagemanager');
    if (this._pageManager == null) {
      this._pageManager = {
        caption: () => title ?? this.getCaption(),
        pageFragment: this.pageFragment,
        canClose: async (_index: number, _page: PageManager): Promise<boolean> => {
          //check if we need to save anything here.. how? comparison? keep original copy?
          return await this.allowPageSwitch();
        },
        canLeave: async () => {
          return await this.allowPageSwitch();
        },
        onEnter: async (_pageIndex, _page) => {
          await this.onEnter();
        },
        hasDelete: () => this.allowDeletePage(),
        content: async () => {
          return this.ui;
        },
        dispose: async () => await this.dispose(),
        buttonMenu: () => this.buttonMenu(),
        callbacks: {
          setNeedsRefreshEvent: (event: () => void) => {
            this._updatePageControl = event;
          },
          setDataEntryOwner: (owner: DataEntryOwner) => {
            this.owner = owner;
          }
        },
        data: this
      };
    }
    return this._pageManager;
  }

  buttonMenu(): Snippet {
    return html``;
  }

  async onEnter(): Promise<void> {
    this.requestUpdate();
  }

  public async abandonAndClose(force?: boolean): Promise<boolean> {
    if (
      force ||
      (await AskConfirmation(
        tlang`Are you sure you want to discard your changes?` +
          '<br/>' +
          tlang`This will revert all changes you have made on this tab`,
        confirmationButtons[ConfirmationButtonType.yesNo],
        undefined,
        'Discard Changes?'
      ))
    ) {
      this.abandoned = true;
      if (await this.forceClose()) {
        await this.doAbandoned();
        return true;
      } else this.abandoned = false;
    }
    return false;
  }

  protected async doAbandoned() {
    //
  }
}

@customElement('wm-pagecontrolchildtab')
export class PageControlChildTab extends ViewBase {
  owner: DataEntryPageControlView;
  private _updatePageControl: (() => void) | null = null;

  constructor(owner: DataEntryPageControlView) {
    super();
    this.owner = owner;
  }

  public allowDeletePage(): boolean {
    return true;
  }

  public isTab(): boolean {
    return true;
  }

  public createPageManager(title?: Snippet): PageManager {
    if (!this.isTab()) throw new DevelopmentError('Does not have a pagemanager');
    return {
      caption: () => title ?? this.getCaption(),
      canClose: async (_index: number, _page: PageManager): Promise<boolean> => {
        //check if we need to save anything here.. how? comparison? keep original copy?
        return await this.owner.allowPageSwitch();
      },
      canLeave: async () => {
        return await this.owner.allowPageSwitch();
      },
      onEnter: async (_pageIndex, _page) => {
        await this.onEnter();
      },
      hasDelete: () => this.allowDeletePage(),
      content: () => {
        return this.ui;
      },
      buttonMenu: () => {
        return html``;
      },
      callbacks: {
        setNeedsRefreshEvent: (event: () => void) => {
          this._updatePageControl = event;
        }
      },
      data: this
    };
  }

  async onEnter(): Promise<void> {
    this.requestUpdate();
  }

  protected getCaption(): Snippet {
    return html``;
  }

  protected refreshParent() {
    this._updatePageControl?.();
  }

  /**
   * redraw the template into our UI element. call onrender if assigned.
   * owning parents should call render as needed as part of any rendering workflow,
   * and embed the ui element into themselves.
   */

  protected template(): EventTemplate {
    return this.bodyTemplate();
  }

  /**
   *
   * @returns the template used to fill the ui element
   */
  protected bodyTemplate(): EventTemplate {
    return html``;
  }
}
