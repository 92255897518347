import { html, TemplateResult } from 'lit';
import { Snippet } from '../../interop/types/misc';
import { until } from 'lit/directives/until.js';

export function awaitableTemplate(
  template:
    | TemplateResult
    | string
    | Promise<TemplateResult>
    | Snippet
    | Promise<Snippet>
    | Promise<string>
    | HTMLElement
    | Promise<HTMLElement>
    | undefined
    | null
    | (() => string | TemplateResult)
    | (() => TemplateResult[])
    | (() => Promise<string | TemplateResult>)
    | (() => Promise<TemplateResult[]>),
  ...fallbacks
) {
  if (!template) return html``;
  const templateValue = typeof template === 'function' ? template() : template;

  return (templateValue as any).then ? until(templateValue, ...fallbacks) : templateValue;
}
