import { customElement, property, query, state } from 'lit/decorators.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { html, LitElement, TemplateResult } from 'lit';
import { ICommandAction } from '../../../../webmodule-common/other/ui/command-action';
import { PricingRule, PricingRuleLibrary, PricingRuleType } from '../../../api/supplier-api-interface-supplier';
import { PricingRuleEvent } from '../../../settings/view/settings-pricing-rules-view';
import { tlang } from '../../../../webmodule-common/other/language/lang';

import {
  WebmoduleChangeEvent,
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleToggle
} from '../../../../webmodule-common/components/src/webmodule-components';

export type getPricingRulesData = () => PricingRuleLibrary;

export type SetDefaultPricingRuleIdEvent = (pricingRuleId?: string) => Promise<void>;

export interface IPricingRulesTable {
  template: () => TemplateResult;
  actions: () => ICommandAction[];
}

@customElement('webmodule-pricingrulestableelement')
export default class PricingRulesTableElement extends LitElement {
  @query('#pricingRulesTable')
  table?: WebModuleLitTable;

  @property() filterValue = '';
  @property() filterRuleName = '';

  dataSource: getPricingRulesData | undefined;
  updateEvent: PricingRuleEvent | undefined;
  setDefaultRuleEvent: SetDefaultPricingRuleIdEvent | undefined;
  @state()
  public defaultRuleId: string = emptyGuid;
  @state()
  private _data?: PricingRule[] | undefined;
  @state()
  private _dataTypes?: PricingRuleType[] | undefined;

  protected get pricingRules(): PricingRule[] | undefined {
    return this._data;
  }

  protected set pricingRules(value: PricingRule[] | undefined) {
    this._data = value;
    this.setTableData();
  }

  protected get pricingRuleTypes(): PricingRuleType[] | undefined {
    return this._dataTypes;
  }

  protected set pricingRuleTypes(value: PricingRuleType[] | undefined) {
    this._dataTypes = value;
    this.setTableData();
  }

  async reloadData() {
    if (this.dataSource) {
      try {
        const container = this.dataSource();
        this.pricingRules = container.pricingRules;
        this.pricingRuleTypes = container.pricingRuleTypes;
        this.defaultRuleId = container.defaultPricingRuleId;

        return;
      } catch (e) {
        console.log(`Error: ${e}`);
      }
    }
    this.pricingRules = [];
    this.pricingRuleTypes = [];
    this.defaultRuleId = emptyGuid;
  }

  getActions(): ICommandAction[] {
    return [];
  }

  //This class is designed to not have a shadow root, and allow styling to come from the application consuming it
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected isMinMaxPricingRule(pricingRuleTypeId?: string): boolean {
    if (pricingRuleTypeId) {
      const ruleType = this.pricingRuleTypes?.find(prt => prt.id === pricingRuleTypeId);
      return ruleType?.hasMinMax ?? false;
    }
    return false;
  }

  protected setTableData() {
    if (!this.table) return;
    const table = this.table;
    const index = this.table.pageIndex;
    const length = this.table.pageLength;
    if (this.pricingRules) {
      table.data = this.pricingRules
        .filter(x => this.matchFilter(x) && this.isMinMaxPricingRule(x.ruleTypeId))
        .slice(index * length, index * length + length);
      table.rowCount = this.pricingRules.length;
    } else table.data = [];
  }

  protected getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`Active`,
        classes: `colpxmax-60 no-pseudo`,
        fieldName: 'id',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PricingRule;
          const setDefaultEvent = async (e: WebmoduleChangeEvent) => {
            const target = e.target as WebmoduleToggle;

            this.defaultRuleId = target.checked ? rowItem.id : emptyGuid;
            await this.setDefaultRuleEvent?.(this.defaultRuleId);
          };
          return html` <webmodule-toggle
            size="small"
            ?checked=${rowItem.id === this.defaultRuleId}
            @webmodule-change="${setDefaultEvent}"
          ></webmodule-toggle>`;
        }
      },
      {
        title: tlang`Rule Name`,
        classes: 'colpx-100',
        fieldName: 'name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PricingRule;
          return html`${rowItem.name}`;
        }
      },
      {
        title: tlang`Margin`,
        classes: 'colpx-200 no-pseudo',
        fieldName: 'minValue',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PricingRule;

          const updateMinEvent = async e => {
            rowItem.minValue = parseFloat(e.target.value);
            this.updateEvent?.(rowItem);
          };
          const updateMaxEvent = async e => {
            rowItem.maxValue = parseFloat(e.target.value);
            this.updateEvent?.(rowItem);
          };

          if (rowItem.ruleTypeId) {
            const pricingRuleType = this.getPricingRuleType(rowItem.ruleTypeId);
            if (pricingRuleType) {
              if (pricingRuleType.hasMinMax) {
                return html` <webmodule-input
                    size="small"
                    class="colpx-100"
                    @webmodule-change="${updateMinEvent}"
                    type="number"
                    value=${rowItem.minValue}
                    min="0"
                    max="100"
                  >
                    <span slot="suffix">%</span>
                  </webmodule-input>
                  -
                  <webmodule-input
                    size="small"
                    class="colpx-100"
                    @webmodule-change="${updateMaxEvent}"
                    type="number"
                    value=${rowItem.maxValue}
                    min="0"
                    max="100"
                  >
                    <span slot="suffix">%</span>
                  </webmodule-input>`;
              }
            }
          }
          return html` <webmodule-input
              class="colpx-100"
              @change=${updateMinEvent}
              type="number"
              value=${rowItem.minValue}
              min="0"
              max="100"
            ></webmodule-input
            >%`;
        }
      },
      {
        title: tlang`Markup`,
        classes: 'colpx-200 no-pseudo',
        fieldName: 'minValue',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PricingRule;
          const updateMinMarkupEvent = async e => {
            rowItem.minMarkup = parseFloat(e.target.value);
            this.updateEvent?.(rowItem);
          };
          const updateMaxMarkupEvent = async e => {
            rowItem.maxMarkup = parseFloat(e.target.value);
            this.updateEvent?.(rowItem);
          };

          if (rowItem.ruleTypeId) {
            const pricingRuleType = this.getPricingRuleType(rowItem.ruleTypeId);
            if (pricingRuleType) {
              if (pricingRuleType.hasMinMax) {
                return html` <webmodule-input
                    size="small"
                    class="colpx-100"
                    @webmodule-change="${updateMinMarkupEvent}"
                    type="number"
                    value=${rowItem.minMarkup}
                    min="0"
                    max="100"
                  >
                    <span slot="suffix">%</span>
                  </webmodule-input>
                  -
                  <webmodule-input
                    size="small"
                    class="colpx-100"
                    @webmodule-change="${updateMaxMarkupEvent}"
                    type="number"
                    value=${rowItem.maxMarkup}
                    min="0"
                    max="100"
                  >
                    <span slot="suffix">%</span>
                  </webmodule-input>`;
              }
            }
          }
          return html` <webmodule-input
              class="colpx-100"
              @change=${updateMinMarkupEvent}
              type="number"
              value=${rowItem.minMarkup}
              min="0"
              max="100"
            >
              <span slot="suffix">%</span></webmodule-input
            >%`;
        }
      }
    ];
  }

  protected render() {
    const keyEvent = (item: PricingRule) => {
      return item.id;
    };
    const columns = this.getColumns();

    return html` <div class="container">
      <webmodule-lit-table
        id="staffTable"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .columns=${columns}
        pageLength="50"
        @fetch=${(e: CustomEvent) => this.internalLoadData(e)}
        .clickrows=${false}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
      >
      </webmodule-lit-table>
    </div>`;
  }

  private matchFilter(pr: PricingRule): boolean {
    function matches(a: string, b: string) {
      return a.toLowerCase().includes(b);
    }

    let match = true;
    if (this.filterValue !== '') {
      const f = this.filterValue.toLowerCase();
      match = matches(pr.name, f);
      if (!match) return false;
    }
    if (this.filterRuleName !== '') {
      const activeMatch = this.filterRuleName.toLocaleLowerCase();
      if (activeMatch === pr.name.toLocaleLowerCase()) return false;
    }
    return match;
  }

  /** This is called by the table when it wants new page data */
  private async internalLoadData(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const table = e.detail.table as WebModuleLitTable;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 1000;
    if (!this.pricingRules || !this.pricingRuleTypes) {
      await this.reloadData();
    }
    if (this.pricingRules) {
      table.data = this.pricingRules
        .filter(x => this.matchFilter(x) && this.isMinMaxPricingRule(x.ruleTypeId))
        .slice(index * length, index * length + length);
      table.rowCount = this.pricingRules.length;
    } else table.data = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _loadExtension(_e: CustomEvent) {}

  private getPricingRuleType(id: string): PricingRuleType | undefined {
    if (this.pricingRuleTypes) {
      return this.pricingRuleTypes.find(dt => dt.id === id);
    }
    return undefined;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-pricingrulestableelement': PricingRulesTableElement;
  }
}
