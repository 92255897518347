import { _lockUIandExecute, setUILockAndExecuteEvent } from './ui-lock';
import { setLitModalScreenFactory } from './ui/modal/modal-factory-lit';
import { litModalScreenFactoryImpl } from './ui/modal-factory-lit';
import { setModalScreenFactory } from './ui/modal/modal-factory';
import { modalScreenFactoryImpl } from './ui/modal-factory';

export function bindApplicationFeatures() {
  setUILockAndExecuteEvent(_lockUIandExecute);
  setLitModalScreenFactory(litModalScreenFactoryImpl);
  setModalScreenFactory(modalScreenFactoryImpl);
}

globalThis.bindApplicationFeatures = bindApplicationFeatures;
