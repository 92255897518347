import { allUserCache } from '../data/all-user-cache';
import {
  autoElement,
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';
import { cache } from '../../supplier/cache-impl/cache-registry';
import { DateTime } from 'luxon';
import { getApiFactory } from '../../api/api-injector';
import { getQuoteNumberDisplay } from '../data/quote-helper';
import { getSupportTypeDisplayValue, getSupportTypeStatus, getSupportTypeStatusClass } from '../data/support-helper';
import { html, LitElement, TemplateResult } from 'lit';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import { NullPromise } from '../../../webmodule-common/interop/webmodule-interop';
import {  property, query, state } from 'lit/decorators.js';
import {
  ResultGetSupportInbox,
  SupportInboxItemStatus,
  SupportInboxItemType,
  SupportInboxTicketOwnerFilter,
  ViewSupportInboxItemEx,
  ViewSupportInboxItemExResultPaginated
} from '../../api/supplier-api-interface-franchiseenetwork';
import {
  serverDateTimeToLocal,
  serverDateTimeToLocalDateTime
} from '../../../webmodule-common/other/datetime-converter';
import { sleep } from '../../../webmodule-common/other/general/time';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../supplier/common/current-user-data-store';

//const ADD_MOCK_DATA = true;

@autoElement()
export class WebModuleQuoteSupportInboxView extends LitElement {
  @property()
  private _ticketOwnerFilter?: SupportInboxTicketOwnerFilter | undefined = SupportInboxTicketOwnerFilter.All;
  public get ticketOwnerFilter(): SupportInboxTicketOwnerFilter | undefined {
    return this._ticketOwnerFilter;
  }
  public set ticketOwnerFilter(value: SupportInboxTicketOwnerFilter | undefined) {
    if (this._ticketOwnerFilter !== value) {
      this._ticketOwnerFilter = value;
      this.table?.fetchEvent();
    }
  }
  @property()
  private _typeFilter?: SupportInboxItemType | undefined;
  public get typeFilter(): SupportInboxItemType | undefined {
    return this._typeFilter;
  }
  public set typeFilter(value: SupportInboxItemType | undefined) {
    this._typeFilter = value;
    if (this.table) this.table.pageIndex = 0;
    this.table?.fetchEvent();
  }
  @property()
  private _statusFilter?: SupportInboxItemStatus | undefined;
  public get statusFilter(): SupportInboxItemStatus | undefined {
    return this._statusFilter;
  }
  public set statusFilter(value: SupportInboxItemStatus | undefined) {
    this._statusFilter = value;
    if (this.table) this.table.pageIndex = 0;
    this.table?.fetchEvent();
  }
  @property()
  private _filter?: string | null | undefined;
  public get filter(): string | null | undefined {
    return this._filter;
  }
  public set filter(value: string | null | undefined) {
    this._filter = value;
    if (this.table) this.table.pageIndex = 0;

    this.table?.fetchEvent();
  }
  @property()
  sortField?: string | null;
  @property()
  sortAsc?: boolean;
  @property()
  showResolvedColumns?: boolean;
  @property()
  showLastModified?: boolean;
  @property()
  showAssignedTo?: boolean;

  @query('#support-inbox-table')
  table?: WebModuleLitTable;

  @state()
  private _data: ViewSupportInboxItemExResultPaginated | undefined;
  private get data(): ViewSupportInboxItemExResultPaginated | undefined {
    return this._data;
  }
  private set data(value: ViewSupportInboxItemExResultPaginated | undefined) {
    this._data = value;
  }

  private loadDataPromise?: NullPromise<ResultGetSupportInbox> = undefined;

  public async refreshData() {
    if (this.table) {
      this.loadDataPromise = undefined;
      this.table?.fetchEvent();
      while (!this.loadDataPromise) await sleep(20);
      await this.loadDataPromise;
    }
  }
  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const table = e.detail.table as WebModuleLitTable;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 100;
    const input = {
      filter: this.filter ?? '',
      requestPage: { pageIndex: index, pageSize: length },
      sortAsc: false, // not used yet
      sortField: '', //not used yet
      statusFilter: this.statusFilter ?? SupportInboxItemStatus.All,
      ticketOwnerFilter: this.ticketOwnerFilter ?? SupportInboxTicketOwnerFilter.Mine,
      typeFilter: this.typeFilter ?? SupportInboxItemType.QuoteReview
    };
    this.loadDataPromise = getApiFactory().franchisee().getQuoteSupportInbox(input);
    this.data = (await this.loadDataPromise)?.items;
    if (!this.data) {
      table.data = [];
    }
    if (this.data) {
      //preload userids
      const userIds = [
        ...this.data.results.map(x => x.item.lastModifiedUserId),
        ...this.data.results.map(x => x.item.franchiseeSystemUserId),
        ...this.data.results.map(x => x.quoteAssignedToUserId),
        ...this.data.results.map(x => x.quoteLastModifiedUserId)
      ];
      await cache().userProfile.preFetch(userIds);

      table.data = this.data.results;
      table.rowCount = this.data.count;
    } else table.data = [];
  }
  render() {
    const keyEvent = (item: ViewSupportInboxItemEx) => {
      return item.item.id;
    };
    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: tlang`%%franchisee%%`,
      classes: 'colpx-160',
      fieldName: 'name',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        return this.nameOfTenant(rowItem.item.franchiseeTenantId);
      }
    });
    columns.push({
      title: tlang`%%branch%%`,
      classes: 'colpx-120',
      fieldName: 'branchName',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        return this.nameOfBranch(rowItem.item.branchId);
      }
    });
    columns.push({
      title: tlang`%%franchisee%% %%quote%% Title`,
      classes: 'colpx-150',
      fieldName: 'quoteTitle',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        const label = rowItem.title ?? '';
        if (rowItem.item.type == SupportInboxItemType.QuoteReview) {
          const clickItem = async (e: Event) => {
            e.stopPropagation();
            e.preventDefault();
            if (rowItem.quoteNumber === 0) {
              await information(
                tlang`Please wait and refresh your system. Quote information is not available yet.`,
                tlang`Data Unavailable`
              );
            }
            this.editQuoteOnFranchiseeSite(rowItem);
          };
          return html`<a href="#" @click=${clickItem}>${label}</a>`;
        } else {
          return html`${label}`;
        }
      }
    });
    columns.push({
      title: tlang`Ticket Title`,
      classes: 'colpx-150',
      fieldName: 'ticketTitle',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        const label = !isEmptyOrSpace(rowItem.item.subject) ? rowItem.item.subject : this.nameOfQuote(rowItem);
        if (
          rowItem.item.type == SupportInboxItemType.EngineeredToOrder ||
          rowItem.item.type == SupportInboxItemType.QuoteAssistanceRequest
        ) {
          const clickItem = async (e: Event) => {
            e.stopPropagation();
            e.preventDefault();
            if (rowItem.quoteNumber === 0) {
              await information(
                tlang`Please wait and refresh your system. Quote information is not available yet.`,
                tlang`Data Unavailable`
              );
            }
            this.editQuoteOnFranchiseeSite(rowItem);
          };
          return html`<a href="#" @click=${clickItem}>${label}</a>`;
        } else {
          return html`${label}`;
        }
      }
    });
    columns.push({
      title: tlang`%%franchisee%% Author`,
      classes: 'colpx-100',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        return cache().userProfile.getLocalData(rowItem.quoteAssignedToUserId)?.friendlyName ?? tlang`Unknown`;
      }
    });
    columns.push({
      title: tlang`Request Type`,
      classes: 'colpx-100',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        return getSupportTypeDisplayValue(rowItem.item.type);
      }
    });
    columns.push({
      title: tlang`Status`,
      classes: 'colpx-120 col-status',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        return html`<span class="badge ${getSupportTypeStatusClass(rowItem.item.status)}"
          >${getSupportTypeStatus(rowItem.item.status)}</span
        >`;
      }
    });
    if (this.showAssignedTo) {
      columns.push({
        title: tlang`Assigned To`,
        classes: 'colpx-160 col-assigned-to',
        fieldName: 'xx',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewSupportInboxItemEx;
          const eventChangeUser = (e: CustomEvent<{ value: string }>) =>
            this.assignUserToSupportItem(rowItem, e.detail.value);
          return html`<webmodule-assignto
            .users=${allUserCache()}
            .value=${rowItem.item.assignedUserId}
            ?readonly=${!this.canChangeAssigment(rowItem)}
            ?disabled=${!this.canChangeAssigment(rowItem)}
            @wm-event-changed=${eventChangeUser}
          ></webmodule-assignto>`;
        }
      });
    }
    columns.push({
      title: tlang`Date Submitted`,
      classes: 'colpx-100',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as ViewSupportInboxItemEx;
        return serverDateTimeToLocal(rowItem.item.dateCreated).toLocaleDateString();
      }
    });
    if (this.showLastModified) {
      columns.push({
        title: tlang`Last Modified`,
        classes: 'colpx-160 col-lastmodifed',
        fieldName: 'xx',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewSupportInboxItemEx;
          const label = cache().userProfile.getLocalData(rowItem.item.lastModifiedUserId)?.friendlyName ?? '';
          const dateValue = serverDateTimeToLocalDateTime(rowItem.item.lastModified);

          const lDateValue =
            Math.abs(dateValue.diffNow().as('years')) > 1000 ? '' : dateValue.toLocaleString(DateTime.DATETIME_SHORT);
          return html`<span class="lastmodified-date me-2">${lDateValue}</span>
            <span class="lastmodified-user">${label}</span>`;
        }
      });
    }
    if (this.showResolvedColumns) {
      columns.push({
        title: tlang`Date Resolved`,
        classes: 'colpx-100',
        fieldName: 'xx',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewSupportInboxItemEx;
          return rowItem.item.status === SupportInboxItemStatus.Resolved
            ? serverDateTimeToLocal(rowItem.item.lastModified).toLocaleDateString()
            : '-';
        }
      });
      columns.push({
        title: tlang`Resolution`,
        classes: 'colpx-80',
        fieldName: 'xx',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewSupportInboxItemEx;
          return rowItem.item.status === SupportInboxItemStatus.Resolved
            ? `${serverDateTimeToLocalDateTime(rowItem.item.lastModified)
                .diff(serverDateTimeToLocalDateTime(rowItem.item.dateCreated), 'hours')
                .hours.toFixed(1)}hrs`
            : '-';
        }
      });
      columns.push({
        title: tlang`Reviewer`,
        classes: 'colpx-100',
        fieldName: 'xx',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as ViewSupportInboxItemEx;
          const reviewerUserId = rowItem.item.assignedUserId;
          const allUsers = allUserCache();
          return allUsers.find(u => u.id == reviewerUserId)?.friendlyName ?? '';
        }
      });
    }

    return html` <div class="support-inbox">
      <webmodule-lit-table
        id="support-inbox-table"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .columns=${columns}
        pageLength="100"
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        .clickrows=${false}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
      >
      </webmodule-lit-table>
    </div>`;
  }
  canChangeAssigment(item: ViewSupportInboxItemEx): boolean {
    if (item.item.status === SupportInboxItemStatus.Cancelled || item.item.status === SupportInboxItemStatus.Resolved)
      return false;
    return true;
  }
  async assignUserToSupportItem(item: ViewSupportInboxItemEx, supplierSystemUserId: string | null) {
    if (!this.canChangeAssigment(item)) {
      await information('%%user%% assignment', tlang`This cannot be assigned at this time`);
    }
    await lockUIandExecute(async () => {
      const result = await getApiFactory()
        .franchisee()
        .updateSupportInboxItem({
          id: item.item.id,
          assignedUserId: supplierSystemUserId,
          status: !supplierSystemUserId
            ? SupportInboxItemStatus.New //revert to new if we unassign
            : item.item.status === SupportInboxItemStatus.New || item.item.status === SupportInboxItemStatus.Overdue
              ? SupportInboxItemStatus.Active //make active if new
              : item.item.status // leave where it is
        });
      if (result?.supportInboxItem) {
        item.item = result.supportInboxItem;
      }
    });
    // eslint-disable-next-line no-self-assign
    if (this.table) {
      const d = this.table.data;
      this.table.data = [];
      this.table.data = d;
    }
  }
  editQuoteOnFranchiseeSite(item: ViewSupportInboxItemEx) {
    this.dispatchCustom('quotereview', {
      item: item
    });
  }
  nameOfQuote(item: ViewSupportInboxItemEx) {
    if (item.quoteNumber === 0) return tlang`<unavailable>`;
    return getQuoteNumberDisplay(item.quoteNumber, item.quoteSuffix);
  }
  nameOfBranch(branchId: string): string | TemplateResult {
    return (
      userDataStore.allFranchiseeDetails.branches?.find(x => x.branchId === branchId)?.abbreviation ?? tlang`Unknown`
    );
  }
  nameOfTenant(franchiseeTenantId: number): string {
    return (
      userDataStore.allFranchiseeDetails.tenantList.tenants?.find(x => x.id === franchiseeTenantId)?.companyName ??
      tlang`Unknown`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _loadExtension(_e: CustomEvent) {
    //const item: Tenant = e.detail.item;
    //e.detail.table?.addExtension(item, this.getExtensionForTenant());
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-supportinbox-${name}`, options));
  }
  connectedCallback(): void {
    super.connectedCallback();
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
