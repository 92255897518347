import { ServiceResponseInvalid } from '../../webmodule-common/interop/webmodule-interop';
import { setCurrentUser } from '../../webmodule-common/other/api/current-user';
import {
  strDuplicateLogin,
  strInvalidClientToken,
  strNetworkUnavailable
} from '../../webmodule-common/other/api/network-consts';
import { tlang } from '../../webmodule-common/other/language/lang';
import {
  information,
  pageReload,
  showError,
  showValidations,
  strInvalidBuildNumber
} from '../../webmodule-common/other/webmodule-common';
import { ValidationError } from './validation-error';
import { handleOutOfDateIntercept } from '../../webmodule-common/other/common/web-cache';

let handlingDuplicateLogin = false;

//create a generic global handler for communication errors that go unresolved.
//this will typically only be things we dont want to happen not considered normal workflow
export const responseHandler = async (response: ServiceResponseInvalid | ValidationError[]) => {
  if (!Array.isArray(response)) {
    if (response.responseError?.message === strInvalidBuildNumber) {
      try {
        await handleOutOfDateIntercept(true);
      } finally {
        pageReload();
      }
      return;
    } else if (response.responseError?.message === strInvalidClientToken) {
      if (handlingDuplicateLogin) return;
      handlingDuplicateLogin = true;
      try {
        await information(tlang`Your client session is no longer valid. Refreshing Page to attempt recovery`);
        window.location.reload();
      } finally {
        handlingDuplicateLogin = false;
      }
      return;
    } else if (response.responseError?.message === strDuplicateLogin) {
      if (handlingDuplicateLogin) return;
      handlingDuplicateLogin = true;
      try {
        await information(
          tlang`You are being disconnected as your account is logged into another machine (or Browser)`
        );
        await setCurrentUser(null);
      } finally {
        handlingDuplicateLogin = false;
      }
      return;
    } else if (response.responseError?.message === strNetworkUnavailable) {
      await information(
        tlang`${'ref:system-offline-msg'}Cannot communicate with the server. There may be a network outage. please check your internet and try again`,
        tlang`${'ref:system-offline-title'}System OFFLINE`
      );
      return;
    }
    const caption = response.responseTypeCaption;
    const msg = response.responseError?.message ?? '';
    const stack = response.responseError?.stackTrace ?? '';
    console.log(`ServiceResponse Error [${caption}] "${msg}" \n StackTrace:${stack}`);
    await showError(response, () => tlang`Server Error Occurred`);
  } else {
    console.log('Service Response Validation Errors Returned');
    const msg: string[] = [];
    response.forEach(err => {
      const errMsg = `${err.property} -> ${err.message}`;
      msg.push(errMsg);
      console.log(errMsg);
    });
    await showValidations(msg, () => tlang`Validation Issues from Server`);
  }
};
