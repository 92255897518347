import { globalState } from '../global-state.js';
import type { TemplateResult } from 'lit';
import { html } from 'lit';
import {
  EventVoid,
  EventVoidAsync,
  LitModalScreen,
  LitModalScreenBuilder,
  LitModalScreenEvents,
  ModalPromise
} from '../../../interop/webmodule-interop.js';
import { isNothing } from '../../general/nothing.js';
import { createConfirmCancelButtons } from '../modal-footer-buttons.js';

export function setLitModalScreenFactory(event: LitModalScreenBuilder) {
  globalState().modalScreenBuilderLitEvent = event;
}

export function litModalScreenFactory(event: LitModalScreenEvents): LitModalScreen {
  const _event = globalState().modalScreenBuilderLitEvent as LitModalScreenBuilder;
  if (_event) return _event(event);
  throw new Error('setModalScreenFactory not called');
}

export class LitBaseModal implements LitModalScreenEvents {
  protected modal: LitModalScreen = litModalScreenFactory(this);

  get isFooterVisible(): boolean {
    return !isNothing(this.footerTemplate());
  }

  get ui(): HTMLElement {
    return this.modal.ui;
  }

  canClose(): Promise<boolean> {
    return Promise.resolve(true);
  }

  footerTemplate(): TemplateResult | null | undefined {
    return undefined;
  }

  async showModal() {
    await this.modal.showModal();
  }

  async hideModal() {
    await this.modal.hideModal();
  }

  show(): Promise<ModalPromise> {
    return this.modal.show();
  }

  title(): string | TemplateResult {
    //backwards compatible with ModalDialog
    return (this as any).getTitle?.() ?? 'Dialog';
  }

  requestUpdate() {
    this.modal.requestUpdate();
  }

  bodyTemplate(): TemplateResult {
    return html``;
  }

  dispatchCustom<T>(name: string, values: T) {
    return this.modal.dispatchCustom(name, values);
  }

  protected createConfirmCancelButtons(
    confirmText: string | TemplateResult,
    confirmEvent: EventVoid | EventVoidAsync,
    cancelText: string | TemplateResult,
    cancelEvent?: EventVoid | EventVoidAsync
  ): TemplateResult {
    const defaultCancelEvent = async () => {
      await this.hideModal();
    };

    return createConfirmCancelButtons(confirmText, confirmEvent, cancelText, cancelEvent ?? defaultCancelEvent);
  }
}
