import { css } from 'lit';

export default css`
  :host {
    display: inline-block;
  }

  :host([size='small']) {
    --height: var(--webmodule-toggle-size-small);
    --thumb-size: calc(var(--webmodule-toggle-size-small) + 4px);
    --width: calc(var(--height) * 2);

    font-size: var(--webmodule-control-font-size-small);
    //padding: var(--webmodule-spacing-small) 0;
  }

  :host([size='medium']) {
    --height: var(--webmodule-toggle-size-medium);
    --thumb-size: calc(var(--webmodule-toggle-size-medium) + 4px);
    --width: calc(var(--height) * 2);

    font-size: var(--webmodule-control-font-size-medium);
    //padding: var(--webmodule-spacing-medium) 0;
  }

  :host([size='large']) {
    --height: var(--webmodule-toggle-size-large);
    --thumb-size: calc(var(--webmodule-toggle-size-large) + 4px);
    --width: calc(var(--height) * 2);

    font-size: var(--webmodule-control-font-size-large);
    //padding: var(--webmodule-spacing-large) 0;
  }

  .toggle {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: var(--webmodule-control-font-family);
    font-size: inherit;
    font-weight: var(--webmodule-font-weight-normal);
    color: var(--webmodule-control-label-color);
    vertical-align: middle;
    cursor: pointer;
  }

  .toggle__control {
    flex: 0 0 auto;
    position: relative;
    display: inline-flex;
    padding-left: 5px;
    align-items: center;
    justify-content: center;
    width: var(--width);
    height: var(--height);
    background-color: var(--webmodule-color-neutral-400);
    border: solid var(--webmodule-control-border-width) var(--webmodule-color-neutral-400);
    border-radius: var(--height);
    transition:
      var(--webmodule-animate-fast) border-color,
      var(--webmodule-animate-fast) background-color;
  }

  .toggle__control .toggle__thumb {
    width: var(--thumb-size);
    height: var(--thumb-size);
    background-color: var(--webmodule-color-neutral-0);
    border-radius: 50%;
    border: solid var(--webmodule-control-border-width) var(--webmodule-color-neutral-400);
    translate: calc((var(--width) - var(--height)) / -2);
    transition:
      var(--webmodule-animate-fast) translate ease,
      var(--webmodule-animate-fast) background-color,
      var(--webmodule-animate-fast) border-color,
      var(--webmodule-animate-fast) box-shadow;
  }

  .toggle__input {
    position: absolute;
    opacity: 0;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }

  /* Hover */

  .toggle:not(.toggle--checked):not(.toggle--disabled) .toggle__control:hover {
    background-color: var(--webmodule-color-neutral-400);
    border-color: var(--webmodule-color-neutral-400);
  }

  .toggle:not(.toggle--checked):not(.toggle--disabled) .toggle__control:hover .toggle__thumb {
    background-color: var(--webmodule-color-neutral-0);
    border-color: var(--webmodule-color-neutral-400);
  }

  /* Focus */

  .toggle:not(.toggle--checked):not(.toggle--disabled) .toggle__input:focus-visible ~ .toggle__control {
    background-color: var(--webmodule-color-neutral-400);
    border-color: var(--webmodule-color-neutral-400);
  }

  .toggle:not(.toggle--checked):not(.toggle--disabled) .toggle__input:focus-visible ~ .toggle__control .toggle__thumb {
    background-color: var(--webmodule-color-neutral-0);
    border-color: var(--webmodule-color-primary-500);
    outline: var(--webmodule-focus-border);
    outline-offset: var(--webmodule-focus-border-offset);
  }

  /* Checked */

  .toggle--checked .toggle__control {
    background-color: var(--webmodule-color-primary-500);
    border-color: var(--webmodule-color-primary-500);
  }

  .toggle--checked .toggle__control .toggle__thumb {
    background-color: var(--webmodule-color-neutral-0);
    border-color: var(--webmodule-color-primary-500);
    translate: calc((var(--width) - var(--height)) / 2);
  }

  /* Checked + hover */

  .toggle.toggle--checked:not(.toggle--disabled) .toggle__control:hover {
    background-color: var(--webmodule-color-primary-400);
    border-color: var(--webmodule-color-primary-400);
  }

  .toggle.toggle--checked:not(.toggle--disabled) .toggle__control:hover .toggle__thumb {
    background-color: var(--webmodule-color-neutral-0);
    border-color: var(--webmodule-color-primary-400);
  }

  /* Checked + focus */

  .toggle.toggle--checked:not(.toggle--disabled) .toggle__input:focus-visible ~ .toggle__control {
    background-color: var(--webmodule-color-primary-500);
    border-color: var(--webmodule-color-primary-500);
  }

  .toggle.toggle--checked:not(.toggle--disabled) .toggle__input:focus-visible ~ .toggle__control .toggle__thumb {
    background-color: var(--webmodule-color-neutral-0);
    border-color: var(--webmodule-color-primary-500);
    outline: var(--webmodule-focus-border);
    outline-offset: var(--webmodule-focus-border-offset);
  }

  /* Disabled */

  .toggle--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .toggle__label {
    display: inline-block;
    line-height: var(--height);
    margin-inline-start: 0.5em;
    user-select: none;
    -webkit-user-select: none;
  }

  :host([required]) .toggle__label::after {
    content: var(--webmodule-control-required-content);
    color: var(--webmodule-control-required-content-color);
    margin-inline-start: var(--webmodule-control-required-content-offset);
  }

  @media (forced-colors: active) {
    .toggle.toggle--checked:not(.toggle--disabled) .toggle__control:hover .toggle__thumb,
    .toggle--checked .toggle__control .toggle__thumb {
      background-color: ButtonText;
    }
  }
`;
