// eslint-disable-next-line import/named
import { Address } from '../../../interop/webmodule-interop';
import { DataBinding } from '../databinding/databinding';
import { googleMapApiKey } from '../../api/google-api';
import { html, TemplateResult } from 'lit';
import { isEmptyOrSpace } from '../string-helper-functions';
import { Place } from './place';
import { getAssetCdnPath } from '../../common/assets';

/**
 * Html template result for the display of Google Maps, based on the Lat and Lng values.
 * @param lat
 * @param lng
 */
export function mapTemplate(lat: number | undefined, lng: number | undefined): TemplateResult {
  return lat && lng
    ? html`
        <google-map
          api-key=${googleMapApiKey}
          zoom="16"
          fit-to-markers="true"
          .centerLatitude="${lat}"
          .centerLongitude="${lng}"
        >
          <google-map-marker slot="markers" .latitude="${lat}" .longitude="${lng}"></google-map-marker>
        </google-map>
      `
    : html`<img
        src=${getAssetCdnPath('./assets/images/map_placeholder.png')}
        style="max-width:100%;"
        alt="google map"
      />`;
}

/**
 * Empty address object for the creation of clients, Projects, etc.
 */

export function emptyAddress(): Address {
  return {
    longitude: null,
    latitude: null,
    country: '',
    line1: '',
    line2: null,
    line3: null,
    line4: null,
    region: '',
    locality: '',
    postcode: '',
    source: null
  };
}

/**
 * Checks to see if the given address is empty or null.
 * @param address The address to be checked.
 * @returns True if the address object is null or its fields are empty, False otherwise.
 */
export function isAddressNullOrEmpty(address?: Address | null): boolean {
  if (address) {
    return (
      isEmptyOrSpace(address.line1) &&
      isEmptyOrSpace(address.locality) &&
      isEmptyOrSpace(address.postcode) &&
      isEmptyOrSpace(address.region) &&
      isEmptyOrSpace(address.country)
    );
  }
  return true;
}

export function addressToV6Strings(defaultAddress: Address): string[] {
  /*
//FROM V6
  ADDRLINE_Line1    = Ord(alAddressLine1) + 1;
  ADDRLINE_Line2    = Ord(alAddressLine2) + 1;
  ADDRLINE_Line3    = Ord(alCity) + 1;
  ADDRLINE_Line4    = Ord(alState) + 1;
  ADDRLINE_Line5    = Ord(alPostCode) + 1;
  ADDRLINE_Line6    = Ord(alCountry) + 1;

             */
  const lines: string[] = [];

  function numberOfLines() {
    if (!isEmptyOrSpace(defaultAddress.line1)) lines.push(defaultAddress.line1);
    if (!isEmptyOrSpace(defaultAddress.line2)) lines.push(defaultAddress.line2 ?? '');
    if (!isEmptyOrSpace(defaultAddress.line3)) lines.push(defaultAddress.line3 ?? '');
    if (!isEmptyOrSpace(defaultAddress.line4)) lines.push(defaultAddress.line4 ?? '');

    return lines.length;
  }

  const lineCount = numberOfLines();
  let line1 = '';
  let line2 = '';
  switch (lineCount) {
    case 1:
      line1 = lines[0];
      break;
    case 2:
      line1 = lines[0];
      line2 = lines[1];
      break;
    case 3:
      line1 = lines[0];
      line2 = `${lines[1]}, ${lines[2]}`;
      break;
    case 4:
      line1 = `${lines[0]}, ${lines[1]}`;
      line2 = `${lines[2]}, ${lines[3]}`;
      break;
    default:
      break;
  }
  const result: string[] = [];
  result.push(line1);
  result.push(line2);
  result.push(defaultAddress.locality ?? '');
  result.push(defaultAddress.region ?? '');
  result.push(defaultAddress.postcode);
  result.push(`${defaultAddress.country}`);
  return result;
}

export function isAddressValid(address: Address): boolean {
  return !isAddressNullOrEmpty(address);
}

export function singleLineAddress(address?: Address | null): string {
  if (!address) return '';
  return [
    address.line1,
    address.line2,
    address.line3,
    address.line4,
    address.locality,
    address.region,
    address.postcode,
    address.country
  ]
    .filter(Boolean)
    .join(', ');
}

/**
 * This helper function will update the Line1, Locality, Region, Postcode and Country fields on the data-binder.
 * It will also return the Lat and Lng values for the address.
 * @param address Updated map values from lookup.
 * @param databinding
 */
export function updateAddressDataBinding(
  address: Place,
  databinding: DataBinding
): null | { lat: number; lng: number } {
  if (address) {
    const latPhysical = address.latLng.lat;
    const lngPhysical = address.latLng.lng;

    databinding.setValue('line1', address.basic.address);
    databinding.setValue('locality', address.basic.city);
    databinding.setValue('region', address.basic.state);
    databinding.setValue('postcode', address.basic.postalCode);
    databinding.setValue('country', address.basic.country);

    return { lat: latPhysical, lng: lngPhysical };
  }

  return null;
}

export function updateAddress(place: Place, address: Address): Address {
  if (place) {
    address.line1 = place.basic.address;
    address.locality = place.basic.city;
    address.region = place.basic.state;
    address.postcode = place.basic.postalCode;
    address.country = place.basic.country;
    address.latitude = place.latLng.lat.toString();
    address.longitude = place.latLng.lng.toString();
  }

  return address;
}

export function updateAddressWithAddress(address: Address, newAddress?: Address): Address {
  if (newAddress) {
    address.line1 = newAddress.line1;
    address.locality = newAddress.locality;
    address.region = newAddress.region;
    address.postcode = newAddress.postcode;
    address.country = newAddress.country;
    address.latitude = newAddress.latitude;
    address.longitude = newAddress.longitude;
  }

  return address;
}

export function areStreetAddressesEqual(address1: Address, address2: Address): boolean {
  return (
    address1.line1 === address2.line1 &&
    address1.locality === address2.locality &&
    address1.region === address2.region &&
    address1.postcode === address2.postcode &&
    address1.country === address2.country
  );
}
