import { AppDealers } from './pages/app-dealer';
import { AppHome } from './pages/app-home';
import { AppLogin } from './pages/app-login';
import { AppSettings } from './pages/app-settings';
import { AppSupportInbox } from './pages/app-support-inbox';
import { MenuItem } from '../../webmodule-common/other/app-index';

import { PageNotFound } from './pages/page-not-found';
import { pages } from './page-constants';
import { tlang } from '../../webmodule-common/other/language/lang';

type MenuItemSub = {
  name: string;
  title: string;
};

export function appConfigMenuPageItems() {
  const config = appConfig();
  const getItem = (item: MenuItemSub): MenuItem => {
    return { name: item.name, title: item.title, path: config.routes.find(x => x.name === item.name)?.path ?? '' };
  };
  return [
    getItem({
      name: pages.home,
      title: tlang`Dashboard`
    }),
    getItem({
      name: pages.supportInbox,
      title: tlang`Support Inbox`
    }),
    getItem({
      name: pages.dealers,
      title: tlang`%%franchisee-network%%`
    }),
    getItem({
      name: pages.settings,
      title: tlang`Settings`
    })
  ];
}

export function appConfig() {
  return {
    routes: [
      {
        path: '/',
        name: pages.home,
        component: 'app-home',
        action: async () => {
          //return home
          return new AppHome();
        }
      },
      {
        path: '/login',
        name: pages.login,
        component: 'app-login',
        action: async () => {
          return new AppLogin();
        }
      },
      {
        path: '/dealers',
        name: pages.dealers,
        action: async () => {
          return new AppDealers();
        }
      },
      {
        path: '/supportinbox',
        name: pages.supportInbox,
        action: async () => {
          return new AppSupportInbox();
        }
      },
      {
        path: '/settings',
        name: pages.settings,
        action: async () => {
          return new AppSettings();
        }
      },
      {
        path: '(.*)',
        name: 'not-found',
        action: async () => {
          return new PageNotFound();
        }
      }
    ]
  };
}
