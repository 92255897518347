import { css } from 'lit';

export default css`
  :host {
    display: block;
  }

  .input {
    flex: 1 1 auto;
    display: inline-flex;
    align-items: stretch;
    justify-content: start;
    position: relative;
    width: 100%;
    font-family: var(--webmodule-control-font-family);
    font-weight: var(--webmodule-control-font-weight);
    letter-spacing: var(--webmodule-control-letter-spacing);
    vertical-align: middle;
    overflow: hidden;
    cursor: text;
    transition:
      var(--webmodule-animate-fast) color,
      var(--webmodule-animate-fast) border,
      var(--webmodule-animate-fast) box-shadow,
      var(--webmodule-animate-fast) background-color;
  }

  /* Standard inputs */

  .input--standard {
    background-color: var(--webmodule-control-background-color);
    border: solid var(--webmodule-control-border-width) var(--webmodule-control-border-color);
  }

  .input--standard:hover:not(.input--disabled) {
    background-color: var(--webmodule-control-background-color-hover);
    border-color: var(--webmodule-control-border-color-hover);
  }

  .input--standard.input--focused:not(.input--disabled) {
    background-color: var(--webmodule-control-background-color-focus);
    border-color: var(--webmodule-control-border-color-focus);
    box-shadow: 0 0 0 var(--webmodule-focus-border-width) var(--webmodule-control-focus-ring-color);
  }

  .input--standard.input--focused:not(.input--disabled) .input__control {
    color: var(--webmodule-control-color-focus);
  }

  .input--standard.input--disabled {
    background-color: var(--webmodule-control-background-color-disabled);
    border-color: var(--webmodule-control-border-color-disabled);
    opacity: 0.5;
    cursor: not-allowed;
  }

  .input--standard.input--disabled .input__control {
    color: var(--webmodule-control-color-disabled);
  }

  .input--standard.input--disabled .input__control::placeholder {
    color: var(--webmodule-control-placeholder-color-disabled);
  }

  /* Filled inputs */

  .input--filled {
    border: none;
    background-color: var(--webmodule-control-filled-background-color);
    color: var(--webmodule-control-color);
  }

  .input--filled:hover:not(.input--disabled) {
    background-color: var(--webmodule-control-filled-background-color-hover);
  }

  .input--filled.input--focused:not(.input--disabled) {
    background-color: var(--webmodule-control-filled-background-color-focus);
    outline: var(--webmodule-focus-border);
    outline-offset: var(--webmodule-focus-border-offset);
  }

  .input--filled.input--disabled {
    background-color: var(--webmodule-control-filled-background-color-disabled);
    opacity: 0.5;
    cursor: not-allowed;
  }

  .input__control {
    flex: 1 1 auto;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    min-width: 0;
    height: 100%;
    color: var(--webmodule-control-color);
    border: none;
    background: inherit;
    box-shadow: none;
    padding: 0;
    margin: 0;
    cursor: inherit;
    -webkit-appearance: none;
    text-align: right;
  }

  .input--negative-value .input__control {
    color: var(--webmodule-color-danger);
  }

  .input__control::-webkit-search-decoration,
  .input__control::-webkit-search-cancel-button,
  .input__control::-webkit-search-results-button,
  .input__control::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .input__control:-webkit-autofill,
  .input__control:-webkit-autofill:hover,
  .input__control:-webkit-autofill:focus,
  .input__control:-webkit-autofill:active {
    box-shadow: 0 0 0 var(--webmodule-control-height-large) var(--webmodule-control-background-color-hover) inset !important;
    -webkit-text-fill-color: var(--webmodule-color-primary-500);
    caret-color: var(--webmodule-control-color);
  }

  .input--filled .input__control:-webkit-autofill,
  .input--filled .input__control:-webkit-autofill:hover,
  .input--filled .input__control:-webkit-autofill:focus,
  .input--filled .input__control:-webkit-autofill:active {
    box-shadow: 0 0 0 var(--webmodule-control-height-large) var(--webmodule-control-filled-background-color) inset !important;
  }

  .input__control::placeholder {
    color: var(--webmodule-control-placeholder-color);
    user-select: none;
    -webkit-user-select: none;
  }

  .input:hover:not(.input--disabled) .input__control {
    color: var(--webmodule-control-color-hover);
  }

  .input__control:focus {
    outline: none;
  }

  .input__prefix,
  .input__suffix {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    cursor: default;
  }

  .input__prefix ::slotted(webmodule-icon),
  .input__suffix ::slotted(webmodule-icon) {
    color: var(--webmodule-control-icon-color);
  }

  /*
   * Size modifiers
   */

  .input--small {
    border-radius: var(--webmodule-control-border-radius-small);
    font-size: var(--webmodule-control-font-size-small);
    height: var(--webmodule-control-height-small);
  }

  .input--small .input__control {
    height: calc(var(--webmodule-control-height-small) - var(--webmodule-control-border-width) * 2);
    padding: 0 var(--webmodule-control-spacing-small);
  }

  .input--small .input__clear {
    width: calc(1em + var(--webmodule-control-spacing-small) * 2);
  }

  .input--small .input__prefix ::slotted(*) {
    margin-inline-start: var(--webmodule-control-spacing-small);
  }

  .input--small .input__suffix ::slotted(*) {
    margin-inline-end: var(--webmodule-control-spacing-small);
  }

  .input--medium {
    border-radius: var(--webmodule-control-border-radius-medium);
    font-size: var(--webmodule-control-font-size-medium);
    height: var(--webmodule-control-height-medium);
  }

  .input--medium .input__control {
    height: calc(var(--webmodule-control-height-medium) - var(--webmodule-control-border-width) * 2);
    padding: 0 var(--webmodule-control-spacing-medium);
  }

  .input--medium .input__clear {
    width: calc(1em + var(--webmodule-control-spacing-medium) * 2);
  }

  .input--medium .input__prefix ::slotted(*) {
    margin-inline-start: var(--webmodule-control-spacing-medium);
  }

  .input--medium .input__suffix ::slotted(*) {
    margin-inline-end: var(--webmodule-control-spacing-medium);
  }

  .input--large {
    border-radius: var(--webmodule-control-border-radius-large);
    font-size: var(--webmodule-control-font-size-large);
    height: var(--webmodule-control-height-large);
  }

  .input--large .input__control {
    height: calc(var(--webmodule-control-height-large) - var(--webmodule-control-border-width) * 2);
    padding: 0 var(--webmodule-control-spacing-large);
  }

  .input--large .input__clear {
    width: calc(1em + var(--webmodule-control-spacing-large) * 2);
  }

  .input--large .input__prefix ::slotted(*) {
    margin-inline-start: var(--webmodule-control-spacing-large);
  }

  .input--large .input__suffix ::slotted(*) {
    margin-inline-end: var(--webmodule-control-spacing-large);
  }

  /*
   * Pill modifier
   */

  .input--pill.input--small {
    border-radius: var(--webmodule-control-height-small);
  }

  .input--pill.input--medium {
    border-radius: var(--webmodule-control-height-medium);
  }

  .input--pill.input--large {
    border-radius: var(--webmodule-control-height-large);
  }

  /*
   * Clearable + Password Toggle
   */

  .input__clear {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    color: var(--webmodule-control-icon-color);
    border: none;
    background: none;
    padding: 0;
    transition: var(--webmodule-animate-fast) color;
    cursor: pointer;
  }

  .input__clear:hover {
    color: var(--webmodule-control-icon-color-hover);
  }

  .input__clear:focus {
    outline: none;
  }

  /* Hide the built-in number spinner */

  .input--no-spin-buttons input[type='number']::-webkit-outer-spin-button,
  .input--no-spin-buttons input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  .input--no-spin-buttons input[type='number'] {
    -moz-appearance: textfield;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;
