import { BaseLogin, DeploymentFranchiseeBranch, Tenant } from '../../api/supplier-api-interface-franchiseenetwork';
import { BaseModal } from '../../../webmodule-common/other/ui/modal/modal-factory';
import {
  canClose,
  ErrorAbandon,
  isAutoSaving,
  SavePromptOptions,
  saveWithIndicator
} from '../../../webmodule-common/other/save-workflow';
import { checkValidations } from '../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { clone } from '../../../webmodule-common/other/clone';
import { createConfirmCancelButtons } from '../../../webmodule-common/other/ui/modal-footer-buttons';
import { customElement, property } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { FranchiseeBranchDeploymentView } from '../data/franchisee-branch-deployment-view';
import { getApiFactory } from '../../api/api-injector';
import { html, LitElement, PropertyValueMap, TemplateResult } from 'lit';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../supplier/common/current-user-data-store';

type Branch = DeploymentFranchiseeBranch;

@customElement('webmodule-supplier-franchisee-branch-editor')
export class WebModuleFranchiseeBranchEditor extends LitElement {
  @property() tenant?: Tenant;
  @property() primaryContact?: BaseLogin;
  @property() readonly = false;
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));

  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.branch),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    const tenantData = () => this.tenant;
    const addTenantField = (fieldName: string, propertyType?: FieldType, nullable?: boolean) => {
      this.dataTracker.addObjectBinding(
        tenantData,
        fieldName,
        fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    const contactData = () => this.primaryContact;
    const addContactField = (fieldName: string) => {
      this.dataTracker.addObjectBinding(contactData, fieldName, fieldName, FieldType.string, false);
    };

    addContactField('userName');
    addContactField('friendlyName');
    addContactField('emailAddress');
    addContactField('jobTitle');
    addContactField('phoneNumber');

    addTenantField('companyName', FieldType.string, false);
    addTenantField('primaryContactPhoneNumber', FieldType.string, false);

    const mockstatus = { status: tlang`Active` };
    addField('status', FieldType.string, false, undefined, () => mockstatus);

    addField('name', FieldType.string, false);
    addField('taxRegistrationNumber', FieldType.string, false);
    this.dataTracker.addDynamic(
      'branchReference',
      FieldType.string,
      () => this.branchReference,
      (value: string) => {
        this.branchReference = value.toUpperCase();
      }
    );
  }

  @property()
  private _branch?: Branch | undefined;

  public get branch(): Branch | undefined {
    return this._branch;
  }

  public set branch(value: Branch | undefined) {
    this._branch = clone(value);
  }

  @property()
  private _branchReference = '';

  public get branchReference() {
    return this._branchReference;
  }

  public set branchReference(value) {
    this._branchReference = value;
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-sbe-${name}`, options));
  }

  branchChangedEvent() {
    this.dispatchCustom('changed', {
      branch: this.branch,
      branchReference: this.branchReference
    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }

  render() {
    const forms = new FormInputAssistant(this.dataTracker);

    if (!this.branch) return html``;
    return html`
      <div class="form-two-col p-3 branch-form">
        <div class="row">
          <div>
            <h2>${tlang`%%franchisee%% Details`}</h2>
            <div>
              ${forms.textReadonly('companyName', tlang`%%franchisee%% Name`)}
              ${forms.textReadonly('name', tlang`Branch Name`)}
              ${forms.textReadonly('taxRegistrationNumber', tlang`Tax Registration #:`)}
              ${forms.text1('branchReference', tlang`V6 Customer Code:`, {
                maxLength: 20,
                events: {
                  change: this.changeEvent,
                  blur: this.changeEvent
                },
                uppercase: true
              })}
              ${forms.textReadonly('primaryContactPhoneNumber', tlang`Business Phone`)}
              ${forms.textReadonly('status', tlang`Status`)}
            </div>
          </div>
          <div>
            <h2>${tlang`Primary %%contact%%`}</h2>
            ${forms.textReadonly('userName', tlang`User Name`)}
            ${forms.textReadonly('friendlyName', tlang`Display Name`)}
            ${forms.textReadonly('emailAddress', tlang`Email`)} ${forms.textReadonly('jobTitle', tlang`Role`)}
            ${forms.textReadonly('phoneNumber', tlang`Mobile Phone`)}
          </div>
        </div>
        <div class="row"></div>
        <wm-addresseditor
          id="physicalAddressEditor"
          .address=${this.branch.physicalAddress}
          .sectionTitle=${tlang`Physical Address`}
          .readonly=${true}
          .isDefaultShippingVisible=${true}
          .isDefaultShipping=${this.branch.physicalAddressAsDefaultShipping}
        ></wm-addresseditor>
      </div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    setTimeout(() => {
      const editor = this.dataTracker.binder.getElement('branchReference') as HTMLElement;
      if (editor) {
        editor.focus();
        (editor as any).select?.();
      }
    }, 200);
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();

    this.dataTracker.applyChangeToValue();
    this.branchChangedEvent();
  };
}

export async function editBranch(item: FranchiseeBranchDeploymentView) {
  await userDataStore.loadCoreDetails();
  const data = userDataStore.allFranchiseeDetails.branchReferences.find(
    x => x.tenantId === item.tenantId && x.dealerDeplymentId === item.deploymentId
  );

  const branchReference = data?.referenceOverrides?.[item.branchId] ?? item.reference;
  const branch = userDataStore.allFranchiseeDetails.branches.find(x => x.branchId === item.branchId);
  if (branch) {
    const dialog = new BranchEditDialog(item, branch, branchReference);
    await dialog.showModal();
    return dialog.saved;
  } else {
    await information(tlang`Could not locate %%branch%% data`);
    return false;
  }
}

class BranchEditDialog extends BaseModal {
  abandoned: any;
  branch: Branch;
  branchReference: string;
  item: FranchiseeBranchDeploymentView;
  backupBranchReference: string;
  saved = false;

  constructor(item: FranchiseeBranchDeploymentView, branch: Branch, branchReference: string) {
    super();
    this.item = item;
    this.backupBranchReference = branchReference;
    this.branchReference = branchReference;
    this.branch = branch;
  }

  get currentBranch(): Branch {
    return this.branch;
  }

  get isFooterVisible(): boolean {
    return true;
  }

  protected get modalSize() {
    return 'modal-fullscreen';
  }

  async title(): Promise<string | TemplateResult> {
    return tlang`Edit %%branch%%`;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];

    return errors;
  }

  updateCurrentBranch(branchReference: string) {
    this.branchReference = branchReference;
  }

  async bodyTemplate(): Promise<TemplateResult> {
    const branchChangedEvent = (e: CustomEvent<{ branch: Branch; branchReference: string }>) => {
      e.stopImmediatePropagation();
      this.updateCurrentBranch(e.detail.branchReference);
    };
    return html` <webmodule-supplier-franchisee-branch-editor
      .primaryContact=${this.item.primaryContact}
      .tenant=${userDataStore.allFranchiseeDetails.tenantList.tenants?.find(x => x.id === this.item.tenantId)}
      .branch=${this.currentBranch}
      .branchReference=${this.branchReference}
      @wm-sbe-changed=${branchChangedEvent}
    ></webmodule-supplier-franchisee-branch-editor>`;
  }

  async performAutoSave(options: SavePromptOptions): Promise<boolean> {
    //if we dont need to save, then just exit.
    //but if we are not in autosave mode, we are going to force a save.
    let needsSave = false;
    try {
      needsSave = await options.needsSaveEvent();
    } catch (e) {
      await showDevelopmentError(e as Error);
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      console.log((e as object).toString());
      return false;
    }

    //this is to let us abandon new items that have had no editing
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (!needsSave) throw new ErrorAbandon('Cancel', tlang`Cancel New Edit`);

    if (!(await this.checkValidations())) return false;
    if (isAutoSaving() && !needsSave) return true;

    //this is our basic save indicator
    return await saveWithIndicator(async () => await this.internalSaveData());
  }

  async internalSaveData() {
    const referenceOverrides = {};
    referenceOverrides[this.item.branchId] = this.branchReference;
    await getApiFactory().franchisee().updateTenantCustomerCodes({
      dealerDeploymentId: this.item.deploymentId,
      tenantId: this.item.tenantId,
      referenceOverrides: referenceOverrides
    });
    this.saved = true;
    const refs = userDataStore.allFranchiseeDetails.branchReferences.find(
      x => x.dealerDeplymentId == this.item.deploymentId && x.tenantId == this.item.tenantId
    );
    if (refs) {
      if (refs.referenceOverrides) refs.referenceOverrides[this.item.branchId] = this.branchReference;
      else refs.referenceOverrides = referenceOverrides;
    }

    return true;
  }

  getAutoSavePromptOptions(): SavePromptOptions {
    return {
      isReadonly: false,
      autoSaveEvent: async options => {
        return await this.performAutoSave(options);
      },
      dictionaryName: '%%branch%%',
      needsSaveEvent: async () => this.branchReference !== this.backupBranchReference,
      abandonSaveEvent: async () => {
        this.abandoned = true;
        this.branchReference = this.backupBranchReference;
        return true;
      },
      displaySaveModal: false,
      informationDispatcher: undefined
    };
  }

  async canClose(): Promise<boolean> {
    return this.abandoned || (await canClose(this.getAutoSavePromptOptions()));
  }

  footerTemplate(): TemplateResult | null | undefined {
    const okEvent = async () => {
      if (await this.canClose()) {
        await this.hideModal();
        return;
      }
    };
    const closeEvent = async () => await this.hideModal();
    return createConfirmCancelButtons(tlang`Save Changes`, okEvent, tlang`Cancel`, closeEvent);
  }

  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    return await checkValidations(errors);
  }
}
