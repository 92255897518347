import '../../../webmodule-common/other/ui/maps/google-map';
import '../../../webmodule-common/other/ui/maps/google-place-autocomplete';
import { customElement, state } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventSnippet, PromiseTemplate } from '../../../webmodule-common/interop/webmodule-interop';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getCredentials } from '../../api/licenseserver-credentials';
import { html, TemplateResult } from 'lit';
import { ILicenseServerApiCredentials, licenseServerUIBootLoader } from '../../settings/data/licenseserver-integration';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import { ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { OnboardFranchisee, onboardFranchisee } from '../franchisee-creator';
import { saveWithIndicator } from '../../../webmodule-common/other/save-workflow';
import { showValidationsWithDiscard } from '../../../webmodule-common/other/ui/modal-validationhandler';
import { SystemViewLicensePool, ViewDeploymentTenants } from '../../api/supplier-api-interface-franchiseenetwork';
import { tlang } from '../../../webmodule-common/other/language/lang';

export interface OnboardFranchiseeModalOptions {
  deployment: ViewDeploymentTenants;
  modalTitle: EventSnippet;
  saveButtonTitle: EventSnippet;
  cancelButtonTitle: EventSnippet;
}

@customElement('wm-onboardfranchiseemodal')
export class OnboardFranchiseeModal extends ModalViewBase {
  options: OnboardFranchiseeModalOptions;
  forceReadonly: boolean;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  @state()
  franchisee: OnboardFranchisee;
  @state()
  ok = false;
  credentials: ILicenseServerApiCredentials;
  @state()
  protected pricingRuleDisplay = '';

  constructor(options: OnboardFranchiseeModalOptions) {
    super();
    this.options = options;
    this.forceReadonly = false;
    this.franchisee = new OnboardFranchisee();
    this.credentials = getCredentials();
    // franchisee databinding
    this.dataBinding = new DataBinding(this.ui, undefined, input => {
      return `franchisee-${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.franchisee),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    addField('companyName', FieldType.string, false);
    addField('reference', FieldType.string, false);
    const mockStatus = { status: tlang`New` };
    addField('status', FieldType.string, false, undefined, () => {
      return mockStatus;
    });
    addField('licenseAllocation', FieldType.int, false);
    addField('availableAllocations', FieldType.int, false, undefined, () => this.activeLicensePool);

    addField('initialName', FieldType.string, false);
    addField('initialEmail', FieldType.string, false);
    addField('initialPhone', FieldType.string, false);
    addField('initialJobTitle', FieldType.string, false);
  }

  get activeLicensePoolId(): number {
    const lp = this.activeLicensePool;
    return lp?.licensePool?.id ?? -1;
  }

  get activeLicensePool(): SystemViewLicensePool | undefined {
    return this.options.deployment.licensePools?.[0];
  }

  get editUserName(): HTMLElement | null {
    return this.ui.querySelector('#userName');
  }

  /**
   * inherited
   * @returns
   */
  protected get modalSize() {
    return 'modal-xl';
  }

  public async afterConstruction(): Promise<void> {
    await licenseServerUIBootLoader();
  }

  /**
   * inherited
   * @returns
   */
  getTitle() {
    return this.options.modalTitle() ?? '';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    return true;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    return html`
      <form class="py-3 px-0 frm-dealer-details form-two-col">
        <div class="row">
          <div>
            <h2>${tlang`%%franchisee%% Details`}</h2>
            ${forms.text('companyName', tlang`%%franchisee%% Name`, 100)}
            ${forms.text('reference', tlang`V6 Customer Code`, 20)} ${forms.textReadonly('status', tlang`Status`)}
            ${forms.intReadonly('availableAllocations', tlang`Licenses Available`)}
            ${forms.int('licenseAllocation', tlang`Licenses Allocated`, {
              min: 0,
              max: this.activeLicensePool?.availableAllocations ?? 1
            })}
          </div>

          <div>
            <h2>${tlang`Primary %%user%%`}</h2>

            <softtechls-username
              id="userName"
              .credentials=${this.credentials}
              .userName=${this.franchisee.initialUserName}
            ></softtechls-username>

            ${forms.text('initialName', tlang`Display Name`, 100)} ${forms.text('initialEmail', tlang`Email`, 200)}
            ${forms.text('initialJobTitle', tlang`Role`, 100)} ${forms.text('initialPhone', tlang`Mobile Phone`, 100)}
          </div>
        </div>
      </form>
    `;
  }

  protected footerTemplate(): TemplateResult | null {
    const okEvent = async () => {
      if (this.editUserName?.['checkingUserName']) {
        await information(tlang`Please wait for %%user%% name validation`);
        return;
      }
      this.prepareForSave();
      const errors = await this.getValidationErrors();
      if (errors.length != 0) {
        const discardChanges = await showValidationsWithDiscard(errors);
        if (discardChanges) {
          await this.hideModal();
        }
      } else {
        if (await this.save()) await this.hideModal();
      }
    };
    return this.createConfirmCancelButtons(this.options.saveButtonTitle(), okEvent, this.options.cancelButtonTitle());
  }

  protected prepareForSave(): void {
    if (this.forceReadonly) return;

    this.dataTracker.applyChangeToValue();
    this.franchisee.initialUserName = this.editUserName?.['userName'] ?? '';
  }

  protected async save(): Promise<boolean> {
    return await saveWithIndicator(async () => {
      await lockUIandExecute(async () => {
        console.log(this.franchisee);
        const result = await onboardFranchisee({
          franchisee: this.franchisee,
          deploymentId: this.options.deployment.dealerDeploymentId,
          licensePoolId: this.activeLicensePoolId
        });

        this.ok = result;
      });
      return this.ok;
    }, true);
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors: string[] = [];

    // const pricingRuleId = this.dataBindingDealer.getValue("pricingRuleId");
    const assignedLicenseCount = this.franchisee.licenseAllocation;

    if (isEmptyOrSpace(this.franchisee.initialUserName))
      errors.push(tlang`Please provide a %%user%% Name for the %%franchisee%%`);

    if (isEmptyOrSpace(this.franchisee.companyName)) errors.push(tlang`Please provide a Name for the %%franchisee%%`);

    if (isEmptyOrSpace(this.franchisee.initialName)) errors.push(tlang`Please provide a Name for the %%user%%`);

    if (isEmptyOrSpace(this.franchisee.initialEmail))
      errors.push(tlang`Please provide an accurate Email for the %%user%%`);

    if (!assignedLicenseCount || assignedLicenseCount < 0)
      errors.push(tlang`Please ensure that the number of assigned Licenses is not negative`);
    if (
      this.activeLicensePool &&
      assignedLicenseCount &&
      assignedLicenseCount > this.activeLicensePool.availableAllocations
    )
      errors.push(
        tlang`You may not allocate above your current available limit of ${this.activeLicensePool.availableAllocations}`
      );

    return errors;
  }

  protected getPricingRulePickerDisplay(): string {
    return this.pricingRuleDisplay;
  }
}
