//helper class that works with objects, editors and
//databinding to form a very simple observation pattern
//SEE: quote-detail-view.ts for an example
import { DataBinding } from './databinding';
import { DevelopmentError } from '../../development-error';
import {
  localDateTimeToServer,
  localRFC3339DateTimeToServer,
  localRFC3339DateToServer,
  serverDateTimeToLocalRFC3339,
  serverDateToLocalRFC3339
} from '../../datetime-converter';
import { strToMoney } from '../../currency-formatter';
import { sameText } from '../string-helper-functions';

export interface ValueBinder {
  fieldType: FieldType;
  nullable: boolean;

  exists(): boolean;

  getValue(): EventValue;

  setValue(value: EventValue): void;

  readonly(): boolean;
}

export type EventValue = string | number | boolean | null | object | undefined;
export type EventValueGetter = () => EventValue;
export type EventValueSetter = (value: EventValue, fieldType: FieldType, nullable: boolean) => void;

export class DynamicValueBinder implements ValueBinder {
  fieldType: FieldType;
  nullable: boolean;
  getter: EventValueGetter;
  setter: EventValueSetter;
  _readonly: (() => boolean) | undefined;

  constructor(
    fieldType: FieldType,
    nullable: boolean,
    getter: EventValueGetter,
    setter: EventValueSetter,
    readonly?: () => boolean
  ) {
    this.nullable = nullable;
    this.fieldType = fieldType;
    this.getter = getter;
    this.setter = setter;
    this._readonly = readonly;
  }

  exists(): boolean {
    return true;
  }

  getValue(): EventValue {
    return this.getter();
  }

  setValue(value: string | number | null) {
    this.setter(value, this.fieldType, this.nullable);
  }

  readonly(): boolean {
    return this._readonly?.() ?? false;
  }
}

export enum FieldType {
  string = 0,
  int = 1,
  dateTime = 2,
  float = 3,
  boolean = 4,
  money = 5,
  date = 7,
  object = 8,
  file = 9,
  fileLink = 10
}

export class ObjectPropertyBinder implements ValueBinder {
  fieldBinder: DynamicValueBinder;
  data: () => any;
  fieldName: string;

  constructor(data: () => any, fieldName: string, fieldType: FieldType, nullable: boolean) {
    this.data = data;
    this.fieldName = fieldName;
    this.fieldBinder = new DynamicValueBinder(
      fieldType,
      nullable,
      () => {
        return this.data()[this.fieldName];
      },
      (value: EventValue) => {
        if ((value === null || value === undefined || value === '') && nullable) this.data()[this.fieldName] = null;
        else {
          const newValue = value?.toString();
          let val = 0;
          let dateStr = '';
          switch (fieldType) {
            case FieldType.fileLink:
              this.data()[this.fieldName] = value;
              break;

            case FieldType.string:
              this.data()[this.fieldName] = newValue ?? '';
              break;
            case FieldType.int:
              val = parseInt(newValue ?? '0');
              if (isNaN(val)) throw new Error(`${newValue} is not a number`);
              this.data()[this.fieldName] = val;
              break;
            case FieldType.float:
              val = parseFloat(newValue ?? '0');
              if (isNaN(val)) throw new Error(`${newValue} is not a number`);
              this.data()[this.fieldName] = val;
              break;
            case FieldType.money:
              val = strToMoney(newValue ?? '0', 4);
              if (isNaN(val)) throw new Error(`${newValue} is not a number`);
              this.data()[this.fieldName] = val;
              break;
            case FieldType.boolean:
              this.data()[this.fieldName] = sameText(newValue, 'true');
              break;
            case FieldType.dateTime:
              //TODO untested
              if (!newValue) throw new DevelopmentError(`invalid date "${newValue}" `);

              //C# streaming is forced now to match toISOString exactly
              dateStr = localRFC3339DateTimeToServer(newValue);
              this.data()[this.fieldName] = dateStr;
              break;
            case FieldType.date:
              if (!newValue) throw new DevelopmentError(`invalid date "${newValue}" `);

              // If you use the ISO format, and you give only the date and not the time/time zone,
              // it will automatically accept the time zone as UTC. But if you add the time/timezone it will
              // accept the time zone as local(or specifically specified zone).
              // In our case newValue is the already local date (yyyy-mm-dd), just passing that to the
              // date constructor will create a date object with the values but as UTC that will result in
              // the date being incremented with every save.
              // By adding a time to the newValue (yyyy-mm-dd) + "T00:00", the date is created as local date
              // time zone which then gets "converted" back to the correct UTC value with the toISOString.
              dateStr = localRFC3339DateToServer(newValue);
              this.data()[this.fieldName] = dateStr;
              break;
          }
        }
      },
      undefined // properties are not readonly
    );
  }

  get fieldType(): FieldType {
    return this.fieldBinder.fieldType;
  }

  get nullable(): boolean {
    return this.fieldBinder.nullable;
  }

  exists(): boolean {
    return true;
  }

  getValue(): EventValue {
    return this.fieldBinder.getValue();
  }

  setValue(value: string | number | null) {
    this.fieldBinder.setValue(value);
  }

  readonly(): boolean {
    return false;
  }
}

export function htmlDisplayValue(value: EventValue, fieldType: FieldType, nullable: boolean): string | null {
  if ((value === null || value === undefined || value === '') && nullable) return null;
  else {
    const newValue = value?.toString();

    let val = 0;
    let dateVal = '';
    switch (fieldType) {
      case FieldType.file:
      case FieldType.fileLink:
        return newValue ?? '';
      case FieldType.string:
        return newValue ?? '';
      case FieldType.int:
        if (!newValue) throw new Error(`${newValue} is not a number`);
        val = parseInt(newValue);
        if (isNaN(val)) throw new Error(`${newValue} is not a number`);
        return val.toString();
      case FieldType.float:
        if (!newValue) throw new Error(`${newValue} is not a number`);
        val = parseFloat(newValue);
        if (isNaN(val)) throw new Error(`${newValue} is not a number`);
        return val.toString();
      case FieldType.money:
        if (!newValue) throw new Error(`${newValue} is not a number`);
        val = strToMoney(newValue, 4);
        if (isNaN(val)) throw new Error(`${newValue} is not a number`);
        return val.toString();
      case FieldType.boolean:
        return newValue ?? 'false';
      case FieldType.dateTime:
        //TODO untested
        if (!newValue) throw Error('invalid datetime');

        //temporary hack for some local objects declared as
        //date but need to be strings
        if (typeof newValue !== 'string') dateVal = localDateTimeToServer(newValue as any as Date);
        else dateVal = newValue;
        return serverDateTimeToLocalRFC3339(dateVal);
      case FieldType.date:
        if (!newValue) throw Error('invalid date');
        //temporary hack for some local objects declared as
        //date but need to be strings
        if (typeof newValue !== 'string') dateVal = localDateTimeToServer(newValue as any as Date);
        else dateVal = newValue;
        return serverDateToLocalRFC3339(dateVal);
      default:
        throw new Error(`${newValue} is not a date`);
    }
  }
}

export class HTMLElementBinder implements ValueBinder {
  fieldBinder: DynamicValueBinder;
  binder: DataBinding;

  fieldName: string;

  constructor(dataBinder: DataBinding, fieldName: string, fieldType: FieldType, nullable: boolean) {
    this.binder = dataBinder;
    this.fieldName = fieldName;
    this.fieldBinder = new DynamicValueBinder(
      fieldType,
      nullable,
      () => {
        switch (fieldType) {
          case FieldType.file:
          case FieldType.fileLink:
            return this.binder.getFile(fieldName, 0);
          case FieldType.int:
            return this.binder.getInt(fieldName, nullable);
          case FieldType.float:
            return this.binder.getFloat(fieldName, nullable);
          case FieldType.money:
            return this.binder.getMoney(fieldName, nullable);
          case FieldType.boolean:
            return this.binder.getBoolean(fieldName);
          case FieldType.string:
            return this.binder.getValue(fieldName);
          case FieldType.dateTime:
          case FieldType.date:
            return this.binder.getValue(fieldName);
          default:
            throw new Error(`${fieldType} not mapped yet`);
        }
      },
      (value: EventValue, fieldType: FieldType, nullable: boolean) => {
        const newValue = htmlDisplayValue(value, fieldType, nullable);
        this.binder.setValue(fieldName, newValue);
      },
      () => this.binder.readonly(fieldName)
    );
  }

  get fieldType(): FieldType {
    return this.fieldBinder.fieldType;
  }

  get nullable(): boolean {
    return this.fieldBinder.nullable;
  }

  getValue(): EventValue {
    return this.fieldBinder.getValue();
  }

  setValue(value: string | number | null) {
    this.fieldBinder.setValue(value);
  }

  exists(): boolean {
    return this.binder.exists(this.fieldName);
  }

  readonly(): boolean {
    return this.fieldBinder.readonly();
  }
}

export interface ValueManager {
  modified: boolean;

  applyChangeToValue: () => void;
  resetEditorValue: () => void;
}

export class ValueEditorBinder implements ValueManager {
  dataField: string;
  valueBinder: ValueBinder;
  editorBinder: ValueBinder;
  owner: DataTracker;

  constructor(dataField: string, valueBinder: ValueBinder, editorBinder: ValueBinder) {
    this.editorBinder = editorBinder;
    this.valueBinder = valueBinder;
    this.dataField = dataField;
  }

  get modified(): boolean {
    if (!this.editorBinder.exists()) return false;
    if (this.editorBinder.readonly()) return false;
    const editorVal = this.editorBinder.getValue();
    const objectVal = this.valueBinder.getValue();
    return editorVal !== objectVal;
  }

  applyChangeToValue() {
    const isModified = this.modified;
    if (!this.editorBinder.exists()) return;
    if (this.editorBinder.readonly()) return;

    const editorVal = this.editorBinder.getValue();
    this.valueBinder.setValue(editorVal);
    if (isModified) this.owner?.doChangeEvent();
  }

  resetEditorValue() {
    if (!this.editorBinder.exists()) return;
    const objVal = this.valueBinder.getValue();

    this.editorBinder.setValue(objVal);
  }
}

class DataTrackerBindings {
  bindings: ValueEditorBinder[] = [];
  private owner: DataTracker;

  constructor(owner: DataTracker) {
    this.owner = owner;
  }

  push(valueManager: ValueEditorBinder) {
    this.bindings.push(valueManager);
    valueManager.owner = this.owner;
  }

  find(fieldName: string): ValueEditorBinder | undefined {
    return this.bindings.find(x => x.dataField === fieldName);
  }

  clear() {
    this.bindings = [];
  }
}

export class DataTracker implements ValueManager {
  binder: DataBinding;
  public eventChange: (() => void) | undefined;
  private eventBlock = 0;
  private _bindings: DataTrackerBindings = new DataTrackerBindings(this);

  constructor(binder: DataBinding) {
    this.binder = binder;
  }

  get modified(): boolean {
    return this._bindings.bindings.some(x => x.modified);
  }

  add(valueManager: ValueEditorBinder) {
    this._bindings.push(valueManager);
  }

  applyChangeToValue() {
    this.eventBlock++;
    try {
      this._bindings.bindings.forEach(x => x.applyChangeToValue());
    } finally {
      this.eventBlock--;
      this.doChangeEvent();
    }
  }

  clear() {
    this._bindings.clear();
  }

  resetEditorValue() {
    this._bindings.bindings.forEach(x => x.resetEditorValue());
  }

  addObjectBinding(
    data: () => any,
    dataField: string,
    editorFieldName: string,
    fieldType: FieldType = FieldType.string,
    nullable = false
  ) {
    this._bindings.push(
      new ValueEditorBinder(
        dataField,
        new ObjectPropertyBinder(data, dataField, fieldType, nullable),
        new HTMLElementBinder(this.binder, editorFieldName, fieldType, nullable)
      )
    );
  }

  addImageLink(
    dataField: string,
    getter: () => string | undefined,
    setter: (value: File) => void,
    options?: {
      editorFieldName?: string;
      nullable?: boolean;

      readonly?: (() => boolean) | undefined;
    }
  ) {
    this.addDynamic(dataField, FieldType.fileLink, getter, setter, options);
  }

  addDynamic(
    dataField: string,
    fieldType: FieldType,
    getter: EventValueGetter,
    setter?: EventValueSetter,
    options?: {
      editorFieldName?: string;
      nullable?: boolean;

      readonly?: (() => boolean) | undefined;
    }
  ) {
    this._bindings.push(
      new ValueEditorBinder(
        dataField,
        new DynamicValueBinder(
          fieldType,
          options?.nullable ?? false,
          getter,
          setter ??
            (() => {
              //do nothing
            }),
          options?.readonly
        ),
        new HTMLElementBinder(this.binder, options?.editorFieldName ?? dataField, fieldType, options?.nullable ?? false)
      )
    );
  }

  addBinding(
    binding: ValueBinder,
    dataField: string,
    editorFieldName: string,
    fieldType: FieldType = FieldType.string,
    nullable = false
  ) {
    this._bindings.push(
      new ValueEditorBinder(
        dataField,
        binding,
        new HTMLElementBinder(this.binder, editorFieldName, fieldType, nullable)
      )
    );
  }

  getBinder(fieldName: string): ValueEditorBinder | undefined {
    return this._bindings.bindings.find(x => x.dataField === fieldName);
  }

  getObjectValue(fieldName: string): EventValue {
    const binder = this._bindings.find(fieldName);
    return binder?.valueBinder.getValue() ?? null;
  }

  getEditorValue(fieldName: string): EventValue {
    const binder = this._bindings.find(fieldName);
    return binder?.editorBinder.getValue() ?? null;
  }

  setEditorValue(fieldName: string, value: EventValue) {
    const binder = this._bindings.find(fieldName);
    binder?.editorBinder.setValue(value);
  }

  getObjectDisplayValue(fieldName: string): string | null {
    const binder = this._bindings.find(fieldName);
    if (binder)
      return htmlDisplayValue(binder.valueBinder.getValue(), binder.valueBinder.fieldType, binder.valueBinder.nullable);
    else return '';
  }

  removeFilesFromEditors() {
    this._bindings.bindings.forEach(b => {
      if (b.editorBinder.fieldType === FieldType.fileLink) {
        b.editorBinder.setValue('');
      }
    });
  }

  public doChangeEvent() {
    if (this.eventBlock === 0) {
      this.eventChange?.();
    }
  }
}
