import { EventVoid, EventVoidAsync } from './events';
import { html, TemplateResult } from 'lit';
import { tlang } from '../language/lang';
import { FormInputAssistantBase } from './templateresult/form-input-assistant-base';

//import { FormInputAssistantBase } from './templateresult/form-input-assistant';

/**
 * function which creates a confirm/cancel button pair for use in the footer of the modal.
 * @param confirmText text to be shown on the confirm button.
 * @param confirmEvent event to be run when clicking on the confirm button.
 * @param cancelText text to be shown on the cancel button.
 * @param cancelEvent event to be run when clicking on the cancel button. If undefined uses the default close event.
 * @returns TemplateResult
 */
export function createConfirmCancelButtons(
  confirmText: string | TemplateResult,
  confirmEvent: EventVoid | EventVoidAsync,
  cancelText: string | TemplateResult,
  cancelEvent: EventVoid | EventVoidAsync
): TemplateResult {
  const forms = new FormInputAssistantBase();
  return html`
    ${forms.buttonDefault(cancelText, { events: { click: cancelEvent } })}
    ${forms.buttonPrimary(confirmText, { events: { click: confirmEvent } })}
  `;
}

export function createCancelButton(
  cancelEvent: EventVoid | EventVoidAsync,
  cancelText?: string | TemplateResult
): TemplateResult {
  return html` <webmodule-button size="small" variant="default" @click=${cancelEvent}>
    ${cancelText ?? tlang`Close`}
  </webmodule-button>`;
}
