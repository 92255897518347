import { html, nothing, TemplateResult } from 'lit';

export function getToolTip(
  toolTip: string | TemplateResult,
  options?: { placement?: 'top' | 'bottom' | 'left' | 'right'; maxSize?: string }
): TemplateResult {
  return typeof toolTip === 'string'
    ? html`
        <webmodule-tooltip
          content="${toolTip}"
          hoist
          placement=${options?.placement ?? nothing}
          style=${options?.maxSize ? `--max-width:${options?.maxSize}` : nothing}
        >
          <webmodule-icon library="fa" name="far-circle-question" class="text-primary"></webmodule-icon>
        </webmodule-tooltip>
      `
    : html`
        <webmodule-tooltip
          hoist
          placement=${options?.placement ?? nothing}
          style=${options?.maxSize ? `--max-width:${options?.maxSize}` : nothing}
        >
          <webmodule-icon library="fa" name="far-circle-question" class="text-primary"></webmodule-icon>
          <div slot="content">${toolTip}</div>
        </webmodule-tooltip>
      `;
}
