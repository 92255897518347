import { pageReload } from './ui/resource-resolver';
import { sameText } from './ui/string-helper-functions';

// eslint-disable-next-line no-var -- var declared as it is replaced by

let usedebugv6 = 'false';
let isv6Debugging = usedebugv6 == 'true';
let localBuildNumber = '';
let isDebugging = sameText('local', localBuildNumber);

export function setDebugInformation(alocalBuildNumber: string, localDebugV6: string) {
  usedebugv6 = localDebugV6;
  isv6Debugging = usedebugv6 == 'true';
  localBuildNumber = alocalBuildNumber;
  isDebugging = sameText('local', localBuildNumber);
  globalThis.localBuildNumber = localBuildNumber;
}

export function isDebugMode(): boolean {
  return isDebugging;
}

export function isv6DebugMode(): boolean {
  return isv6Debugging;
}

export function getLocalBuildNumber(): string {
  return localBuildNumber;
}

let debugOutofdate = false;

export function isDebugForcedOutOfDate(): boolean {
  return debugOutofdate;
}

export function appOutOfDate(buildNumber?: string): boolean {
  if (debugOutofdate) {
    return true;
  }
  return (
    !sameText(getLocalBuildNumber(), buildNumber ?? globalThis.dealerConfiguration?.apiBuildNumberDisplay) &&
    !isDebugMode()
  );
}

export function getServerBuildNumberDisplay() {
  return globalThis.dealerConfiguration?.apiBuildNumberDisplay ?? '';
}

export async function checkAppOutOfDate() {
  await globalThis.dealerRefreshApiDetails?.();
}

export const outOfDateURL = '/outofdate';
globalThis.forceOutOfDate = (value = true, reRoute = true) => {
  debugOutofdate = value;
  if (reRoute) pageReload();
};

globalThis.isAppOutOfDate = (buildNumber?: string) => {
  return appOutOfDate(buildNumber);
};
