import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PricingRule } from '../../api/supplier-api-interface-supplier';
import { PricingRuleEditModal } from './pricing-rule-edit-modal';
import { PricingRulesManager } from '../data/pricing-rules-manager';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import { tlang } from '../../../webmodule-common/other/language/lang';

export type PricingRuleEvent = (pricingRule: PricingRule) => Promise<void>;

@autoElement()
export class SettingsPricingRulesView extends PageControlTabWithIndependantSaving {
  protected pricingRulesManager: PricingRulesManager;

  constructor() {
    super();

    this.pageFragment = 'pricing-rules';

    this._supplierId = getSettingsManager().supplierSettings.globalSupplierId;

    this.pricingRulesManager = this.pricingRulesManagerFactory();
  }

  private _supplierId: number;

  get supplierId(): number {
    return this._supplierId;
  }

  public async afterConstruction(): Promise<void> {
    await getSettingsManager().needsSettings(true);
  }

  public allowDeletePage(): boolean {
    return false;
  }

  async prepareForSave(): Promise<void> {}

  public internalDataChanged(): boolean {
    return this.pricingRulesManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.refreshData();
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];
    return errors;
  }

  buttonMenu(): Snippet {
    const createNewPricingRuleEvent = async () => lockUIandExecute(async () => await this.createNewPricingRule());

    return html`
      <button @click=${createNewPricingRuleEvent} class="btn btn-primary" type="button">${tlang`Create Rule`}</button>
    `;
  }

  protected getCaption(): Snippet {
    return tlang`!!pricing-rule!!`;
  }

  protected async internalSaveData(): Promise<boolean> {
    const result = await this.pricingRulesManager.save(true);
    return result;
  }

  protected async refreshData() {
    this.requestUpdate();
  }

  protected async bodyTemplate(): PromiseTemplate {
    const createNewPricingRuleEvent = async () => lockUIandExecute(async () => await this.createNewPricingRule());

    return html` <div>
      <form id="OnboardingEmailForm" class="form-two-col">
        <h2>${this.getCaption()}</h2>
        <div class="row">
          <div></div>
        </div>
        <div class="row">
          <div class="new-table-item">
            <button
              @click=${createNewPricingRuleEvent}
              class="btn btn-primary"
              type="button"
              id="btn-create-pricing-rule"
            >
              ${tlang`Create Rule`}
            </button>
          </div>
        </div>
      </form>
    </div>`;
  }

  protected pricingRulesManagerFactory(): PricingRulesManager {
    return new PricingRulesManager();
  }

  protected async createNewPricingRule(): Promise<void> {
    const title = tlang`Add %%pricing-rule%%`;
    const pricingRule: PricingRule = {
      id: newGuid(),
      supplierId: this.supplierId,
      name: '',
      maxValue: 0,
      minValue: 0,
      maxMarkup: 0,
      minMarkup: 0,
      ruleTypeId: emptyGuid
    };
    const pricingRuleModal = await constructAsync(
      new PricingRuleEditModal({
        pricingRule: pricingRule,
        modalTitle: () => title,
        saveButtonTitle: () => tlang`Save`,
        cancelButtonTitle: () => tlang`Cancel`,
        forceReadonly: false,
        pricingRuleTypes: this.pricingRulesManager!.pricingRuleTypes,
        getRuleType: this.pricingRulesManager!.getPricingRuleType
      })
    );
    await pricingRuleModal.showModal();
    if (pricingRuleModal.ok) {
      this.pricingRulesManager!.createPricingRule(pricingRule);
      this.requestUpdate();
    }
  }

  protected async editPricingRule(pricingRule: PricingRule) {
    const title = tlang`Edit %%pricing-rule%%`;
    const pricingRuleModal = await constructAsync(
      new PricingRuleEditModal({
        pricingRule: pricingRule,
        modalTitle: () => title,
        saveButtonTitle: () => tlang`Save`,
        cancelButtonTitle: () => tlang`Cancel`,
        forceReadonly: false,
        pricingRuleTypes: this.pricingRulesManager!.pricingRuleTypes,
        getRuleType: this.pricingRulesManager!.getPricingRuleType
      })
    );
    await pricingRuleModal.showModal();
    if (pricingRuleModal.ok) {
      const pr = pricingRuleModal.pricingRule;
      this.pricingRulesManager!.editPricingRule(pr);
    }
  }

  protected async deletePricingrule(pricingRule: PricingRule) {
    this.pricingRulesManager!.deletePricingRule(pricingRule);
  }
}
