import { autoElement } from '../../../../webmodule-common/components/src/webmodule-components';
import { emptyGuid, newGuid } from '../../../../webmodule-common/other/api/guid';
import {
  getMinMaxMarginPricingRuleType,
  getRegularRetailPricingRuleType,
  PricingRulesManager
} from '../../../settings/data/pricing-rules-manager';
import { html } from 'lit';
import { PricingRule, PricingRuleLibrary } from '../../../api/supplier-api-interface-supplier';
import { PromiseTemplate, Snippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { SettingsPricingRulesView } from '../../../settings/view/settings-pricing-rules-view';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import PricingRulesTableElement, { IPricingRulesTable } from '../ui/pricing-rules-table-element';
import { getMarginMarkupHelpPage } from '../../../../webmodule-common/other/ui/margin-markup-help-page';
import { errorList } from '../../../../webmodule-common/other/error-list';

@autoElement()
export class SupplierSettingsPricingRulesView extends SettingsPricingRulesView {
  protected newPricingRulesTable: IPricingRulesTable;

  constructor() {
    super();

    this.newPricingRulesTable = this.pricingRulesTableFactoryNew();
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    if (this.pricingRulesManager.empty()) this.pricingRulesManager.container = this.getDefaultPricingRulesContainer();
  }

  public getValidationErrors(): string[] {
    const validator = errorList(super.getValidationErrors());

    for (const pricingRule of this.pricingRulesManager.pricingRules) {
      if (pricingRule.ruleTypeId) {
        const ruleType = this.pricingRulesManager.getPricingRuleType(pricingRule.ruleTypeId);
        if (ruleType && ruleType.hasMinMax) {
          validator.assert(
            pricingRule.minValue >= 0,
            tlang`Minimum Margin value cannot be less than 0% for %%min%%/%%max%% %%pricing-rule%% '${pricingRule.name}'`
          );
          validator.assert(
            pricingRule.minValue < pricingRule.maxValue,
            tlang`Maximum Margin value cannot be less than or equal to Minimum Margin for %%min%%/%%max%% %%pricing-rule%% '${pricingRule.name}'`
          );
          validator.assert(
            pricingRule.maxValue < 100,
            tlang`Maximum Margin value must be less than 100% for %%min%%/%%max%% %%pricing-rule%% '${pricingRule.name}'`
          );
          validator.assert(
            pricingRule.minMarkup >= 0,
            tlang`Minimum Markup value cannot be less than 0% for %%min%%/%%max%% %%pricing-rule%% '${pricingRule.name}'`
          );
          validator.assert(
            pricingRule.maxMarkup >= pricingRule.minMarkup,
            tlang`Maximum Markup value cannot be less than Minimum Markup for %%min%%/%%max%% %%pricing-rule%% '${pricingRule.name}'`
          );
        }
      }
    }

    return validator.errors;
  }

  buttonMenu(): Snippet {
    return html``;
  }

  protected pricingRulesManagerFactory(): PricingRulesManager {
    return new PricingRulesManager();
  }

  protected getDefaultPricingRulesContainer(): PricingRuleLibrary {
    const rrpRuleType = getRegularRetailPricingRuleType();
    const minMaxRuleType = getMinMaxMarginPricingRuleType();

    const rrpRule: PricingRule = {
      id: newGuid(),
      minValue: 20,
      maxValue: 0,
      minMarkup: 25,
      maxMarkup: 0,
      name: tlang`%%rrp-abbrev%%`,
      supplierId: this.supplierId,
      ruleTypeId: rrpRuleType.id
    };
    const minMaxRule: PricingRule = {
      id: newGuid(),
      minValue: 15,
      maxValue: 25,
      minMarkup: 25,
      maxMarkup: 33.5,
      name: tlang`%%min%%/%%max%%`,
      supplierId: this.supplierId,
      ruleTypeId: minMaxRuleType.id
    };

    return {
      pricingRules: [rrpRule, minMaxRule],
      pricingRuleTypes: [rrpRuleType, minMaxRuleType],
      defaultPricingRuleId: emptyGuid
    };
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html` <div>
      <form id="PricingRulesForm" class="form-one-col pricing-rules-page">
        <h2>${this.getCaption()}</h2>
        <div class="row">
          <div class="col-12 col-lg-8 col-xl-7 pricing-rules-table-wrapper">${this.newPricingRulesTable.template()}</div>
          <div class="col-12 col-lg-4 col-xl-5">${getMarginMarkupHelpPage()}</div>
        </div>
      </form>
    </div>`;
  }

  protected pricingRulesTableFactoryNew(): IPricingRulesTable {
    const table = new PricingRulesTableElement();
    table.dataSource = () => this.pricingRulesManager.container;
    table.updateEvent = async (pricingRule: PricingRule) => {
      this.pricingRulesManager.editPricingRule(pricingRule);
    };
    table.setDefaultRuleEvent = async (pricingRuleId?: string) => {
      this.pricingRulesManager.setDefaultPricingRuleId(pricingRuleId ?? emptyGuid);
    };
    table.defaultRuleId = this.pricingRulesManager.container.defaultPricingRuleId;

    return {
      template: () => html`${table}`,
      actions: () => table.getActions()
    };
  }
}
