import { html, PropertyValueMap, TemplateResult } from 'lit';
import { lapsedMinutes } from '../general/time';
import { LitElementBase } from '../litelement-base';
import { lockUIandExecute } from '../ui-lock';
import { property, query, state } from 'lit/decorators.js';
import { RequestPage, ResultPaginated } from './RequestPage';
import { TimedTrigger } from '../timed-trigger';
import { tlang } from '../language/lang';
import { WebModuleLitTable, WebModuleLitTableColumnDef } from '../../components/src/webmodule-components';

export class LitTableWrapper<RowClass> extends LitElementBase {
  isDataLoadDelayed() {
    return false;
  }

  lastRefresh: Date = new Date();
  timedTrigger!: TimedTrigger | undefined;
  filterValue: string | null = null;

  @property({ reflect: true })
  class;

  @property({ type: Boolean })
  clickrows = false;

  @property({ type: Boolean })
  sortAscending = false;

  @query('.dataTables_scrollBody')
  scrollBody?: Element | null;

  @query('.data-table')
  dataTable?: WebModuleLitTable | null;

  @state()
  lastFetch: RowClass[] = [];

  @state()
  selectMode: string = 'none';

  constructor() {
    super();
    this.initTimedTrigger();
  }

  private _delayedDataLoad = this.isDataLoadDelayed();
  public get delayedDataLoad() {
    return this._delayedDataLoad;
  }

  @property({ type: Boolean })
  public set delayedDataLoad(value) {
    if (this._delayedDataLoad !== value) {
      this._delayedDataLoad = value;
      this.delayedDataLoadChanged(value);
    }
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.classList.add('div');
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('class')) {
      this.classList.add('div');
    }
  }

  //build and create the datatable.
  connectDataTable() {
    const dataTableElement = this.dataTable;
    if (dataTableElement) {
      dataTableElement.columns = this.getColumns();
      dataTableElement.sortField = this.getDefaultSortField();
      dataTableElement.sortAscending = this.getDefaultSortAsc();
    } else throw new Error("could not bind 'lit-table'");
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<RowClass>> {
    return {
      count: 0,
      pageCount: 0,
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      results: []
    };
  }

  public async rowClicked(_details: CustomEvent<{ table: WebModuleLitTable; item: RowClass }>) {
    // To be overridden in the derived class and only has an effect if click rows set to true
  }

  pageLength(): number {
    //override as needed
    return 200;
  }

  enableFiltering(): boolean {
    //override as needed
    return false;
  }

  updateFilter(_searchTerm: string | null) {
    //Override as needed
    return;
  }

  enableAdvancedFiltering(): boolean {
    //override as needed
    return false;
  }

  getDefaultSortAsc(): boolean {
    //Override as needed
    return false;
  }

  getDefaultSortField(): string | undefined {
    //Override as needed
    return undefined;
  }

  getRowClass(): string {
    //Override as needed
    return 'row';
  }

  getColumnClass(): string {
    //Override as needed
    return 'col';
  }

  //must override and provide a correct set of columns, which will render based on the RowType class fields
  getColumns(): WebModuleLitTableColumnDef[] {
    return [];
  }

  public filterTemplate(): TemplateResult {
    const resetEvent = this.timedTrigger?.getResetEvent();
    const triggerEvent = this.timedTrigger?.getTriggerEarlyEvent();
    const advancedFilterTemplate = this.enableAdvancedFiltering()
      ? html` <div class="col-7 filter-advanced">${this.advancedFilterTemplate()}</div>`
      : html``;

    return !this.enableFiltering()
      ? html``
      : html`
          <div class="row form-group filter-wrapper">
            ${advancedFilterTemplate}
            <div class="col-5 filter-input">
              <webmodule-input
                size="small"
                class="webmodule-control-left-label"
                label="${tlang`Filter`}:"
                placeholder="${tlang`Text Search`}"
                autocomplete="off"
                @webmodule-input=${resetEvent}
                @webmodule-blur=${triggerEvent}
                @webmodule-change=${resetEvent}
              ></webmodule-input>
            </div>
          </div>
        `;
  }

  public advancedFilterTemplate(): TemplateResult {
    //override as needed
    return html``;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getItemRowClass(_item: RowClass) {
    //Override as needed
    return '';
  }

  public tableTemplate(): TemplateResult {
    return html`
      <webmodule-lit-table
        class="data-table"
        .rowClass=${this.getRowClass()}
        .colClass=${this.getColumnClass()}
        .data=${this.lastFetch}
        pageLength=${this.pageLength()}
        ?clickrows=${this.clickrows}
        .itemRowClassEvent=${this.getItemRowClass}
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        @rowclick=${(e: CustomEvent<{ table: WebModuleLitTable; item: RowClass }>) => this.rowClicked(e)}
        selectmode="${this.selectMode}"
      >
      </webmodule-lit-table>
    `;
  }

  public bodyTemplate(): TemplateResult {
    //override as needed
    return html` <div class="row">
      <div class="col-12">${this.filterTemplate()} ${this.tableTemplate()}</div>
    </div>`;
  }

  render() {
    return html` ${this.bodyTemplate()} `;
  }

  public async refreshData(sinceLastRefreshMinutes?: number): Promise<void> {
    if (this.dataTable && (!sinceLastRefreshMinutes || lapsedMinutes(this.lastRefresh) >= sinceLastRefreshMinutes)) {
      this.dataTable?.fetchEvent();
      this.requestUpdate();
    }
  }

  protected delayedDataLoadChanged(value: boolean) {
    if (!value) this.refreshData();
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);
    this.connectDataTable();
    const filteredEnabledClass = this.enableFiltering() ? `webmodule-filter-enabled` : ``;
    if (this.scrollBody && filteredEnabledClass !== '') {
      this.scrollBody?.classList.add(filteredEnabledClass);
    }
  }

  protected async eventHandler(callback: () => Promise<void>) {
    await lockUIandExecute(async () => await callback());
  }

  protected enableTableClass(): string {
    //override
    return '';
  }

  private initTimedTrigger() {
    const triggerEvent = e => {
      this.filterChangeEvent(e);
    };
    this.timedTrigger = new TimedTrigger(1000, triggerEvent);
  }

  private async internalDataLoad(e: CustomEvent) {
    if (this.delayedDataLoad) {
      this.lastFetch = [];
      this.dataTable!.rowCount = 0;
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 20;

    const sortField = (e.detail?.sortField as string) ?? undefined;
    const sortAsc = (e.detail?.sortAscending as boolean) ?? undefined;

    const results = await this.getRowsFromServer({
      pageIndex: index,
      pageSize: length,
      sortAsc,
      sortField
    });

    if (results) {
      this.lastFetch = results.results ?? [];
      this.dataTable!.rowCount = results.count;
    }
  }

  private async filterChangeEvent(e: Event) {
    const searchTerm = (e.target as HTMLInputElement).value;

    this.updateFilter(searchTerm);

    await this.refreshData();
  }
}
