export async function flushAndReloadApplication(reload = true) {
  await globalThis.noSqlDb?.clear?.();
  const cacheNames = await caches.keys();
  await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
  console.log('All caches cleared');
  if (reload) window.location.reload();
}

let handling409 = false;

export async function handleOutOfDateIntercept(reload = true) {
  if (handling409) return;
  if (globalThis.runningIsOutOfDate) return;
  handling409 = true;

  globalThis.runningIsOutOfDate = true;
  try {
    await flushAndReloadApplication(false);
    if (reload) {
      //this error came form a server response possibly so we need to force update our version info

      await globalThis.dealerRefreshApiDetails?.();
    }
    /*
    await information(
      tlang`${'ref:409-outofdate'}
The Dealer system is now out-of-sync due to a recent scheduled update and will refresh.

You will lose any unsaved changes. 

    Current Server Version: ${globalThis.dealerConfiguration.apiBuildNumberDisplay}
    
    Current Local Version: ${globalThis.localBuildNumber ?? 'Unknown'}

You will be redirected and the page will refresh.

**Tip:** To avoid this issue we recommend logging out overnight or when not in use to reduce the risk of data loss during scheduled updates.
            `,
      'System out-of-date'
    );
    */
  } finally {
    window.location.reload();
    handling409 = false;
  }
}
