import { DashboardFilter } from './dashboard/widget/dashboard-filter';
import { GeneratedPurchaseOrdersWidget } from './dashboard/widget/generated-purchase-orders-widget';
import { WebModuleAssignUserEdit } from '../dealer/view/assignto-edit';
import { TerritoryFranchiseeTableElement } from '../settings/ui/territory-franchisee-table-element';

export const supplierComponentRegistry = {
  dashboardFilterControls: DashboardFilter,
  dashboardGeneratedPurchaseOrderWidgets: GeneratedPurchaseOrdersWidget,
  webModuleAssignUserEdit: WebModuleAssignUserEdit,
  territoryFranchiseeTableElement: TerritoryFranchiseeTableElement
};
