import { claimIdentity } from '../../../webmodule-common/other/api/user-security.js';
import { containsText, isEmptyOrSpace, sameText } from '../../../webmodule-common/other/ui/string-helper-functions.js';
import { customElement, query, state } from 'lit/decorators.js';
import { emptyAddress } from '../../../webmodule-common/other/ui/maps/map-helpers.js';
import { emptyGuid } from '../../../webmodule-common/other/api/guid.js';
import { EventGuidAction } from '../data/events.js';
import {
  FranchiseeBranchDeploymentView,
  FranchiseeBranchDeploymentViewEvent
} from '../data/franchisee-branch-deployment-view.js';
import { getAssetCdnPath } from '../../../webmodule-common/other/common/assets.js';
import { getLicenseData, LicensePoolData } from '../../supplier/common/supplier-license-data.js';
import { html, LitElement, TemplateResult } from 'lit';
import { information } from '../../../webmodule-common/other/ui/modal-option.js';
import { TenantInitializationState, ViewDeploymentTenants } from '../../api/supplier-api-interface-franchiseenetwork.js';
import { TimedTrigger } from '../../../webmodule-common/other/timed-trigger.js';
import { tlang } from '../../../webmodule-common/other/language/lang.js';
import { userDataStore } from '../../supplier/common/current-user-data-store.js';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components.js';

export interface FranchiseeSummaryTableOptions {
  deploymentId: number;
  onOpen: EventGuidAction;
  onLogin: FranchiseeBranchDeploymentViewEvent;
}

export interface FranchiseeNetworkTableData {
  branchDeployments: FranchiseeBranchDeploymentView[];
  licenseData: LicensePoolData | undefined;
}

@customElement('webmodule-franchisee-network')
export class WebModuleFranchiseeNetwork extends LitElement {
  @query('#branches-table')
  table?: WebModuleLitTable;
  @state()
  private _viewDeploymentTenants: ViewDeploymentTenants | undefined;
  @state()
  private _data?: FranchiseeNetworkTableData;

  @state()
  private _filter?: string;

  timedTrigger?: TimedTrigger;

  public get viewDeploymentTenants(): ViewDeploymentTenants | undefined {
    return this._viewDeploymentTenants;
  }

  public set viewDeploymentTenants(value: ViewDeploymentTenants | undefined) {
    this._viewDeploymentTenants = value;
    this.convertData(); //fires off a promise
  }

  public get data(): FranchiseeNetworkTableData | undefined {
    return this._data;
  }

  public set data(value: FranchiseeNetworkTableData | undefined) {
    this._data = value;
    this.table?.fetchEvent();
  }

  async convertData() {
    const results: FranchiseeBranchDeploymentView[] = [];

    const viewDeployment = this.viewDeploymentTenants;

    if (!viewDeployment) {
      this.data = { branchDeployments: [], licenseData: undefined };
      return;
    }
    await userDataStore.loadCoreDetails();
    const cacheData = userDataStore.allFranchiseeDetails;

    viewDeployment.tenants?.forEach(tenantInfo => {
      const tenant = cacheData?.tenantList.tenants?.find(x => x.id === tenantInfo.tenantId);
      const franchiseeDeploymentConfiguration = cacheData?.franchisees.find(
        x => x.franchiseeTenantId == tenantInfo.tenantId && x.dealerDeploymentId == viewDeployment.dealerDeploymentId
      );
      const branches = cacheData?.branches.filter(
        x =>
          x.dealerDeploymentId == franchiseeDeploymentConfiguration?.dealerDeploymentId &&
          x.franchiseeTenantId == franchiseeDeploymentConfiguration?.franchiseeTenantId
      );

      const branchReferences = cacheData?.branchReferences.find(
        x => x.dealerDeplymentId === viewDeployment.dealerDeploymentId && x.tenantId === tenant?.id
      );

      if (branches.length === 0) {
        branches.push({
          abbreviation: tlang``,
          branchId: emptyGuid,
          id: emptyGuid,
          dealerDeploymentId: viewDeployment.dealerDeploymentId,
          franchiseeTenantId: tenantInfo.tenantId,
          name: 'Unavailable',
          billingAddress: null,
          dateCreated: '',
          physicalAddress: emptyAddress(),
          physicalAddressAsDefaultShipping: true,
          recordVersion: '',
          taxRegistrationNumber: ''
        });
      }
      if (!tenant) return;
      const userAccess = viewDeployment.userLoginInformation?.find(x => x.tenantId === tenant.id && x.lastLoginTime);
      const primaryContact = viewDeployment?.tenants?.find(x => x.tenantId === tenantInfo.tenantId)?.primaryContact;

      results.push({
        allowSupportAccess: tenantInfo.allowSupplierSupportAccess /* && status is available */,
        branchName: '',
        branchNameAbbreviated: '',
        branchV6CustomerCode: '',
        deploymentDomain: viewDeployment.uiDomain ?? '',
        deploymentId: viewDeployment.dealerDeploymentId,
        franchiseeDeploymentConfigId: franchiseeDeploymentConfiguration?.id ?? emptyGuid,
        LicensesUsed: tenantInfo.licensePoolAllocated ?? 0,
        LicensePoolAllocations: tenantInfo.licensePoolAllocations ?? 0,
        name: tenant.companyName ?? '',
        primaryContact: primaryContact ?? undefined,
        reference: tenantInfo.reference ?? '',
        status: tenantInfo.state ?? TenantInitializationState.Available,
        tenantId: tenantInfo.tenantId,
        branchId: '',
        isBranch: false,
        inUse: userAccess !== undefined,
        sortName: tenant.companyName ?? '',
        sortBranchName: ''
      });
      const tenantUsers = viewDeployment.userLoginInformation?.filter(x => x.tenantId === tenantInfo.tenantId) ?? [];

      function isInvalidBranch(id: string): boolean {
        return branches.find(c => c.branchId === id) === undefined;
      }

      branches.forEach((branch, index) => {
        const isMainBranch = index === 0;

        function isSameBranch(claims: { [key: string]: string } | null) {
          const branchId = claims?.[claimIdentity.branchId] ?? '';
          return sameText(branchId, branch.branchId) || (isInvalidBranch(branchId) && isMainBranch);
        }

        const branchUsers = tenantUsers.filter(x => isSameBranch(x.claims));
        const activeBranchUsers = branchUsers.filter(x => x.active).length ?? 0;

        const fv: FranchiseeBranchDeploymentView = {
          allowSupportAccess: tenantInfo.allowSupplierSupportAccess /* && status is available */,
          branchName: branch.name /* not supporting branches in the UI yet */,
          branchNameAbbreviated: branch.abbreviation,
          branchV6CustomerCode: branchReferences?.referenceOverrides?.[branch.branchId] ?? '',
          deploymentDomain: viewDeployment.uiDomain ?? '',
          deploymentId: viewDeployment.dealerDeploymentId,
          franchiseeDeploymentConfigId: franchiseeDeploymentConfiguration?.id ?? emptyGuid,
          LicensesUsed: activeBranchUsers ?? 0,
          LicensePoolAllocations: tenantInfo.licensePoolAllocations ?? 0,
          name: '',
          primaryContact: primaryContact ?? undefined,
          reference: tenantInfo.reference ?? '',
          status: tenantInfo.state ?? TenantInitializationState.Available,
          tenantId: tenantInfo.tenantId,
          branchId: branch.branchId,
          isBranch: true,
          inUse: userAccess !== undefined,

          sortName: tenant.companyName ?? '',
          sortBranchName: branch.name
        };
        results.push(fv);
      });
    });

    this.data = {
      branchDeployments: results.sort((a, b) => {
        if (a.sortName == b.sortBranchName) {
          return a.sortBranchName.localeCompare(b.sortBranchName);
        }

        return a.sortName.localeCompare(b.sortName);
      }),
      licenseData: await getLicenseData(viewDeployment.dealerDeploymentId)
    };
  }

  render() {
    const keyEvent = (item: FranchiseeBranchDeploymentView) => {
      return item.branchId;
    };
    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: tlang`%%franchisee%% Name`,
      classes: 'colpx-160',
      fieldName: 'name',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        const clickItem = (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          this.editFranchisee(rowItem);
        };
        return !rowItem.isBranch
          ? html`<a class="branch-table-franchisee-name" href="#" @click=${clickItem}>${rowItem.name}</a>`
          : html``;
      }
    });
    columns.push({
      title: tlang`%%branch%% Name`,
      classes: 'colpx-160',
      fieldName: 'branchName',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        const clickItem = async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          if (rowItem.branchId === emptyGuid) {
            await information(tlang`Not available yet`);
            return;
          }
          this.editBranch(rowItem);
        };
        const label = isEmptyOrSpace(rowItem.branchNameAbbreviated)
          ? rowItem.branchName
          : `${rowItem.branchName} (${rowItem.branchNameAbbreviated})`;
        return rowItem.isBranch ? html`<a href="#" @click=${clickItem}>${label}</a>` : html``;
      }
    });
    columns.push({
      title: tlang`V6 Customer Code`,
      classes: 'colpxmax-130',
      fieldName: 'v6CustomerCode',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        const clickItem = async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          if (rowItem.branchId === emptyGuid) {
            await information(tlang`Not available yet`);
            return;
          }
          this.editBranch(rowItem);
        };
        const label = isEmptyOrSpace(rowItem.branchV6CustomerCode) ? rowItem.reference : rowItem.branchV6CustomerCode;
        return rowItem.isBranch ? html`<a href="#" @click=${clickItem}>${label}</a>` : html`${rowItem.reference}`;
      }
    });
    columns.push({
      title: tlang`Primary %%contact%%`,
      classes: 'colpx-150',
      fieldName: 'primaryContact',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        return !rowItem.isBranch ? (rowItem.primaryContact?.friendlyName ?? '') : '';
      }
    });
    columns.push({
      title: tlang`Licenses Allocated`,
      classes: 'colpxmax-130',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        //if the value is -1 then it means that the dealer is using this supplier
        //but their license pool and license is not owned by the supplier
        //could be owned by Cyncly etc.
        //this is less likely to happen outside of the demo environment
        if (rowItem.isBranch) return html``;
        if (rowItem.LicensesUsed < 0) return tlang`N/A`;
        const available = rowItem.LicensePoolAllocations ?? 0;
        return this.zeroAsDashTemplate(available);
      }
    });
    columns.push({
      title: tlang`Licenses Used`,
      classes: 'colpxmax-110',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        if (rowItem.LicensesUsed < 0) return tlang`N/A`;
        const available = rowItem.LicensesUsed;
        return rowItem.isBranch
          ? this.zeroAsDashTemplate(available)
          : html`<span class="fw-bolder">(${available})</span>`;
      }
    });
    columns.push({
      title: tlang`Status`,
      classes: 'colpx-60',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        return !rowItem.isBranch ? html`${getTenantStatusDisplayValue(rowItem.status, rowItem.inUse)}` : html``;
      }
    });
    columns.push({
      title: tlang`Login to %%branch%%`,
      classes: 'colpxmax-120',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as FranchiseeBranchDeploymentView;
        const clickEvent = (_e: Event) => this.loginToFranchisee(rowItem);
        return rowItem.isBranch && rowItem.status == TenantInitializationState.Available
          ? html` <button
              type="button"
              class="multi-action-btn btn-circle btn-access loginToDealer"
              @click=${clickEvent}
            >
              <img class="icon action-key" src=${getAssetCdnPath('/assets/icons/key16.svg')} alt="" />
            </button>`
          : html``;
      }
    });

    const notValid = tlang`N/A`;
    return html` <div class=" branches-editor">
      <div class="mb-3">
        <div class="table-filter-header">
          <div class="filter-left">
            <button type="none" class="multi-action-btn btn-circle loginToDealer">
              <img class="icon action-key" src=${getAssetCdnPath('/assets/icons/key16.svg')} alt="" />
            </button>
            ${tlang`License Bundle:`}
            <span class="fw-bolder fs-1">${this.data?.licenseData?.totalLicenses ?? notValid}</span>
            ${tlang`Licenses Allocated:`}
            <span class="fw-bolder fs-1">${this.data?.licenseData?.licensesAllocatedToDealer ?? notValid}</span>
            ${tlang`Licenses Used:`}
            <span class="fw-bolder fs-1">${this.data?.licenseData?.licensesUsedByDealer ?? notValid}</span>
          </div>
          <div class="filter-right">${this.filterTemplate()}</div>
        </div>
      </div>

      <webmodule-lit-table
        id="branches-table"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .columns=${columns}
        pageLength="100"
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        .clickrows=${false}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
      >
      </webmodule-lit-table>
    </div>`;
  }

  public filterTemplate(): TemplateResult {
    const resetEvent = this.timedTrigger?.getResetEvent();
    const triggerEvent = this.timedTrigger?.getTriggerEarlyEvent();

    return html`
      <label for="filterText">${tlang`Filter:`}</label>
      <input
        .value=${this._filter ?? ''}
        type="text"
        id="filterText"
        placeholder="Text Search"
        @oninput=${resetEvent}
        @blur=${triggerEvent}
        @keyup=${resetEvent}
      />
    `;
  }

  async loginToFranchisee(item: FranchiseeBranchDeploymentView) {
    if (item.branchId === emptyGuid) {
      await information(tlang`Not available yet`);
      return;
    }
    this.dispatchCustom('login', {
      item: item
    });
  }

  editBranch(item: FranchiseeBranchDeploymentView) {
    this.dispatchCustom('open-branch', {
      item: item
    });
  }

  editFranchisee(item: FranchiseeBranchDeploymentView) {
    this.dispatchCustom('open', { item: item });
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-tenant-${name}`, options));
  }

  connectedCallback(): void {
    super.connectedCallback();

    const triggerEvent = e => {
      this._filter = (e.target as HTMLInputElement).value;

      this.table?.fetchEvent();
    };
    this.timedTrigger = new TimedTrigger(1500, triggerEvent);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.timedTrigger = undefined;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private matchFilter(_vbl: FranchiseeBranchDeploymentView): boolean {
    if (!this._filter) return true;

    return (
      containsText(_vbl.sortName, this._filter) ||
      containsText(_vbl.sortBranchName, this._filter) ||
      containsText(_vbl.reference, this._filter)
    );
  }

  private zeroAsDashTemplate(value: number) {
    return value === 0 ? html`${tlang`${'ref:zerodash'}-`}` : html`${value}`;
  }

  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const table = e.detail.table as WebModuleLitTable;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 1000;
    if (!this.data) {
      table.data = [];
    }
    if (this.data) {
      const tableData = this.data.branchDeployments.filter(x => this.matchFilter(x));
      table.data = tableData.slice(index * length, index * length + length);

      table.rowCount = tableData.length;
    } else table.data = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _loadExtension(_e: CustomEvent) {
    //const item: Tenant = e.detail.item;
    //e.detail.table?.addExtension(item, this.getExtensionForTenant());
  }
}

export function getTenantStatusDisplayValue(status: TenantInitializationState, inUse: boolean): string {
  switch (status) {
    case TenantInitializationState.Available:
      return inUse
        ? tlang`${'ref:TenantInitializationState.AvailableInUse'}Active`
        : tlang`${'ref:TenantInitializationState.Available'}Ready`;
    case TenantInitializationState.Disabled:
      return tlang`${'ref:TenantInitializationState.Disabled'}Disabled`;
    case TenantInitializationState.PendingDelivery:
      return tlang`${'ref:TenantInitializationState.PendingDelivery'}Pending Delivery`;
    case TenantInitializationState.RequestedBySupplier:
      return tlang`${'ref:TenantInitializationState.RequestedBySupplier'}Requested By Supplier`;
  }
  return TenantInitializationState[status];
}
