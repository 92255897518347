// eslint-disable-next-line import/named
import './ui/templateresult/FormInputView';
import { appOutOfDate, getLocalBuildNumber, isDebugMode } from './debug';
import { authenticationOptions } from './api/api-injector';
import { customElement, property, state } from 'lit/decorators.js';
import { footerBrandLogoUrl, isDevSite, isTestSite } from './domain';
import { getCurrentUser, setCurrentUser } from './api/current-user';
import { html, LitElement, TemplateResult } from 'lit';
import { ImagePreview, imagePreviewContext } from './context/imagePreview';
import { isCynclyStaff, isSupplierAgent } from './currentuser-claims';
import { isEmptyOrSpace } from './ui/string-helper-functions';
import { IUserSettings } from './context/IUserSettings';
import {
  localSettingsContext,
  sessionSettingsContext,
  userLocalSettings,
  userSessionSettings
} from './context/UserSettingsContext';
import { provide } from '@lit/context';
import { tlang } from './language/lang';
import { showDevelopmentError } from './development-error';
import { urlForName } from './router';
import { flushAndReloadApplication } from './common/web-cache';
import { getAssetCdnPath } from './common/assets';
import { run2FARegisterV1 } from './ui/loginscreen/authenticate-modal-lit';

export interface MenuItem {
  name: string;
  title: string;
  path: string;
}

@customElement('wm-appindex')
export class AppIndex extends LitElement {
  @property({ type: String }) appTitle?: string;
  @property({ type: String }) currentPath?: string;
  @property({ type: String }) menuItems?: string;
  @property({ type: String }) buildNumber?: string = getLocalBuildNumber();
  @property({ type: String }) userDisplayName?: string;

  @provide({ context: imagePreviewContext })
  imagePreview = new ImagePreview();

  @provide({ context: localSettingsContext })
  userSettings: IUserSettings = userLocalSettings;
  @provide({ context: sessionSettingsContext })
  userSessionSettings: IUserSettings = userSessionSettings;
  @state()
  outOfDate: boolean;
  @state()
  protected logoTemplate: TemplateResult = html` <svg width="1" height="1"></svg>`;

  constructor() {
    super();
    globalThis.dealerConfiguration.litApp = this;
    window.onunhandledrejection = event => {
      console.error(event.reason.stack);

      console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
      showDevelopmentError(event.reason);
    };

    window.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
      //Items to ignore
      if (source?.includes('apexcharts.js')) return;
      if (
        source?.includes('ResizeObserver') ||
        error?.message.includes('ResizeObserver') ||
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        event.toString().includes('ResizeObserver')
      )
        return;

      console.warn(`UNHANDLED ERROR: ${error?.stack}`);
      if (error?.stack) console.error(error.stack);
      showDevelopmentError(
        error ??
          `
    
      ${
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        event
      }
    source:${source}
    line:${lineno}
    col :${colno}
    `
      );
    };
    this.outOfDate = appOutOfDate();
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('webmodule-page-navigation', this.updateNavigation);
    this.addEventListener('wm-scroll-into-view', this.scrollElementIntoView);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('webmodule-page-navigation', this.updateNavigation);
    this.removeEventListener('wm-scroll-into-view', this.scrollElementIntoView);
  }

  render() {
    console.log();

    const logoutEvent = async (e: Event) => {
      e.preventDefault();
      await this.disconnectUser();
      await setCurrentUser(null);
      window.location.href = '/login';
    };

    const enable2FAEvent = (e: Event) => {
      e.preventDefault();
      run2FARegisterV1(authenticationOptions());
    };
    const title2FA = getCurrentUser()?.Is2FAEnabled ? tlang`Register new 2FA App` : tlang`Enable 2FA`;
    const userMenu = !isEmptyOrSpace(this.userDisplayName)
      ? html` <span class="navbar-text">
          ${this.aboveUserNameTemplate()}

          <webmodule-dropdown placement="bottom">
            <webmodule-button slot="trigger" variant="text" caret>${this.userNameMenuContent()}</webmodule-button>
            <webmodule-menu>
              ${this.customUserMenuElements()}
              <webmodule-menu-label>${tlang`%%user%%`}</webmodule-menu-label>
              <webmodule-menu-item @click=${logoutEvent}>${tlang`Logout`}</webmodule-menu-item>
              <webmodule-menu-item @click=${enable2FAEvent}>${title2FA}</webmodule-menu-item>
              ${isCynclyStaff() ? this.cynclyStaffMenuItems() : undefined}
            </webmodule-menu>
          </webmodule-dropdown>
        </span>`
      : html``;
    const supportClasses = isCynclyStaff()
      ? 'global-support-section cyncly-staff'
      : isSupplierAgent()
        ? 'global-support-section supplier'
        : '';
    const supplierOnlineTemplate = this.serviceOnlineTemplate();
    //this code is based on expecting only a single supplier instance, not multi instance
    const supportTemplate = isEmptyOrSpace(supportClasses)
      ? html``
      : html` <div class=${supportClasses}>${this.globalSupportInformation()}</div>`;
    return html`
      ${supplierOnlineTemplate} ${supportTemplate}
      <header>
        <nav class="navbar navbar-expand-lg navbar-header">
          <div class="container-fluid px-3">
            <a class="navbar-brand" href="/"> ${this.logoTemplate} </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="siteNavBar">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                ${this.getMenuItems().map(x => this.getMenuItemTemplate(x))}
              </ul>
              <wm-webmoduleautosaveindicator></wm-webmoduleautosaveindicator>
              <span class="me-1"></span>
              <icon-notification-signal></icon-notification-signal>
              ${userMenu}
            </div>
          </div>
        </nav>
      </header>

      <main id="main-page" role="main" class="flex-shrink-0 main-page">
        <div id="main-body"></div>
      </main>

      <footer class="footer mt-auto">
        <div class="d-flex flex-wrap justify-content-center align-content-center justify-content-lg-between">
          <div
            class="d-flex flex-wrap flex-lg-nowrap justify-content-center justify-content-md-start align-items-center footer-left-col"
          >
            <div class="st-logo pe-3">
              <img src=${getAssetCdnPath('./assets/images/PoweredBySoftTech.svg')} alt="Powered by SoftTech online" />
            </div>
            <div class="st-copyright pe-3">&copy; SoftTech (NZ) Ltd ${new Date().getFullYear()}</div>
            <div class="st-build px-3 border-start border-white">${this.generateBuildNumberText()}</div>
          </div>
          <div
            class="d-flex flex-wrap justify-content-center justify-content-md-end align-items-center footer-right-col"
          >
            <div class="supplier-footer-logo">${this.supplierFooterTemplate()}</div>
          </div>
        </div>
      </footer>
    `;
  }

  serviceOnlineTemplate() {
    return html``;
  }

  generateBuildNumberText(): TemplateResult {
    return html`${tlang`Build ${this.buildNumber}`}`;
  }

  supplierFooterTemplate(): TemplateResult {
    const debugTemplate = isDebugMode()
      ? html` <webmodule-button size="small" variant="danger" @click=${() => flushAndReloadApplication()}
          >Hot Reload
        </webmodule-button>`
      : html``;

    return isTestSite()
      ? html`<h1>TEST ENVIRONMENT ${debugTemplate}</h1>`
      : isDevSite()
        ? html`<h1>DEV ENVIRONMENT${debugTemplate}</h1>`
        : html`<img src="${footerBrandLogoUrl()}" alt="Supplier Branded logo" />${debugTemplate}`;
  }

  globalSupportInformation(): unknown {
    return '';
  }

  userNameMenuContent(): unknown {
    return html` ${tlang`Welcome`} ${this.userDisplayName}`;
  }

  aboveUserNameTemplate(): unknown {
    return html``;
  }

  customUserMenuElements(): TemplateResult {
    return html``;
  }

  async disconnectUser() {
    //do nothing
    return true;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected getMenuItems(): Array<MenuItem> {
    if (this.menuItems) return JSON.parse(this.menuItems);
    return [];
  }

  protected getMenuItemTemplate(menuItem: MenuItem): TemplateResult {
    return html` <li class=${'nav-item ' + this.isPathActive(menuItem)}>
      <a class="nav-link" aria-current="page" href="${urlForName(menuItem.name)}">${menuItem.title}</a>
    </li>`;
  }

  protected updateLogoTemplate() {
    this.logoTemplate = html` <svg width="1" height="1"></svg>`;
  }

  private isPathActive(menuItem: MenuItem): string {
    const currentPath = this.currentPath;

    return currentPath?.toLowerCase() === menuItem.path.toLowerCase() ||
      (menuItem.path.toLowerCase() !== '/' && currentPath?.toLowerCase().startsWith(menuItem.path.toLowerCase()))
      ? ' active'
      : '';
  }

  private updateNavigation = e => {
    this.currentPath = e.detail.path;
  };

  private scrollElementIntoView = (evt: Event) => {
    const e = evt as CustomEvent<{
      element?: Element | undefined;
      id?: string | undefined;
      selector?: string | undefined;
    }>;
    let element: Element | undefined = e.detail.element;

    if (!element && e.detail.id) {
      element = this.querySelector(`#${e.detail.id}`) ?? undefined;
    }

    if (!element && e.detail.selector) {
      element = this.querySelector(e.detail.selector) ?? undefined;
    }

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  protected cynclyStaffMenuItems() {
    return html``;
  }
}
