import { globalState } from '../ui/global-state';

export const claimIdentity = {
  true: 'true',
  /// <summary>
  /// claim added for a token that accesses a franchisee dealer db and wants to use a specific branch
  /// </summary>
  branchId: 'franchisee-branchid',
  allBranches: 'franchisee-allbranches',
  licenseServerAdmin: 'licenseserveradmin',
  branchAdmin: 'franchisee-branchadmin',
  primarycontact: 'primarycontact',
  supportTenantAllAccess: 'support-tenantallaccess',
  supportManager: 'support-manager',
  superAdmin: 'super-admin',

  /// <summary>
  /// claim identifies the user as being a login for a supplier nominated by the "supplierId" claim representing
  /// the "id" of a GlobalSupplier in the license server
  /// </summary>
  supplier: 'supplier',
  supplierId: 'supplierId',
  supplierGuid: 'supplierguid',
  frameConfigPowerUser: 'frameconfig-poweruser',
  /// <summary>
  /// represents that this token is created as a PAT on behalf of the tenant
  /// </summary>
  agent: 'agent-token',
  cynclyStaff: 'cynclystaff',

  /// <summary>
  /// this claim identifies the user as having admin rights on the license server on behalf of this tenant
  /// they have the ability to manage license allocations and user creation and maintenance
  /// </summary>
  admin: 'admin'
};

export interface IUserSecurity {
  claimExists: (claimName: string) => boolean;
  claimIsTrue: (claimName: string) => boolean;
  claim: (claimName: string) => string;
  hasRole: (roleName: string) => boolean;
  isSupplier: () => boolean;
  isPowerUser: () => boolean;
  isAdmin: () => boolean;
  supplierId: () => number;
  supplierGuid: () => string;
}

const defSecurity: IUserSecurity = {
  claimExists: () => false,
  claimIsTrue: () => false,
  claim: () => '',
  hasRole: () => false,
  isPowerUser: () => false,
  isSupplier: () => false,
  supplierId: () => 0,
  supplierGuid: () => '00000000-0000-0000-0000-000000000000',
  isAdmin: () => false
};

export function setUserSecurity(newUserSecurity: IUserSecurity | null) {
  globalState().userSecurity = newUserSecurity ?? defSecurity;
}

export function userSecurity(): IUserSecurity {
  return globalState().userSecurity ?? defSecurity;
}
