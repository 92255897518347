import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../webmodule-common/other/ui/modal-confirmation';
import {
  autoElement,
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';
import { customElement, property, query } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { equalsIgnoringCase } from '../data/settings-helpers';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html, LitElement } from 'lit';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { lockUIandExecute } from '../../../webmodule-common/other/ui-lock';
import { newGuid } from '../../../webmodule-common/other/api/guid';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import { PurchaseOrderRecipient } from '../../api/supplier-api-interface-supplier';
import { PurchaseOrderRecipientManager, PurchaseOrderRecipientManagerOptions } from '../data/purchase-order-manager';
import { showValidations } from '../../../webmodule-common/other/ui/modal-validationhandler';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { when } from 'lit/directives/when.js';

@customElement('settings-po-recipients-table')
export class SettingsPoRecipientsTable extends LitElement {
  @query('#settings-por-table')
  table?: WebModuleLitTable;

  @property()
  public data?: PurchaseOrderRecipient[] | null;

  //Do we allow edit?
  dispatchCustom(action: 'delete', purchaseOrderRecipient: PurchaseOrderRecipient) {
    const options = {
      detail: { ...purchaseOrderRecipient },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent(`wm-settings-por-${action}`, options));
  }

  protected render() {
    const colDef = this.getColums();

    const keyEvent = (item: PurchaseOrderRecipient) => {
      return item.id;
    };

    return html`
      ${when(
        this.data && this.data.length > 0,
        () =>
          html` <webmodule-lit-table
            id="settings-por-table"
            class="settings-por-table"
            .rowClass=${'tr'}
            .colClass=${'column'}
            .keyevent=${keyEvent}
            .data="${this.data}"
            pageLength="200"
            .tablestyle="nestedtable"
            .columns=${colDef}
            @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
            .clickrows=${false}
          >
          </webmodule-lit-table>`,
        () => html`No purchase order recipients added yet`
      )}
    `;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    const table = e.detail.table as WebModuleLitTable;

    const index = (e.detail?.pageIndex as number) ?? 0;

    const length = (e.detail?.pageLength as number) ?? 200;

    const sortField = (e.detail?.sortField as string) ?? '';

    const sortAscending = (e.detail?.sortAscending as boolean) ?? true;
    if (!this.data) {
      table.data = [];
    }
    if (this.data) {
      const sortedData = this.data.sort((a, b) => {
        if (sortField === 'email') {
          const result = a.email.localeCompare(b.email, undefined, { sensitivity: 'base' });
          return sortAscending ? result : result * -1;
        }
        return 0;
      });
      table.data = sortedData.slice(index * length, index * length + length);
      table.rowCount = sortedData.length;
    } else table.data = [];
  }

  private getColums(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];
    cols.push({
      title: tlang`Email`,
      classes: 'settings-por-email',
      fieldName: 'email',
      sortable: true,
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as PurchaseOrderRecipient;

        return html`${rowItem.email}`;
      }
    });
    cols.push({
      title: html``,
      classes: 'colpxmax-90 item-menu',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as PurchaseOrderRecipient;

        return html` <div class="settings-por-item-actions">
          <webmodule-icon-button
            library="fa"
            name="trash-can"
            @click="${() => this.deleteItem(rowItem)}"
          ></webmodule-icon-button>
        </div>`;
      }
    });

    return cols;
  }

  private deleteItem(rowItem: PurchaseOrderRecipient) {
    this.dispatchCustom('delete', rowItem);
  }
}

@autoElement()
export class SettingsPurchaseOrdersRecipientsView extends PageControlTabWithIndependantSaving {
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private newRecipientEmail: string = '';
  private recipientManager: PurchaseOrderRecipientManager;

  constructor() {
    super();
    this.pageFragment = 'order-recipients';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    this.recipientManager = this.recipientsManagerFactory();

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('newRecipientEmail', FieldType.string, true);
  }

  get table(): SettingsPoRecipientsTable {
    return this.querySelector('#settings-po-recipients-table') as SettingsPoRecipientsTable;
  }

  protected get recipients(): PurchaseOrderRecipient[] {
    return this.recipientManager.recipients;
  }

  public async afterConstruction(): Promise<void> {
    await this.recipientManager.needsRecipients();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];
    return errors;
  }

  async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) {
      this.dataTracker.applyChangeToValue();
    }
  }

  public internalDataChanged(): boolean {
    console.log(`region internalDataChanged ${this.recipientManager.changed()}`);
    return this.recipientManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.refreshData();
  }

  protected getCaption(): Snippet {
    return tlang`Purchase Order Recipients`;
  }

  protected async internalSaveData(): Promise<boolean> {
    console.log('purchase order internalSaveData');
    const result = await this.recipientManager.save(true);
    return result;
  }

  protected async refreshData() {
    this.requestUpdate();
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    const createNewRecipientEvent = async () => lockUIandExecute(async () => await this.createNewRecipient());

    return html` <div>
      <form id="PurchaseOrderRecipientForm" class="form-one-col po-recipient-page">
        <h2>${this.getCaption()}</h2>

        <div class="row">
          <div class="col-12 col-lg-6 po-recipient-table-wrapper">
            <settings-po-recipients-table
              id="settings-po-recipients-table"
              .data=${this.recipients}
              @wm-settings-por-delete="${(e: CustomEvent<PurchaseOrderRecipient>) => this.handleDeleteRecipient(e)}"
            ></settings-po-recipients-table>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 form-column new-table-item">
            ${forms.text('newRecipientEmail', tlang`Recipient Email`, 100)}
            <webmodule-button
              size="small"
              variant="primary"
              @click=${createNewRecipientEvent}
              id=${forms.id('create-po-recipient')}
            >
              ${tlang`Add`}
            </webmodule-button>
          </div>
        </div>
      </form>
    </div>`;
  }

  protected recipientsManagerFactory(): PurchaseOrderRecipientManager {
    const options: PurchaseOrderRecipientManagerOptions = {
      container: { recipients: [] }
    };
    return new PurchaseOrderRecipientManager(options);
  }

  protected async createNewRecipient(): Promise<void> {
    const newRecipientEmail =
      this.dataTracker.getEditorValue('newRecipientEmail')?.toString() ?? this.newRecipientEmail;
    if (isEmptyOrSpace(newRecipientEmail)) {
      await showValidations([tlang`No email specified`]);
    } else {
      const hasExisting = this.recipients.find((value: PurchaseOrderRecipient) =>
        equalsIgnoringCase(newRecipientEmail, value.email)
      );
      if (hasExisting) {
        await showValidations([
          tlang`A %%purchase-order%% %%recipient%% already exists with the name '${newRecipientEmail}'`
        ]);
      } else {
        const recipient: PurchaseOrderRecipient = { id: newGuid(), email: newRecipientEmail };
        this.recipientManager.createRecipient(recipient);
        this.table.requestUpdate();
      }
    }
    this.dataTracker.resetEditorValue();
    this.requestUpdate();
  }

  protected async deleteRecipient(recipient: PurchaseOrderRecipient) {
    console.log(recipient);
    this.recipientManager.deleteRecipient(recipient);
    this.table.requestUpdate();
  }

  private async handleDeleteRecipient(e: CustomEvent<PurchaseOrderRecipient>) {
    const purchaseOrderRecipient = e.detail;

    if (
      await AskConfirmation(
        tlang`Are you sure you want to delete the Purchase Order Recipient?`,
        confirmationButtons[ConfirmationButtonType.yesNo]
      )
    ) {
      await this.deleteRecipient(purchaseOrderRecipient);
    }
  }
}
