import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { Editor } from '@toast-ui/editor';
import { FileContainer, FileContainerManager } from '../data/file-content-container';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { hasContent } from '../../../webmodule-common/other/ui/string-helper-functions';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/interop/webmodule-interop';
import { stringToBase64 } from '../../../webmodule-common/other/blob-converters';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { errorList } from '../../../webmodule-common/other/error-list';
import { query, state } from 'lit/decorators.js';
import { PropertyValueMap } from '@lit/reactive-element';

@autoElement()
export class SettingsTermsOfUseView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private termsOfUseManager: FileContainerManager;

  @query('#touEditor')
  private editorElement?: HTMLElement;
  @state()
  private editor?: Editor;

  constructor() {
    super();
    this.pageFragment = 'tou';

    this.termsOfUseManager = new FileContainerManager(
      new FileContainer(this.settingsManager.supplierSettings.termsOfUseVirtualPath),
      () => tlang`Terms of Use`
    );
    this.termsOfUseManager.afterSave.push(async () => {
      this.settingsManager.updateInput.base64TermsOfUse = stringToBase64(this.termsOfUseManager.fileContent);
      await this.settingsManager.saveSettings();
      this.requestUpdate();
    });
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  getValidationErrors(): string[] {
    const errors = errorList();

    const termsOfUseText = this.editor?.getMarkdown();

    errors.assert(hasContent(termsOfUseText), tlang`No Terms of Use specified`);

    return errors.errors;
  }

  async prepareForSave(): Promise<void> {
    this.termsOfUseManager.fileContent = this.editor?.getMarkdown() ?? '';
  }

  public internalDataChanged(): boolean {
    return this.settingsManager.changed() || this.termsOfUseManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    await this.termsOfUseManager.needsFile();
  }

  protected async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
    await super.firstUpdated(_changedProperties);
    if (this.editorElement) {
      this.editor = new Editor({
        el: this.editorElement,
        previewStyle: 'vertical',
        height: '500px',
        initialValue: this.termsOfUseManager.fileContent,
        usageStatistics: false,
        initialEditType: 'wysiwyg'
      });
    }
  }

  protected getCaption(): Snippet {
    return tlang`Terms of Use Agreement`;
  }

  protected async internalSaveData(): Promise<boolean> {
    await this.termsOfUseManager.save(true);

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`${this.getCaption()} saved`);
      this.requestUpdate();
      return true;
    }

    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html` <div>
      <form id="formTermsOfUseSettings" class="form-one-col">
        <h2>${tlang`General Terms of Use Agreement`}</h2>
        <div class="row">
          <div class="col-sm-12 form-column">
            <div id="touEditor"></div>
          </div>
        </div>
      </form>
    </div>`;
  }
}
