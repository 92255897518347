import { PageBaseInternal } from '../../../../webmodule-common/other/ui/pages/page-base';
import { userDataStore } from '../../common/current-user-data-store';

export class PageBase extends PageBaseInternal {
  protected defaultTitleTemplate = `%s | Supplier's Module`;

  async loadUserCoreDetails(): Promise<void> {
    await userDataStore.loadCoreDetails();
  }
}

export abstract class TabbedPageBase extends PageBase {
  protected async awaken() {
    await this.setActiveTabByHash();
    await super.awaken();
  }
  // eslint-disable-next-line @typescript-eslint/require-await
  protected abstract setActiveTabByHash(): Promise<void>;
  protected async afterUserConnected() {
    await this.setActiveTabByHash();
  }
}
