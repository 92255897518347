import { Address } from '../../../interop/webmodule-interop';
import { ButtonEventMap, RowEventMap } from './input-type';
import { emptyAddress } from '../maps/map-helpers';
import { getToolTip } from '../../tooltip';
import { html, nothing, TemplateResult } from 'lit';
import { WebmoduleButtonSize, WebmoduleButtonVariant } from '../../../components/src/components/button/button';
import { when } from 'lit/directives/when.js';

export type ButtonSlot = 'suffix' | 'prefix';

export interface ButtonOptions {
  events?: ButtonEventMap;
  readonly?: boolean;
  slot?: ButtonSlot;
  class?: string;
  size?: WebmoduleButtonSize;
}

interface ButtonOptionsInternal {
  events?: ButtonEventMap;
  readonly?: boolean;
  slot?: ButtonSlot;
  variant?: WebmoduleButtonVariant;
  size?: WebmoduleButtonSize;
  class?: string;
}

interface AddressOptions {
  onAddressChanged: (e: Event) => Promise<void>;
  address?: Address;
  shippingNotes?: string;
  readonly?: boolean;
  isDefaultShippingVisible?: boolean;
  isDefaultShipping?: boolean;
  displayMap?: boolean;
}

export class FormInputAssistantBase {
  toolTipMaxSize?: string;

  constructor(forceReadonly: boolean | (() => boolean) = false) {
    this._forceReadonly = typeof forceReadonly === 'function' ? forceReadonly : () => forceReadonly;
  }

  private _forceReadonly: () => boolean;

  get forceReadonly(): boolean {
    return this._forceReadonly();
  }

  public clickableIcon(options: {
    events: ButtonEventMap;
    library: string;
    name: string;
    class?: string;
    slot?: string;
  }) {
    return html` <webmodule-icon-button
      class="${options.class ?? ''}"
      @click=${options.events.click}
      slot=${options.slot ?? nothing}
      library=${options.library}
      name=${options.name}
    ></webmodule-icon-button>`;
  }

  public buttonPrimary(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'primary' });
  }

  public buttonDefault(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'default' });
  }

  public buttonDanger(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'danger' });
  }

  public buttonWarning(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'warning' });
  }

  public buttonSuccess(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'success' });
  }

  public addressEditor(title: string, id: string, options: AddressOptions) {
    return this.addressInternal(title, id, options);
  }

  public multiEditRow(options: {
    title?: string;
    toolTip?: string | TemplateResult;
    slotted: TemplateResult;
    events?: RowEventMap;
  }) {
    return html` <webmodule-input-row
      size="small"
      class="webmodule-control-left-label"
      @mouseleave=""
      ${options?.events?.mouseleave}
      @click=${options?.events?.click}
      @mouseenter=${options?.events?.mouseenter}
    >
      ${options.slotted}
      ${when(
        options.title,
        () => html`
          <span slot="label">
            ${options.title} ${options.toolTip ? getToolTip(options.toolTip, { maxSize: this.toolTipMaxSize }) : ''}
          </span>
        `
      )}
    </webmodule-input-row>`;
  }

  protected buttonInternal(caption: string | TemplateResult, options?: ButtonOptionsInternal) {
    return html` <webmodule-button
      ?disabled=${(options?.readonly ?? false) || this.forceReadonly}
      .variant=${options?.variant ?? 'default'}
      @click=${options?.events?.click}
      slot=${options?.slot ?? nothing}
      size=${options?.size ?? nothing}
      class=${options?.class ?? nothing}
      >${caption}
    </webmodule-button>`;
  }

  protected addressInternal(title: string, id: string, options: AddressOptions) {
    return html` <wm-addresseditor
      @wm-ae-changed=${options.onAddressChanged}
      id=${id}
      .address=${options.address ?? emptyAddress()}
      .shippingNotes=${options.shippingNotes}
      .sectionTitle=${title}
      .readonly=${options.readonly ?? false}
      .isDefaultShippingVisible=${options.isDefaultShippingVisible ?? false}
      .isDefaultShipping=${options.isDefaultShipping ?? false}
      .displayMap=${options.displayMap ?? true}
    >
    </wm-addresseditor>`;
  }
}
